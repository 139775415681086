function initUtmSession() {
  let utmObj = {};
  let isUtmParameterInUrl = false;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmContent = urlParams.get('utm_content');
  const utmTerm = urlParams.get('utm_term');
  const utmAdgroup = urlParams.get('utm_adgroup');
  if(utmSource && utmSource!=''){
    utmObj['utm_source'] = utmSource;
    isUtmParameterInUrl = true;
  }
  if(utmMedium && utmMedium!=''){
    utmObj['utm_medium'] = utmMedium;
    isUtmParameterInUrl = true;
  }
  if(utmCampaign && utmCampaign!=''){
    utmObj['utm_campaign'] = utmCampaign;
    isUtmParameterInUrl = true;
  }
  if(utmContent && utmContent!=''){
    utmObj['utm_content'] = utmContent;
    isUtmParameterInUrl = true;
  }
  if(utmTerm && utmTerm!=''){
    utmObj['utm_term'] = utmTerm;
    isUtmParameterInUrl = true;
  }
  if(utmAdgroup && utmAdgroup!=''){
    utmObj['utm_adgroup'] = utmAdgroup;
    isUtmParameterInUrl = true;
  }
  if(isUtmParameterInUrl){
    let jsonUtm = JSON.stringify(utmObj);
    setCookie('_rhenus_utm',jsonUtm,30);
  }
  prefillPowermailFromSession();
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  let cookieContent = cname + "=" + cvalue + ";" + expires + ";path=/";
  document.cookie = cookieContent;
}

function prefillPowermailFromSession(){
  if($('#powermail_field_utm_source').length){
    let rhenusCookie = getCookie("_rhenus_utm");
    if($('#powermail_field_utm_source').val()=='' && rhenusCookie!='' && rhenusCookie!='{}'){
      let obj = JSON.parse(rhenusCookie);
      let fieldString = '';
      for (const [key, value] of Object.entries(obj)) {
        fieldString += key+':'+value+';';
        if(fieldString!=''){
          $('#powermail_field_utm_source').val(fieldString);
        }
      }
    }
  }
}



export { initUtmSession };

