import {BrowserDetect} from "../components/browserDetect";

/**
 * HyphenopolyHandler
 *
 * Use this object to control the hyphenopoly implementation.
 */
const HYPHENOPOLY_CUSTOM_OPTIONS = {
  /**
   * The class name that will be added to the html element when hyphenopoly is loaded.
   */
  hyphenopolyLoadedClassName: 'hyphenopoly-loaded',

  /**
   * The selector will be used to select the elements that should be hyphenated.
   * Keep in mind that all child elements will be affected as well.
   */
  selector: 'body',

  /**
   * The languages that should be hyphenated.
   *
   * The key is the language code and the value is an array of browsers that should use hyphenopoly.
   *
   * When adding or editing a language, refer to {@link https://caniuse.com/} to check if the browser supports the
   * hyphenation natively. E.g. by using a similar search parameter as in {@link https://caniuse.com/?search=cs-*}.
   * Also, check the `hyphenopoly/patterns/` folder to see if the language is supported by hyphenopoly.
   *
   * Note:
   *   - The language code must be the same as the one used in the html tag.
   *   - The browser names must be the same as the ones returned by the BrowserDetect.getBrowserInfo() method.
   *   - The edge browser is using the same engine as chrome, so you can use the chrome value for it.
   */
  lang: {
    "af": ["chrome", "safari"],
    "bg": ["chrome", "safari"],
    "ca": ["chrome"],
    //"cs": ["chrome", "firefox"],
    "cy": ["chrome", "safari"],
    "da": ["chrome"],
    "eo": ["chrome", "safari"],
    //"es": ["chrome"],
    "et": ["chrome", "safari"],
    "fi": ["chrome"],
    "fr": ["chrome"],
    "hr": ["chrome"],
    "hsb": ["chrome", "safari"],
    "hu": ["chrome"],
    "ia": ["chrome", "safari"],
    "is": ["chrome", "safari"],
    //"it": ["chrome"],
    "kmr": ["chrome", "safari"],
    "la": ["chrome", "safari"],
    "lt": ["chrome", "safari"],
    "mr": ["chrome", "safari"],
    "nb": ["chrome"],
    "nl": ["chrome"],
    "nn": ["chrome", "safari"],
    "no": ["chrome"],
    "pl": ["chrome"],
    //"pt": ["chrome"],
    "ru": ["chrome"],
    "sl": ["chrome", "safari"],
    "sv": ["chrome"],
    "tr": ["chrome"],
  },
};

/**
 * HyphenopolyHandler
 *
 * This object is responsible for applying the `HYPHENOPOLY_CUSTOM_OPTIONS` to the hyphenopoly library.
 */
const HyphenopolyHandler = {
  /**
   * Initializes the hyphenopoly library by first checking if the requirements are met, then setting up the library.
   */
  init() {
    if (!this.checkRequirements()) return;

    if (this.setupHyphenopoly()) {
      document.querySelector('html').classList.add(HYPHENOPOLY_CUSTOM_OPTIONS.hyphenopolyLoadedClassName);
    }
  },

  getHtmlLang() {
    return document.querySelector('html').getAttribute('lang');
  },

  /**
   * Checks if the requirements are met by the current browser and language
   *
   * @returns {boolean}
   */
  checkRequirements() {
    const lang = this.getHtmlLang();
    const browserInfo = BrowserDetect.getBrowserInfo();

    // do we even want to hyphenate this language?
    if (!HYPHENOPOLY_CUSTOM_OPTIONS.lang[lang]) return false;

    // do we even want use hyphenopoly in this browser for this language?
    return HYPHENOPOLY_CUSTOM_OPTIONS.lang[lang].includes(browserInfo.browser);
  },

  /**
   * Sets up the hyphenopoly library
   *
   * @see {@link https://github.com/mnater/Hyphenopoly}
   */
  setupHyphenopoly() {
    const lang = this.getHtmlLang();

    window.Hyphenopoly.config({
      require: {
        [lang]: ""
      },
      setup: {
        selectors: {
          "body": {}
        }
      }
    });

    return true;
  }
};

export {HyphenopolyHandler};
