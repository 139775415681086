const Back2TopButton = function () {
  const back2TopButtonMobile = document.getElementById('back-to-top-btn-mobile');
  const back2TopButtonDesktop = document.getElementById('back-to-top-btn-desktop');

  // check if button exists on current page
  if (!back2TopButtonMobile && !back2TopButtonDesktop) return;

  // mobileBreakpoint MUST MATCH the corresponding mobile breakpoint on the site
  const mobileBreakpoint = 992;

  // at which point, depending on the viewport, should the button become visible?
  const showButtonMobileAtScrollPosition = 568;
  const showButtonDesktopAtScrollPosition = 1024;

  const sidebar = document.getElementById('sidebar-cta-container');
  if (!sidebar) return;

  const buttons = sidebar.getElementsByClassName('cta-button');
  if (buttons.length > 0) {
    back2TopButtonMobile.classList.add('back-to-top-btn_has-sidebar');
    back2TopButtonDesktop.classList.add('back-to-top-btn_has-sidebar');
  }

  const handleBack2TopButtonVisibility = () => {
    if (window.innerWidth < mobileBreakpoint) { // mobile view
      if (window.scrollY > showButtonMobileAtScrollPosition) {
        back2TopButtonMobile.classList.add('back-to-top-btn_show');
      } else {
        back2TopButtonMobile.classList.remove('back-to-top-btn_show');
      }
    } else { // desktop view
      if (window.scrollY > showButtonDesktopAtScrollPosition) {
        back2TopButtonDesktop.classList.add('back-to-top-btn_show');
      } else {
        back2TopButtonDesktop.classList.remove('back-to-top-btn_show');
      }
    }
  };

  handleBack2TopButtonVisibility();
  window.addEventListener('scroll', () => {
    handleBack2TopButtonVisibility();
  });

  back2TopButtonMobile.addEventListener('click', () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  });

  back2TopButtonDesktop.addEventListener('click', () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  });
};

export {Back2TopButton};
