// load responsive media behaviour
require('../modules/responsive-media');

$(function() {
    // bootstrap opt-in modules
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    //lightbox for click and enlarge
    $('a[rel*="lightbox"]').magnificPopup({
        type: 'image',
        tLoading: 'Lade Bild...',
        tClose: 'Schließen (Esc)',
        image: {
            titleSrc: function (item) {
                var title = item.el.attr('title');
                var description = item.el.attr('alt');
                return ((title) ? title : '') + ((description) ? '<small>' + description + '</small>' : '');
            }
        },
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1],
            tCounter: '%curr% von %total%',
            tPrev: 'Zurück (Linke Pfeiltaste)',
            tNext: 'Vorwärts (Rechte Pfeiltaste)'
        },
    })

    $('.rhenus-worldwide>a').magnificPopup({
        type:'inline',
        midClick: false, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        closeMarkup:'',
        closeOnBgClick:true,
        callbacks: {
            open: function() {
                $('.rhenus-worldwide>a').addClass('opened');
            },
            close: function() {
                $('.rhenus-worldwide>a').removeClass('opened');
            }
        }
    });
    function closeRhenusWorldwide(){
        $.magnificPopup.close();
    }
})