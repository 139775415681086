function defaultTeaserSlider() {
    $('.default-teaser-container').each(function () {
        var $slideshowContainer = $(this).find('.row'),
            howManyCols = $(this).find('.default-teaser-box').length;


        if (howManyCols > 2) {
            $(this).find('.row').addClass('default-teaser-slideshow');
        }

        if (howManyCols > 3) {
            $(this).addClass('active-slideshow');
        }

    });

    var $defaultSliderTNS = $('.default-teaser-slideshow');
    // DO NOT REMOVE LOOP=FALSE, if you have to, talk to Tim Apler. Removing loop=false will de-sort the items in frontend.
    $defaultSliderTNS.each(function (index, slider) {
        var defaultTeaser = tns({
            container: slider,
            slideBy: 'page',
            controls: true,
            mode: 'carousel',
            nav: false,
            loop: false,
            arrowKeys: true,
            navPosition: 'bottom',
            controlsText: ["chevron_left", "chevron_right"],

            responsive: {
                1: {
                    items: 1,
                    gutter: 30
                },
                576: {
                    items: 1,
                    gutter: 15
                },
                992: {
                    items: 3,
                    nav: true,
                },
            }
        });
    });
}

export { defaultTeaserSlider };