function initTabLinks() {
    let hash = window.location.hash;

    if ($('a[data-link="tab-content-link"]').length) {
        $('a[data-link="tab-content-link"]').each(function () {
          let href = $(this).attr('href');
          if(href && href[0]=='#'){
            $(this).on('click',function (e) {
                e.preventDefault();
                let href = $(this).attr('href');
                if ($('a.nav-link[data-toggle="tab"][href="' + href + '"]').length) {
                    $('a.nav-link[data-toggle="tab"][href="' + href + '"]').click();
                }
            })
          }
        });
    }

    if (typeof (window.location.hash) === "string" && window.location.hash !== '') {
        let hashWithout = hash.replace('#', '');

        if (hashWithout.length > 3) {
            let identifier = hashWithout.substring(0, 3);

            if (identifier === 'tab') {
                // does this identifier exists?
                let $tabLink = $('a[href="' + window.location.hash + '"][data-toggle="tab"]');

                if ($tabLink.length === 1) {
                    // activate tab
                    setTimeout(function(){
                      $tabLink.trigger('click');
                    },500);
                }
            }
        }
    }
}

export { initTabLinks };
