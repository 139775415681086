let hotspotPageCache = {};

function initHotspots() {
    let interactiveMaps = [];
    // search for interactive maps
    if ($('.interactive-map').length) {
        $('.interactive-map').each(function () {
            // fill data of map
            let uid = $(this).data('uid');
            let config = $(this).data('config');
            let language = $(this).data('language-uid');
            let map = {"id": uid, "hotspots": config, "language": language};
            // add map to array
            interactiveMaps.push(map);
        });
    }
    // if there are maps
    if (interactiveMaps.length) {
        // define hotspot template
        const hotspotHtml = '<div class="hotspot" id="###ID###" title="###TITLE###" data-type="###TYPE###" data-target="###TARGET###" data-language="###LANGUAGE###" style="###STYLE###">###IMAGE###</div>';
        interactiveMaps.forEach(function (map) {
            // create map-selector
            let mapSelector = ".interactive-map[data-uid=" + map.id + "]";
            let hotspotsHtml = '';
            if (map.hotspots.length) {
                // for each hotspot copy template and replace data
                map.hotspots.forEach(function (hotspot) {
                    let html = hotspotHtml.replace('###ID###', hotspot['id']);
                    html = html.replace('###TYPE###', hotspot['type']);
                    html = html.replace('###TITLE###', hotspot['id']);
                    html = html.replace('###LANGUAGE###', map.language);
                    html = html.replace('###TARGET###', hotspot['target']);
                    let style = "left:" + hotspot['left'] + ";top:" + hotspot['top'] + ";";
                    html = html.replace('###STYLE###', style);
                    if (hotspot['image']) {
                        html = html.replace('###IMAGE###', '<img src="' + hotspot['image'] + '"/>');
                    } else {
                        html = html.replace('###IMAGE###', '<i class="material-icons">add</i>');
                    }
                    // add to html variable
                    hotspotsHtml += html;
                    buildPageContentForInteractiveMap(hotspot['target'], map.language);
                });
                // add hotspots html to page
                if ($(mapSelector + ' .hotspots').length) {
                    $(mapSelector + ' .hotspots').html(hotspotsHtml);
                }
                // define click event for hotspots
                $(mapSelector + ' .hotspot').click(function () {
                    // check type of hotspot
                    if ($(this).data('type') == 'pageContent') {
                        let isOpen = $(this).hasClass('open');
                        // close every hotspot and remove content
                        $(mapSelector + ' .hotspot').removeClass('open');
                        $(mapSelector + ' .hotspot-content').empty();
                        // if hotspot was closed, open it
                        if (!isOpen) {
                            $(this).addClass('open');
                            loadPageContentForInteractiveMap(mapSelector, $(this).data('target'), $(this).data('language'));
                        }
                    } else if ($(this).data('type') == 'tab') {
                        // close every hotspot and remove content
                        $(mapSelector + ' .hotspot').removeClass('open');
                        $(mapSelector + ' .hotspot-content').empty();
                        // search for tab and click it
                        let tabSelector = "a.nav-link[href*='-" + $(this).data('target') + "']";
                        $(tabSelector).click();
                        // scroll to tab element
                        $('html, body').animate({
                                scrollTop: $('.frame-type-rscwcontentelements_tabcontent').offset().top - 66
                            }, 300, function () {
                            }
                        );
                        // close all accordions
                        $('.interactive-map .accordion-element').removeClass('active');
                    } else if ($(this).data('type') == 'link') {
                        // close every hotspot and remove content
                        $(mapSelector + ' .hotspot').removeClass('open');
                        $(mapSelector + ' .hotspot-content').empty();
                        // create redirect url to target page
                        let redirectUrl = $(this).data('target');
                        window.open(redirectUrl, '_blank');
                    } else if ($(this).data('type') == 'internalLink') {
                        // create redirect url to target page
                        let redirectUrl = $(this).data('target');
                        window.open(redirectUrl, '_self');
                    }
                });
            }
            // definde mobile behavior
            if ($(mapSelector + ' .accordion-element').length) {
                $(mapSelector + ' .accordion-header').click(function (e) {
                    let header = $(this);
                    let element = $(header.parent());
                    let type = element.data('type');
                    let uid = element.data('uid');
                    let target = element.data('target');
                    let languageUid = element.data('language-uid');
                    if (type == 'pageContent') {
                        loadPageContentForInteractiveMapMobile(mapSelector, $(this).parent().data('target'), uid, languageUid);
                    } else if (type == 'tab') {
                        // search for tab and click it
                        let tabSelector = "a.nav-link[href*='-" + target + "']";
                        $(tabSelector).click();
                        $('.interactive-map .accordion-element').removeClass('active');
                    } else if (type == 'link') {
                        // create redirect url to target page
                        let redirectUrl = target;
                        window.open(redirectUrl, '_blank');
                    } else if (type == 'internalLink') {
                        // create redirect url to target page
                        let redirectUrl = target;
                        window.open(redirectUrl, '_self');
                    }
                })
            }
        });
    }
    ;
}

function loadPageContentForInteractiveMap(mapSelector, pageUid, languageUid) {
    if (hotspotPageCache[pageUid] !== undefined) {
        let resultHtml = '<div class="container"><div class="row"><div class="col"><i class="material-icons close-hotspot-content float-right">close</i></div></div></div>';
        resultHtml += hotspotPageCache[pageUid];
        $(mapSelector + ' .hotspot-content').html(resultHtml);
        // close content on close button and deselect hotspot
        $(mapSelector + ' .close-hotspot-content').click(function () {
            $(mapSelector + ' .hotspot-content').empty();
            $(mapSelector + ' .hotspot').removeClass('open');
            $('.hotspot-content-loading').addClass('d-none');
            // scroll to tabs when content is closed
            $('html, body').animate({
                    scrollTop: $('.frame-type-rscwcontentelements_tabcontent').offset().top - 66
                }, 300, function () {
                }
            );
        });
        $('.hotspot-content-loading').addClass('d-none');
        // scroll to content when it's loaded
        $('html, body').animate({
                scrollTop: $(mapSelector + ' .hotspot-content').offset().top - 66
            }, 300, function () {
            }
        );
    } else {
        $('.hotspot-content-loading').removeClass('d-none');
        // build ajax url
        let url = '';
        if (document.location.origin.indexOf('styleguide') === 1) {
            url = 'https://www.rhenus.group';
        } else {
            url = document.location.origin + "/index.php?id=" + pageUid + "&L=" + languageUid + "&type=101";
        }
        $.ajax({
            url: url,
            dataType: "html",
            success: function (html) {
                // store to cache
                hotspotPageCache[pageUid] = html;
                // if success build content html with close button
                let resultHtml = '<div class="container"><div class="row"><div class="col"><i class="material-icons close-hotspot-content float-right">close</i></div></div></div>';
                resultHtml += html;
                $(mapSelector + ' .hotspot-content').html(resultHtml);
                // close content on close button and deselect hotspot
                $(mapSelector + ' .close-hotspot-content').click(function () {
                    $(mapSelector + ' .hotspot-content').empty();
                    $(mapSelector + ' .hotspot').removeClass('open');
                    $('.hotspot-content-loading').addClass('d-none');
                    // scroll to tabs when content is closed
                    $('html, body').animate({
                            scrollTop: $('.frame-type-rscwcontentelements_tabcontent').offset().top - 66
                        }, 300, function () {
                        }
                    );
                });
                $('.hotspot-content-loading').addClass('d-none');
                // scroll to content when it's loaded
                $('html, body').animate({
                        scrollTop: $(mapSelector + ' .hotspot-content').offset().top - 66
                    }, 300, function () {
                    }
                );
            }
        });
    }
}

function loadPageContentForInteractiveMapMobile(mapSelector, pageUid, uid, languageUid) {
  const $spinner = $(mapSelector).find('.hotspot-content-loading');

    if (hotspotPageCache[pageUid] !== undefined) {
        $('#hotspot-modal-' + uid + ' .hotspot-modal-content').html(hotspotPageCache[pageUid]);
        $.magnificPopup.open({
            items: {
                src: '#hotspot-modal-' + uid,
                type: 'inline'
            },
            removalDelay: 300,
            mainClass: 'mfp-zoom-in mfp-zoom-out',
            closeMarkup: '',
            callbacks: {
              open: function () {
                $('body').addClass('mfp-noscroll');
              },
              close: function () {
                $('.interactive-map .accordion-element').removeClass('active');
                $('body').removeClass('mfp-noscroll');
              }
            }
        });
        rscwUpdateImgs();
        $('.mfp-wrap').scroll(function () {
            rscwUpdateImgs();
        });
    } else {
        $spinner.removeClass('d-none');

        // build ajax url
        let url = document.location.origin + "/index.php?id=" + pageUid + "&L=" + languageUid + "&type=101";
        $.ajax({
            url: url,
            dataType: "html",
            success: function (html) {
                // store in cache
                hotspotPageCache[pageUid] = html;
                // if success build content html
                let resultHtml = html;
                $('#hotspot-modal-' + uid + ' .hotspot-modal-content').html(resultHtml);
                $.magnificPopup.open({
                    items: {
                        src: '#hotspot-modal-' + uid,
                        type: 'inline'
                    },
                    removalDelay: 300,
                    mainClass: 'mfp-zoom-in mfp-zoom-out',
                    closeMarkup: '',
                    callbacks: {
                      open: function () {
                        $('body').addClass('mfp-noscroll');
                        $spinner.addClass('d-none');
                      },
                      close: function () {
                        $('.interactive-map .accordion-element').removeClass('active');
                        $('body').removeClass('mfp-noscroll');
                      }
                    }
                });
                rscwUpdateImgs();
                $('.mfp-wrap').scroll(function () {
                    rscwUpdateImgs();
                });
            }
        });
    }
}

function buildPageContentForInteractiveMap(pageUid, languageUid) {
    // build ajax url
    let url = '';
    if (document.location.origin.indexOf('styleguide') === 1) {
        url = 'https://www.rhenus.group';
    } else {
        url = document.location.origin + "/index.php?id=" + pageUid + "&L=" + languageUid + "&type=101";
    }
    $.ajax({
        url: url,
        dataType: "html",
        success: function (html) {
            // store to cache
            hotspotPageCache[pageUid] = html;
        }
    });
}

export { initHotspots, hotspotPageCache };
