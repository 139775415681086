/**
 * Contains and handles the CTA-Buttons based on viewport
 *
 * WARNING:
 *  This file works in close conjunction with the sass-partial '_sidebar-cta-container.scss'. Keep this file in mind,
 *  when changing any parts regarding the sidebar. This is especially true when it comes to the viewport-breakpoint.
 *  Also, the "Back 2 Top"-button is influenced by the existence or non-existence of the sidebar. Keep this in mind too,
 *  when changing and testing the sidebar!
 */
const SidebarCtaContainer = function () {
  const sidebar = document.getElementById('sidebar-cta-container');

  // does the sidebar exists?
  if (!sidebar) return;

  const body = document.getElementsByTagName('body')[0];
  const buttons = sidebar.getElementsByClassName('cta-button');

  // from this point on the viewport is considered to be desktop
  const mobileBreakpoint = 992;

  sidebar.setAttribute('data-sidebar-cta-container-item-count', buttons.length);

  /**
   * On mobile/tablet-viewport a border is added to bottom of the body to prevent the sidebar from covering any
   * information at the footer.
   */
  const preventFooterCoveringMobile = () => {
    if (window.innerWidth < mobileBreakpoint) {
      body.style.borderBottom = buttons[0]?.clientHeight + "px solid #084997";
    } else {
      body.style.borderBottom = 0;
    }
  };

  /**
   * Centers the sidebar vertical based on the browser window-height.
   */
  const centerVerticalDesktop = () => {
    if (window.innerWidth < mobileBreakpoint) {
      // undo posible calculated sidebar-positioning within this method
      sidebar.style.bottom = 0

      return;
    }

    sidebar.style.bottom = (window.innerHeight / 2) - (sidebar.offsetHeight / 2) + 'px';
    sidebar.style.opacity = 1;
  }

  preventFooterCoveringMobile();
  centerVerticalDesktop(); // necessary due to lazy loading-effects
  window.addEventListener('resize', () => {
    centerVerticalDesktop(); // necessary due to lazy loading-effects
    preventFooterCoveringMobile();
  });
};

export {SidebarCtaContainer};
