function changeLayoutOfLpcNews() {
    // set a 10 column grid with 1 column offset when the current page is a lpc article
    if($('.page-type-lpc').length){
        if($('.news-single').length){
            $('.fullwidth-content-col').addClass('col-md-10 offset-md-1');
        }
    }
}

export { changeLayoutOfLpcNews };
