function countrySwitchModule() {
  let cname = "_countryswitch";
  let cnamedatacountry = "_countryswitch_datacountry";
  let cnamedatalanguage = "_countryswitch_datalanguage";
  let cookie = getCookie(cname);
  let datacountrycookie = getCookie(cnamedatacountry);
  let datalanguagecookie = getCookie(cnamedatalanguage);

  if(cookie !== "true") {
    let currentCountry = window.location.pathname.slice(1,3);
    if($('.country_switch').length) {
      if(datacountrycookie!=="") {
        if(datacountrycookie == currentCountry) {
          $('.country_switch').addClass('hidden');
        } else {
          let countrySwitchLink = null;
          if(datacountrycookie!==undefined && datalanguagecookie!==undefined && datalanguagecookie!=="false" && datalanguagecookie!==false && datalanguagecookie!=="") {
            countrySwitchLink = document.location.origin+"/"+datacountrycookie+"/"+datalanguagecookie+"/";
          } else if(datacountrycookie!==null) {
            countrySwitchLink = document.location.origin+"/"+datacountrycookie+"/";
          }

          if(countrySwitchLink !== null) {
            $('#country-switch-link').attr("href",countrySwitchLink);
            $('.country_switch').removeClass('hidden');
          } else {
            $('.country_switch').delete();
          }

          var countryTitle = null;
          if ($('#rhenus-worldwide-popup').length) {
            var slug = datacountrycookie;
            let hostUrl = window.location.host;
            if(slug!==''){
              $('#rhenus-worldwide-popup .frame-type-textmedia a').each(function () {
                let href = $(this).attr('href');
                if(href!==undefined){
                  href.indexOf(1);
                  href.toLowerCase();
                  if(href.includes(hostUrl)){
                      href = href.split("/")[3];
                  } else {
                      href = href.split("/")[1];
                  }
                  if(href==slug){
                    switch (href) {
                      case "cn":
                        countryTitle = "Rhenus Greater China";
                        break;
                      case "za":
                        countryTitle = "Rhenus South Africa";
                        break;
                      default:
                        var country = $(this).html();
                        country = country.charAt(0).toUpperCase() + country.slice(1);
                        countryTitle = country;
                        break;
                    }
                  }
                }
              });
            }
          }
          if(countryTitle !== null) {
            $('#country-switch-country-title').html(countryTitle);
            $('#country-switch-link').html(countryTitle);
          }
        }
      } else {
        let url = buildAjaxUrl();
        let currentCountry = window.location.pathname.slice(1,3)
        url += "/?action=initCountrySwitch&currentCountry="+currentCountry+"&type=12518";
        // url += "/?action=initCountrySwitch&slug="++"&type=12518";
        $.ajax({
            url: url,
            dataType: "html",
            success: function (data) {
                if(data){
                  if(data !== "false") {
                    let parsedData = JSON.parse(data);
                    let countrySwitchLink = null;
                    let cookieValue = null;
                    if(parsedData['countryCode']!==undefined && parsedData['languageCode']!==undefined && parsedData['languageCode']!=="false" && parsedData['languageCode']!==false) {
                      countrySwitchLink = document.location.origin+"/"+parsedData['countryCode']+"/"+parsedData['languageCode']+"/";
                      setCookie(cnamedatacountry,parsedData['countryCode'],30);
                      setCookie(cnamedatalanguage,parsedData['languageCode'],30);
                    } else if(parsedData['countryCode']!==null) {
                      countrySwitchLink = document.location.origin+"/"+parsedData['countryCode']+"/";
                      setCookie(cnamedatacountry,parsedData['countryCode'],30);
                      setCookie(cnamedatalanguage,false,30);
                    }

                    if(getCookie(cnamedatacountry) == currentCountry) {
                      $('.country_switch').addClass('hidden');
                    } else {
                      if(countrySwitchLink !== null) {
                        $('#country-switch-link').attr("href",countrySwitchLink);
                        $('.country_switch').removeClass('hidden');
                      } else {
                        $('.country_switch').delete();
                      }

                      var countryTitle = null;
                      if ($('#rhenus-worldwide-popup').length) {
                        var slug = parsedData['countryCode'];
                        let hostUrl = window.location.host;
                        if(slug!==''){
                          $('#rhenus-worldwide-popup .frame-type-textmedia a').each(function () {
                            let href = $(this).attr('href');
                            if(href!==undefined){
                              href.indexOf(1);
                              href.toLowerCase();
                              if(href.includes(hostUrl)){
                                  href = href.split("/")[3];
                              } else {
                                  href = href.split("/")[1];
                              }
                              if(href==slug){
                                switch (href) {
                                  case "cn":
                                    countryTitle = "Rhenus Greater China";
                                    break;
                                  case "za":
                                    countryTitle = "Rhenus South Africa";
                                    break;
                                  default:
                                    var country = $(this).html();
                                    country = country.charAt(0).toUpperCase() + country.slice(1);
                                    countryTitle = country;
                                    break;
                                }
                              }
                            }
                          });
                        }
                      }
                      if(countryTitle !== null) {
                        $('#country-switch-country-title').html(countryTitle);
                        $('#country-switch-link').html(countryTitle);
                      }
                    }
                  } else {
                    $('.country_switch').remove();
                  }
                } else {
                  $('.country_switch').remove();
                }
            }
        });
      }

      $('#close-countryswitch').on("click", function(){
        $('.country_switch').addClass('hidden');
        setCookie(cname,true,30);
      });
    }
  } else {
    $('.country_switch').addClass('hidden');
  }
}

function buildAjaxUrl(){
  let url = document.location.origin;
  return url;
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  let cookieContent = cname + "=" + cvalue + ";" + expires + ";path=/";
  document.cookie = cookieContent;
}

export { countrySwitchModule };
