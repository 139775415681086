const UsercentricsHandler = {
  init: function () {
    const url = window.location.href;
    if (url.includes('.rscw.dev') && url.includes('no_cookie=1')) {
      document.querySelectorAll('.no_youtube_cookie').forEach(noCookieWrapperElement => {
        setupYoutubeVideo(noCookieWrapperElement);
      });

      return;
    }

    const youtubeVideos = document.querySelectorAll('.no_youtube_cookie');
    if (youtubeVideos.length) {
      youtubeVideos.forEach(video => {
        this.initYoutubeVideos(video);
      });
    }

    window.addEventListener('UC_CONSENT', event => {
      if (event.detail.services['BJz7qNsdj-7'].consent.given === true) {
        document.querySelectorAll('.no_youtube_cookie').forEach(noCookieWrapperElement => {
          setupYoutubeVideo(noCookieWrapperElement);
        });
      }
    });

    this.bindOpenUsercentricsBannerManually();
  },

  initYoutubeVideos: function (noCookieWrapperElement) {
    if (!noCookieWrapperElement) return;

    const btnAllow = noCookieWrapperElement.querySelector('.data-protection-notice__button_allow');
    const btnSettings = noCookieWrapperElement.querySelector('.data-protection-notice__button_settings');

    if (!btnAllow || !btnSettings) return;

    btnAllow.addEventListener('click', async () => {
      usercentricsAllowYouTubeVideos();
    });

    btnSettings.addEventListener('click', async () => {
      if (typeof UC_UI === 'undefined') {
        console.error('Usercentrics is not loaded');
        return;
      }

      UC_UI.showFirstLayer();
    });
  },

  bindOpenUsercentricsBannerManually: function () {
    const links = document.querySelectorAll('.open-usercentrics-banner');
    if (!links.length) return;

    links.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();

        if (typeof UC_UI === 'undefined') {
          console.error('Usercentrics is not loaded');
          return;
        }

        UC_UI.showFirstLayer();
      });
    });
  }
};

function usercentricsAllowYouTubeVideos() {
  __ucCmp.updateServicesConsents([{id: 'BJz7qNsdj-7', consent: true}]);
  __ucCmp.saveConsents()

  document.querySelectorAll('.no_youtube_cookie').forEach(noCookieWrapperElement => {
    setupYoutubeVideo(noCookieWrapperElement);
  });
}

const setupYoutubeVideo = noCookieWrapperElement => {
  noCookieWrapperElement.classList.remove('no_youtube_cookie');
  noCookieWrapperElement.querySelector('.data-protection-notice').classList.add('d-none');

  noCookieWrapperElement.querySelector('.video-embed-overlay')?.addEventListener('click', () => {
    const video_wrapper = noCookieWrapperElement.closest(".video-embed") || noCookieWrapperElement;
    noCookieWrapperElement.remove();

    if (video_wrapper.querySelector('iframe')) {
      const iframe = video_wrapper.querySelector('iframe');
      const url = iframe.getAttribute('data-src');

      iframe.setAttribute('src', url);
      iframe.setAttribute('style', 'display: block');
    } else if (video_wrapper.querySelector('video')) {
      video_wrapper.querySelector('video').play();
    }
  });
};

export {UsercentricsHandler};
