/**
 * Controls the visibility of a special banner, that can be activated in the page-properties in the backend.
 *
 * If "Language > Use Default Lang" in page properties is set to "1", then the current page will be delivered
 * in the default language. In order to notify the user of said behaviour a banner will be displayed.
 */
const UseDefaultLangBanner = function () {
  if (!document.getElementsByClassName('page-body')[0].classList.contains('lang-overlay')) return;

  const cookieName = 'lang-overlay';
  const bannerId = 'use-default-lang-banner';
  const showBannerClassName = 'use-default-lang-banner_show';
  const hiddingBannerClassName = 'use-default-lang-banner_hidding';
  const buttonAcceptId = 'use-default-lang-banner-button-accept';

  // arrow-function helpers
  const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  };
  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };
  const getPageId = () => {
    return document.getElementsByTagName('body')[0].getAttribute('id');
  };

  const pageId = getPageId();
  let cookie = getCookie(cookieName);

  // should the banner should be displayed?
  if (cookie === null || cookie.indexOf(pageId) === -1) {
    document.getElementById(bannerId).classList.add(showBannerClassName);
  }

  // evt: button "Accept" clicked
  document.getElementById(buttonAcceptId).addEventListener('click', () => {
    let cookieValueList = getCookie(cookieName);

    if (cookieValueList === null) {
      setCookie(cookieName, pageId, 30);
    } else {
      cookieValueList += '|' + pageId;
      setCookie(cookieName, cookieValueList, 30);
    }

    document.getElementById(bannerId).classList.add(hiddingBannerClassName);
    setTimeout(() => {
      document.getElementById(bannerId).classList.remove(showBannerClassName);
    }, 2000); // timeout time has to match "animation-duration" (see '_use-default-lang-banner.scss')

  });
};

export { UseDefaultLangBanner };
