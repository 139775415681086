function initCareerNumbers() {
    if ($('.career-numbers').length) {
        const duration = 2000;
        $('.career-numbers').each(function () {
            let htmlId = '#' + $(this).attr('id');
            let element = $(this);
            let numbers = 0;
            const url = document.location.origin + "/?type=767";
            $.ajax({
                url: url,
                dataType: "html",
                success: function (data) {
                    data = JSON.parse(data);
                    let selector = '';
                    if (element.find('.career-numbers-total').length && data.total) {
                        selector = htmlId + ' .career-numbers-total';
                        animateValue(selector, 0, data.total, duration);
                    }
                    if (element.find('.career-numbers-countries').length && data.countries) {
                        selector = htmlId + ' .career-numbers-countries';
                        animateValue(selector, 0, data.countries, duration);
                    }
                    if (element.find('.career-numbers-business-areas').length && data.businessAreas) {
                        selector = htmlId + ' .career-numbers-business-areas';
                        animateValue(selector, 0, data.businessAreas, duration);
                    }
                    if (element.find('.career-numbers-fields-of-activity').length && data.fieldsOfAcivity) {
                        selector = htmlId + ' .career-numbers-fields-of-activity';
                        animateValue(selector, 0, data.fieldsOfAcivity, duration);
                    }
                }
            });
        })
    }
}

function animateValue(selector, start, end, duration) {
    if (start === end) return;
    var range = end - start;
    var current = start;
    var increment = end > start ? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var obj = $(selector);
    var timer = setInterval(function () {
        current += increment;
        obj.html(current);
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
}

export { initCareerNumbers };