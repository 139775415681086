const waterLevels = {
  init: function () {

    if (typeof waterLevelsData === 'undefined') return;
    this.data = waterLevelsData;

    // the height (in pixel) that represents the maximum water level
    this.barHeightMax = 142;

    this.el = document.querySelector('.water-levels');
    if (!this.el) return;

    this.headline = this.el.querySelector('.water-levels__headline');
    this.subHeadline = this.el.querySelector('.water-levels__sub-headline');

    this.scale = this.el.querySelector('.water-levels__scale');

    this.city = this.el.querySelectorAll('.water-levels-city');

    // hide .water-levels-city in the DOM if it hasn't any data provided
    for (let cityIndex = this.data.city.length; cityIndex < 4; cityIndex++) {
      this.city[cityIndex].classList.add('water-levels-city_hidden');
    }

    this.setTheme();
    this.updateSvgValues();
  },

  setTheme: function () {
    if (this.data.theme === 'dark') {
      this.el.classList.add('water-levels_theme-dark');
    }
  },

  updateSvgValues: function () {
    this.headline.innerHTML = this.data.headline;
    this.subHeadline.innerHTML = this.data.subHeadline;
    this.scale.querySelector('.water-levels__scale-value_mid').innerHTML = this.data.scale.midLabel;
    this.scale.querySelector('.water-levels__scale-value_max').innerHTML = this.data.scale.maxLabel;

    this.city.forEach((city, index) => {
      if (city.classList.contains('water-levels-city_hidden')) return;

      city.querySelector('.water-levels-city__name').innerHTML = this.data.city[index].name;
      city.querySelector('.water-levels-city__current-value').innerHTML = this.data.city[index].currentValue;
      city.querySelector('.water-levels-city__trend').innerHTML = this.data.city[index].trend;

      if (this.data.city[index].trend < 0) {
        city.querySelector('.water-levels-city__trend').classList.add('water-levels-city__trend_negative');
      }
    });

    this.animateCityBars();
  },

  animateCityBars: function () {
    this.city.forEach((city, index) => {
      if (city.classList.contains('water-levels-city_hidden')) return;

      const bar = city.querySelector('.water-levels-city__bar');
      const barRatio = this.data.city[index].currentValue / this.data.scale.maxValue;
      const barHeight = barRatio * this.barHeightMax;
      const barAnimationDuration = (barRatio * 100) * this.data.animationDurationPerPercent; // in ms

      bar.style.transition = `all ${barAnimationDuration}ms linear`;
      bar.style.top = -barHeight + 'px';

      const barIntervalDuration = barAnimationDuration / this.data.city[index].currentValue;

      let currentValue = 0;
      let interval = setInterval(() => {
        if (this.data.city[index].currentValue <= currentValue) {
          clearInterval(interval);
          bar.querySelector('.water-levels-city__trend').style.opacity = 1;
        } else {
          currentValue++;
          bar.querySelector('.water-levels-city__current-value').innerHTML = currentValue;
        }
      }, barIntervalDuration);
    });
  }
};

export {waterLevels};
