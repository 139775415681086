import {StickinessHandler, $scrollIntoViewWithOffset} from '../utils/scroll.utils';

function tabularContent() {
  if ($('.tabular-content .tabs ul').length) {
    $('.tabular-content .tabs').each(function (index) {
      $(this).attr('data-tabular-content', index);
    });

    const allTabularContents = [];
    $('.tabular-content .tabs ul').each(function (index, slider) {
      const tnSliderRef = tns({
        container: slider,
        autoWidth: true,
        controls: true,
        edgePadding: 15,
        mode: 'carousel',
        nav: false,
        mouseDrag: true,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayButtonOutput: false,
        loop: false,
        slideBy: 1,
      });

      const $tabs = $(this).closest('.tabs');
      tnSliderRef.events.on('indexChanged', () => {
        const sliderInfo = tnSliderRef.getInfo();

        // last slide active?
        if (sliderInfo.displayIndex === sliderInfo.slideCount) {
          $tabs.find('.slider-next').addClass('disabled');
        } else {
          $tabs.find('.slider-next').removeClass('disabled');
        }

        // first slide active?
        if (sliderInfo.displayIndex === 1) {
          $tabs.find('.slider-prev').addClass('disabled');
        } else {
          $tabs.find('.slider-prev').removeClass('disabled');
        }
      });

      allTabularContents.push(tnSliderRef);

      const navTab = document.querySelector('.tabs[data-tabular-content="' + index + '"]');
      const contentTab = document.querySelectorAll('.tabular-content-bg')[index];
      StickinessHandler.init(
        navTab,
        contentTab,
        48,
        'tabs_sticky',
        'tabs_scroll-up'
      );
    });

    /**
     * If a .nav-link is clicked by the user, we have to determine
     * and slide to the correct slide index.
     */
    $('.tabular-content a.nav-link').on('click', function () {
      const childNumber = $(this).parents('li.nav-item').index();

      const sliderContainer = $(this).parents('.tabs');
      const sliderNumber = sliderContainer.attr('data-tabular-content');

      allTabularContents[sliderNumber].goTo(childNumber);

      rscwUpdateImgs();

      scrollToNavTabs($(sliderContainer));
    });

    /**
     * If one of the arrows is clicked, we have to manually trigger
     * the "click"-event on the corresponding .nav-link, so that
     * the next (or previous) slide will become "active".
     */
    $('.tabular-content-button').on('click', function () {
      const sliderContainer = $(this).parents('.tabular-content').find('.tabs');
      let childNumber = sliderContainer.find('.nav-link.active').parents('li.nav-item').index();
      const sliderNumber = sliderContainer.attr('data-tabular-content');
      const sliderInfo = allTabularContents[sliderNumber].getInfo();
      const maxChildNumber = sliderInfo.slideCount - 1;

      if ($(this).hasClass('slider-next')) {
        childNumber += 1;
      } else {
        childNumber -= 1;
      }

      if (childNumber < 0) {
        childNumber = 0;
      } else if (childNumber > maxChildNumber) {
        childNumber = maxChildNumber;
      }

      sliderContainer.find('.nav-link').eq(childNumber).trigger('click');

      scrollToNavTabs($(sliderContainer));
    });
  }
}

/**
 * Scrolls to the .tabular-content_nav-tabs container, if the browser width is below 992px.
 *
 * When determining the offset for function $scrollIntoViewWithOffset, the height of the .navigation-mobile will be considered as well as the height of the sticky nav-tabs (when it is sticky).
 * Additionally, we have to consider the height of the sticky nav-tabs, when it is sticky.
 *
 * @param {Object} $tabs - jQuery object of the .tabs container
 */
function scrollToNavTabs($tabs) {
  const browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (browserWidth >= 992) return;

  if ($tabs.length === 0) return;

  if (!$tabs.hasClass('tabs_sticky')) return;

  const navTabs = $tabs.closest('.tabular-content_nav-tabs');
  if (navTabs.length === 0) return;

  const scrollIntoViewOffset = $tabs.height() + 48;

    $scrollIntoViewWithOffset(navTabs, scrollIntoViewOffset, 200);
}

export {tabularContent};
