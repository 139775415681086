function materialComponents() {
    // Textfields
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (isIE11 == false) {
        const textFields = document.querySelectorAll('.mdc-text-field');
        for (const textField of textFields) {
            mdc.textField.MDCTextField.attachTo(textField);
        }
    }
}

export { materialComponents };