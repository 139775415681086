function getScrollDirection() {
  let scrollDirection = 'down';
  if (window.scrollY < window.oldScroll) {
    scrollDirection = 'up';
  }
  window.oldScroll = window.scrollY;

  return scrollDirection;
}

/**
 * Check if element is at least partially in viewport
 */
function isPartiallyInViewport(el, offsetTop = 0, offsetBottom = 0) {
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  const rect = el.getBoundingClientRect();
  const vertInView = (rect.top <= windowHeight) && ((rect.top - offsetTop + rect.height - offsetBottom) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}

/**
 * Handles the sticky behaviour of a filter element depending on the filtered element visibility in the viewport.
 *
 * Usage: Call StickinessHandler.init(). The parameters needed are described at the init function.
 */
const StickinessHandler = {
  /**
   * Initializes the sticky behaviour
   *
   * @param {object} elFilter - The filter element
   * @param {object} elFilteredArea - Is need in order calculate the sticky behaviour of the filter element.
   * @param {int} additionalOffsetTop - Additional offset top for the filter element. This is useful if you have a fixed header.
   * @param {string} fixedFilterModifierClassName - The class name which will be added to the filter element if it is sticky.
   * @param scrollUpFilterModifierClassName - The class name which will be added to the filter element if the user scrolls up.
   * @param maxWindowWidth - The max window width for the sticky behaviour. If the window width is greater than this value, the filter element will not be sticky.
   */
  init: function (elFilter, elFilteredArea, additionalOffsetTop = 0, fixedFilterModifierClassName = 'fixed', scrollUpFilterModifierClassName = 'scroll-up', maxWindowWidth = 991.8) {
    this.elFilter = elFilter;
    this.elFilteredArea = elFilteredArea;
    this.additionalOffsetTop = -additionalOffsetTop;
    this.fixedFilterModifierClassName = fixedFilterModifierClassName;
    this.maxWindowWidth = maxWindowWidth;

    if (!elFilter || !elFilteredArea) {
      return;
    }

    this.elHtml = document.querySelector('html');

    this.filterHeight = this.elFilter.getBoundingClientRect().height;
    this.initialFilterOffsetTop = this.getOffsetTop(this.elFilter);
    this.sumFilterAndAdditionalOffsetTop = this.filterHeight + this.additionalOffsetTop;
    window.addEventListener('resize', () => {
      this.applyStickiness();
    });

    this.applyStickiness();
    window.addEventListener('scroll', () => {
      this.filterHeight = this.elFilter.getBoundingClientRect().height;
      this.sumFilterAndAdditionalOffsetTop = this.filterHeight + this.additionalOffsetTop;
      this.applyStickiness();

      if (getScrollDirection() === 'up') {
        this.elFilter.classList.add(scrollUpFilterModifierClassName);
      } else {
        this.elFilter.classList.remove(scrollUpFilterModifierClassName);
      }
    });
  },

  applyStickiness: function () {
    if (
      isPartiallyInViewport(this.elFilteredArea, 0, this.sumFilterAndAdditionalOffsetTop)
      && this.initialFilterOffsetTop < window.scrollY + this.additionalOffsetTop
      && this.elFilter.offsetTop < window.scrollY + this.additionalOffsetTop
      && window.innerWidth <= this.maxWindowWidth
    ) {
      this.elFilter.classList.add(this.fixedFilterModifierClassName);
      this.elHtml.style.marginTop = this.filterHeight + 'px';
    } else {
      this.removeStickiness();
    }
  },

  removeStickiness: function () {
    this.elFilter.classList.remove(this.fixedFilterModifierClassName);
    this.elHtml.style.marginTop = '0';
  },

  getOffsetTop(el) {
    let offsetTop = 0;
    do {
      if (!isNaN(el.offsetTop)) {
        offsetTop += el.offsetTop;
      }
    } while (el = el.offsetParent);

    return offsetTop;
  }
};

/**
 * Scrolls to an element with an offset
 *
 * @param selector
 * @param offset
 * @param behavior See https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo for possible values
 */
const scrollIntoViewWithOffset = (selector, offset = 0, behavior = 'instant') => {
  const scrollToTarget = document.querySelector(selector);
  if (!scrollToTarget) return;

  window.scrollTo({
    behavior: behavior,
    top:
      scrollToTarget.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  })
};

/**
 * jQuery-based alternative to scrollIntoViewWithOffset, that restores the html-tags scroll behavior after scrolling.
 *
 * @see {@link https://api.jquery.com/animate/} section "Easing" for possible values of easingFunction and further information
 *
 * @param {Object} $target - jQuery object of the target element
 * @param {number} [offset=0] - Offset in px. Will be subtracted from the target element's top position. Useful for fixed headers.
 * @param {number} [duration=300] - Duration of the scroll animation in ms
 * @param {string} [easingFunction='linear'] - Easing function for the scroll animation
 */
const $scrollIntoViewWithOffset = ($target, offset = 0, duration = 300, easingFunction = 'linear') => {
  if ($target.length === 0) return;

  const htmlCssScrollBehavior = window.getComputedStyle(document.documentElement).scrollBehavior;

  document.documentElement.style.scrollBehavior = 'auto';

  $('html, body').animate({
    scrollTop: $target.offset().top - offset
  }, duration, easingFunction, () => {
    document.documentElement.style.scrollBehavior = htmlCssScrollBehavior;
  });
};

export {
  getScrollDirection,
  isPartiallyInViewport,
  StickinessHandler,
  scrollIntoViewWithOffset,
  $scrollIntoViewWithOffset
}
