import {getScrollDirection} from "../utils/scroll.utils";

var allFilterRemoved = false;

function initSolr() {
    const $subfilterTextSearchToggleButton = $('.solr-news-filter__subfilter_text-search');
    const $subfilterTextSearchBox = $('.solr-news-filter__filter-search-box');

    solrSearchBoxNewsMedia();

    /**
     * When text-search becomes active/visible, then set focus to it,
     * so the user can directly proceed with entering a search term.
     */
    $subfilterTextSearchToggleButton.on('shown.bs.collapse', () => {
        $subfilterTextSearchBox.focus();
        $('.autocomplete-suggestions').addClass('tx-solr-autosuggest');
    });

    $subfilterTextSearchToggleButton.on('hide.bs.collapse', () => {
        $('.autocomplete-suggestions').removeClass('tx-solr-autosuggest');
    });

    const getPerPage = (viewportBreakpoint, archiveFlag = false) => {
        if ($(window).width() >= viewportBreakpoint) {
            if (archiveFlag) {
                return "16";
            } else {
                return "8";
            }
        } else {
            return "3";
        }
    }

    const perPageViewportHandler = () => {
        const $form = $('.news-media-search #results-per-page').find('form');
        const $select = $form.find('select');

        const perPage = getPerPage(992);

        if (perPage !== $select.val()) {
            $select.val(perPage);
            $form.submit();
        }
    }

    perPageViewportHandler();
    $(window).on('resize', () => {
        perPageViewportHandler();
    });

    /**
     * initSolr() has to be executed after an ajax call, because otherwise the bindings within the filter
     * get lost. The event "tx_solr_updated" (bind to the body-tag) will be fired, when the call is finished.
     */
    $('body').on('tx_solr_updated', function () {
        initSolr();
        $('.autocomplete-suggestions').removeClass('tx-solr-autosuggest');
    });
}

function initSolrResultsPerPage(){
    solrResultsPerPage();
    window.addEventListener('resize',function(){
      solrResultsPerPage();
    });
}

function solrResultsPerPage(){
    const breakpoint = 768;
    const resultsAmountMobile = 6;
    if(window.innerWidth<=breakpoint){
      $('#solr-results-per-page-hidden').val(resultsAmountMobile)
    }else{
      $('#solr-results-per-page-hidden').val('')
    }
}

function initMobileNewsFilter() {
    // Check if o  the current site a news filter element exists
    if($('.solr-news-filter').length){
        //Check if filter params are in the url
        let decodedUrl = decodeURI(window.location.href);
        if (decodedUrl.indexOf("tx_solr[filter]") > -1 || decodedUrl.indexOf("tx_solr[q]") > -1) {
            $('html, body').animate({
                scrollTop: $(".solr-news-filter").offset().top
            }, 2000);
        }
    }
}

function mobileRemoveFilter() {
    $('#remove-all-filter').click(function(){
        $('.solr-filter-bar-mobile-content').find('input').each(function(){
            $(this).prop('checked',false);
            var totalFilter = 0;
            $('#mobile-filter-form .facet.panel-group').each(function(){
                var activeFilter = 0
                $(this).find('input').each(function(){
                    if($(this).prop('checked')){
                        activeFilter++;
                        totalFilter++;
                    }
                });
                if(activeFilter == 0){
                    $(this).parent().find('.facet-result-count').addClass('d-none');
                } else {
                    $(this).parent().find('.facet-result-count').removeClass('d-none');
                }
                $(this).parent().find('.facet-result-count').html(activeFilter);
            });
            if(totalFilter==0){
                $('.apply-filter').addClass('disabled');
            } else {
                $('.apply-filter').removeClass('disabled');
            }
            $('.mobile-selected-filter a').remove();
            $('.mobile-selected-filter span').html('');
            allFilterRemoved = true;
        });
    });
}

function mobileFilterCount() {
    if($('.facet-option-list').length){
        $('.facet-option-list').each(function(){
            let filterList = $(this);
            $(this).find('input').each(function(){
                let input = $(this);
                $(this).change(function(){
                    var totalFilter = 0;
                    $('.facet-option-list').each(function(){
                        var activeFilter = 0
                        $(this).find('input').each(function(){
                            if($(this).prop('checked')){
                                activeFilter++;
                                totalFilter++;
                            }
                        });
                        if(activeFilter == 0){
                            $(this).parent().find('.facet-result-count').addClass('d-none');
                        } else {
                            $(this).parent().find('.facet-result-count').removeClass('d-none');
                        }
                        $(this).parent().find('.facet-result-count').html(activeFilter);
                    });
                    if(totalFilter==0){
                        $('.apply-filter').addClass('disabled');
                    } else {
                        $('.apply-filter').removeClass('disabled');
                    }
                });
            });
        });
    }
}

function initFilterPopupButton() {
    $('.mobile-filer-popup').magnificPopup({
        type:'inline',
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        fixedBgPos: true,
        fixedContentPos: true,
        removalDelay: 300,
        mainClass: 'mfp-fade',
        callbacks: {
            beforeClose: function() {
                if(allFilterRemoved==true){
                    let spinner =   '<div class="spinner-background">'+
                                        '<div class="spinner-border text-light" role="status">'+
                                            '<span class="sr-only">Loading...</span>'+
                                        '</div>'+
                                    '</div>';
                    $(spinner).appendTo('body');

                    if($('#remove-all-filter').data('pagetype')=='solr'){
                        if($('#solr-searchbar-input').val()==''){
                            window.location.replace(window.location.pathname+$('#remove-all-filter').data('additionalurlparam'));
                        } else {
                            let searchstring = $('#solr-searchbar-input').val();
                            window.location.replace(window.location.pathname+$('#remove-all-filter').data('additionalurlparam')+"&tx_solr[q]="+searchstring);
                        }
                    } else {
                        window.location.replace(window.location.pathname);
                    }
                }
            },
        },
    });
    $('#mfp-close').on('click',function(){
        $.magnificPopup.close();
    });
}

function initMobileNewsFilterButton() {
    //The function to disable the filter button when zero total filter are selected
    //Iterate through each select and save the values if there are any, in an array
    let txSolrFilter = $('#mobile-filter-form').find('input[name*="tx_solr[filter][]"]');
    let filterSelected = false;
    $(txSolrFilter).each(function(){
        if($(this).prop('checked')==true){
            filterSelected = true;
        }
    });
    //If the selectedFilter array is empty, then disable the filter button. If there are fselected filter, then undisable he button
    if(filterSelected == true){
        $('.apply-filter').removeClass('disabled');
    } else {
        $('.apply-filter').addClass('disabled');
    }



    //The same from above, but fired when a select has changed
    $('#mobile-filter-form').find('input[name*="tx_solr[filter][]"]').on('change',function(){
        filterSelected = false;
        $(txSolrFilter).each(function(){
            if($(this).prop('checked')==true){
                filterSelected = true;
            }
        });
        if($('#mobile-filter-form').find('input[name*="tx_solr[q]"]').length){
            if($('#mobile-filter-form').find('input[name*="tx_solr[q]"]').attr('type')!=='hidden'){
                if($('#mobile-filter-form').find('input[name*="tx_solr[q]"]').val()!==''){
                    filterSelected = true;
                }
            }
        }
        if(filterSelected == true){
            $('.apply-filter').removeClass('disabled');
        } else {
            $('.apply-filter').addClass('disabled');
        }
    });



    if($('#mobile-filter-form').find('input[name*="tx_solr[q]"]').length){
        $('#mobile-filter-form').find('input[name*="tx_solr[q]"]').on('input',function(){
            filterSelected = false;
            $(txSolrFilter).each(function(){
                if($(this).prop('checked')==true){
                    filterSelected = true;
                }
            });
            if($(this).val()==''){
                if(filterSelected==false){
                    $('.apply-filter').addClass('disabled');
                } else {
                    $('.apply-filter').removeClass('disabled');
                }
            } else {
                $('.apply-filter').removeClass('disabled');
            }
        });
    }
    //prevent default, when the button is disabled
    $('.apply-filter').click(function(e){
        if($(this).hasClass('disabled')){
            e.preventDefault();
        } else {
            let spinner =   '<div class="spinner-background">'+
                                '<div class="spinner-border text-light" role="status">'+
                                    '<span class="sr-only">Loading...</span>'+
                                '</div>'+
                            '</div>';
            $(spinner).appendTo('body');
        }
    });

    if($('.solr-filter-bar-mobile-content').length){
        // let windowHeight = $(window).height();
        // const resizeObserver = new ResizeObserver(entries => {
        //     if(entries[0].target.clientHeight > windowHeight) {
        //         $('.apply-filter').addClass('sticky');
        //     } else {
        //         $('.apply-filter').removeClass('sticky');
        //     }
        // });
        // resizeObserver.observe(document.getElementById('solr-filter-bar-mobile-content'));
    }
}

function iniSaveSolrSearchTermInSession() {
    const lastSearchesLimit = 3;

    if($('.solr-searchbar__search-form').length){
      $('.solr-searchbar__search-form').each(function(){
        let searchForm = this;
        $(searchForm).on('submit',function(){
          //save to session
          let solrSession = [];
          let searchTerm = $(searchForm).find('.tx-solr-q').val();
          if(window.localStorage.getItem('solr-searchterms')!=null && window.localStorage.getItem('solr-searchterms')!='undefined'){
            if(window.localStorage.getItem('solr-searchterms').includes(',')){
              solrSession = window.localStorage.getItem('solr-searchterms').split(',');
            }else{
              if(window.localStorage.getItem('solr-searchterms')!=''){
                solrSession = [window.localStorage.getItem('solr-searchterms')];
              }else{
                solrSession = [];
              }
            }
          }
          if(searchTerm&&searchTerm!=''){
            solrSession.push(searchTerm);
          }
          solrSession = [...new Set(solrSession)];
          if(solrSession.length>lastSearchesLimit){
            let slice = solrSession.length - lastSearchesLimit;
            if(slice>0){
              solrSession = solrSession.slice(slice);
            }
          }
          window.localStorage.setItem('solr-searchterms', solrSession);
        });
      });
    }
}

function solrResultHandler() {
    const handleResize = () => {
        if ($(window).width() < 561) {
            $(".solr-search-result-count-per-page").addClass("mobile");
            $(".no-newsroom").parents(".results-list").addClass("mobile");
        } else {
            $(".solr-search-result-count-per-page").removeClass("mobile");
            $(".no-newsroom").parents(".results-list").removeClass("mobile");
        }
    }
    $(window).on("resize", () => {
        handleResize();
    });
}

function solrSearchbarHandler() {
    // Set Cursor to "not allowed" if search is empty
    if ($('#solr-searchbar-input').val() == '') {
        $('#solr-search-btn').css('cursor', 'not-allowed');
        $('#solr-search-btn').addClass('link-disabled');
    }
    if ($('#solr-searchbar-nav-input').val() == '') {
        $('#solr-search-btn-nav').css('cursor', 'not-allowed');
        $('#solr-search-btn-nav').addClass('link-disabled');
    }

    // handle cursor on input
    $('#solr-searchbar-input').on('input focus', () => {
        if ($('#solr-searchbar-input').val() == '') {
            $('#solr-search-btn').css('cursor', 'not-allowed');
            $('#solr-search-btn').addClass('link-disabled');
        } else {
            $('#solr-search-btn').css('cursor', 'pointer');
            $('#solr-search-btn').removeClass('link-disabled');
        }
    });
    $('#solr-searchbar-nav-input').on('input focus', () => {
        if ($('#solr-searchbar-nav-input').val() == '') {
            $('#solr-search-btn-nav').css('cursor', 'not-allowed');
            $('#solr-search-btn-nav').addClass('link-disabled');
        } else {
            $('#solr-search-btn-nav').css('cursor', 'pointer');
            $('#solr-search-btn-nav').removeClass('link-disabled');
        }
    });

    // Searchbar Clear-Button:
    $("#cancel-search-btn, #cancel-search-btn-nav, #cancel-search-btn-nav-mobile").on("click", (e) => {
      let target = e.currentTarget;
      let form = $(target).closest('form');
      let inputField = form.find('input.solr-searchbar__search-input')
      let autocompleteBox = form.find('.tx-solr-autocomplete')
        $("#solr-searchbar-input, #solr-searchbar-nav-input, #solr-searchbar-input-mobile").val("");
        $(".autocomplete-suggestions.tx-solr-autosuggest").hide();
        $("#cancel-search-btn, #cancel-search-btn-nav, #cancel-search-btn-nav-mobile").addClass("hide");
        setTimeout(function(){
          solrShowOnlyLastSeachesAutosuggest(form,autocompleteBox);
          inputField.focus();
        },300);
    });

    // show/hide "delete-search-button" on input changes
    //$("#cancel-search-btn, #cancel-search-btn-nav").removeClass("hide");

    if ($("#solr-searchbar-input").val() == "") {
        $("#cancel-search-btn").addClass("hide");
    } else {
        $("#cancel-search-btn").removeClass("hide");
    }
    if ($("#solr-searchbar-nav-input").val() == "") {
        $("#cancel-search-btn-nav").addClass("hide");
    } else {
        $("#cancel-search-btn-nav").removeClass("hide");
    }
    if ($("#solr-searchbar-input-mobile").val() == "") {
        $("#cancel-search-btn-nav-mobile").addClass("hide");
    } else {
        $("#cancel-search-btn-nav-mobile").removeClass("hide");
    }
    $("#solr-searchbar-nav-input, #solr-searchbar-input, #solr-searchbar-input-mobile").on("input focus", () => {
        //$("#cancel-search-btn, #cancel-search-btn-nav").removeClass("hide");

        if ($("#solr-searchbar-input").val() == "") {
            $("#cancel-search-btn").addClass("hide");
        } else {
            $("#cancel-search-btn").removeClass("hide");
        }
        if ($("#solr-searchbar-nav-input").val() == "") {
            $("#cancel-search-btn-nav").addClass("hide");
        } else {
            $("#cancel-search-btn-nav").removeClass("hide");
        }
        if ($("#solr-searchbar-input-mobile").val() == "") {
            $("#cancel-search-btn-nav-mobile").addClass("hide");
        } else {
            $("#cancel-search-btn-nav-mobile").removeClass("hide");
        }
    });

    // custom width for Autosuggest -not newsroom
    $("#solr-searchbar-input").on("focus input", () => {
        $(".autocomplete-suggestions").addClass("custom-size-suggest");

        if ($(".autocomplete-suggestions")) {
            $(".custom-size-suggest").css("width", $("#solr-searchbar-input").outerWidth()+"px");
        }
    });

    $("#solr-searchbar-nav-input").on("focus input", () => {
        $(".autocomplete-suggestions").addClass("custom-size-suggest");

        if ($(".autocomplete-suggestions")) {
            $(".custom-size-suggest").css("width", $("#solr-searchbar-nav-input").outerWidth()+"px");
        }
    });

    $("#solr-searchbar-input-mobile").on("focus input", () => {
        $(".autocomplete-suggestions").addClass("custom-size-suggest");

        if ($(".autocomplete-suggestions")) {
            $(".custom-size-suggest").css("width", $("#solr-searchbar-nav-input-mobile").outerWidth()+"px");
        }
    });

    // custom width for Autosuggest -newsroom
    $("#solr-news-filter-filter-search-box").on("focus input", () => {
        $(".autocomplete-suggestions").addClass("custom-size-suggest");
        $(".custom-size-suggest").css("width", $("#solr-news-filter-filter-search-box").outerWidth()+"px");
    });
}

/**
 * Custom width for autosuggest (News & Media only)
 */
function solrSearchBoxNewsMedia() {
    $(".solr-news-filter__filter-search-box").on("input", () => {
        $autocompleteSuggestions.addClass("custom-size-suggest");

        if ($autocompleteSuggestions) {
        $(".custom-size-suggest").css("width", "1110px");
        }
    });
}

function solrMobileFilterButtonListener() {
    if($('.open-filter-bar-mobile').length){
        $('.open-filter-bar-mobile').each(function(){
            let filterButton = $(this);
            if(filterButton.closest('.frame-type-list').length){
                filterButton.closest('.frame-type-list').on("DOMNodeInserted", function (autocompleteEvent){
                    initFilterPopupButton();
                });
            }
        });
    }
}

function solrLastSearchesAutosuggestListener() {
    // if solr search form exist
    if($('.tx-solr-search-form-pi-results').length){
      // for each solr search form
      $('.tx-solr-search-form-pi-results').each(function(){
        let form = $(this);
        // if solr autocomplete box exists
        if(form.find('.tx-solr-autocomplete').length){
          // for each solr autocomplete box
          form.find('.tx-solr-autocomplete').each(function(){
            let autocompleteBox = $(this);
            // on change of autocomplete box (every time is gets filled)
            autocompleteBox.on("DOMNodeInserted", function (autocompleteEvent) {
              // if we are not changing the autocomplete box at this moment
              if(!autocompleteBox.hasClass('solr-hack-performing')){
                let lastSearchesHtml = solrGetLastSearchesAutosuggestHtml(form);
                solrAddLastSearchesAutosuggestHtml(form,autocompleteBox,lastSearchesHtml);
              }
            });
          })
        }
      });
    }
}

function solrGetLastSearchesAutosuggestHtml(form) {
    const lastSearchesLimit = 3;
    let lastSearchesHtml = '';
    // if there are last searches in session
    if(window.localStorage.getItem('solr-searchterms')!=null && window.localStorage.getItem('solr-searchterms')!='undefined' && window.localStorage.getItem('solr-searchterms')!=''){
      // get last searches from session
      let lastSeaches;
      if(window.localStorage.getItem('solr-searchterms').includes(',')){
        lastSeaches = window.localStorage.getItem('solr-searchterms').split(',');
      }else{
        lastSeaches = [window.localStorage.getItem('solr-searchterms')];
      }

      let headerLastSearches = 'Your last searches';
      if(form.find('input[data-last-searches-header]').length){
        headerLastSearches = form.find('input[data-last-searches-header]').attr('data-last-searches-header');
      }

      // if there are last searches, fill the html for autocomplete box
      let i = 0;
      if(Array.isArray(lastSeaches)){
        // set header of last seaches for autocomplete box
        lastSearchesHtml = '<div class="autocomplete-group last-search-suggestions-header">'+headerLastSearches+'</div>';
        lastSeaches = lastSeaches.reverse();
        lastSeaches.forEach(function(item){
          if(item!=''){
            if(i<lastSearchesLimit){
              lastSearchesHtml+= '<div class="autocomplete-suggestion last-search-suggestion">'+item+'</div>';
              i++;
            }
          }
        })
      }
      return lastSearchesHtml;
    }
}

function solrAddLastSearchesAutosuggestHtml(form,autocompleteBox,lastSearchesHtml) {
    let isMobile = autocompleteBox.hasClass('tx-solr-autocomplete-mobile');
    // if we are not changing the autocomplete box at this moment
    if(!autocompleteBox.hasClass('solr-hack-performing')){
      // if the need container exists
      if(autocompleteBox.find('.autocomplete-suggestions').length){
        // add class which marks the current hacking process
        autocompleteBox.addClass('solr-hack-performing');
        // add last searches html to autocompletet box
        if(!autocompleteBox.find('.autocomplete-suggestions .last-search-suggestions-container').length){
          autocompleteBox.find('.autocomplete-suggestions').append('<div class="last-search-suggestions-container"></div>');
        }
        autocompleteBox.find('.autocomplete-suggestions .last-search-suggestions-container').html(lastSearchesHtml);
        // add search button for mobile
        if(isMobile){
          if(!autocompleteBox.find('.solr-mobile-search-button').length){

            let searchButtonText = 'Search';
            if(form.find('.solr-searchbar__search-input').attr('data-search-button-text')){
              searchButtonText = form.find('.solr-searchbar__search-input').attr('data-search-button-text');
            }
            autocompleteBox.find('.autocomplete-suggestions').append('<button type="submit" class="button-secondary w-100 solr-mobile-search-button">'+searchButtonText+'</button>');
          }
        }
        // if last searches options were set init the click event
        if(autocompleteBox.find('.last-search-suggestion').length){
          autocompleteBox.find('.last-search-suggestion').each(function(){
            let lastSearchSuggestOption = $(this);
            // init click event
            lastSearchSuggestOption.on('click',function(optionEvent){
              optionEvent.preventDefault();
              let lastSearchValue = lastSearchSuggestOption.html();
              let inputField = $(form).find('.tx-solr-suggest');
              // fill input field and submit form
              inputField.val(lastSearchValue);
              form.prepend('<input type="hidden" id="solr-was-previous" name="tx_solr[wasPrevious]" value="1">');
              form.submit();
            });
          })
        }
        // send GTM DataLayer at click on autosuggest result
        autocompleteBox.find('.autocomplete-suggestion:not(.last-search-suggestion)').each(function(){
          let autoSuggestion = $(this);
          if(autoSuggestion.find('a').length){
            autoSuggestion.find('a').off();
            autoSuggestion.find('a').on('click',function(){
              let clickDataLayer = {
                'dL-searchQuery': form.find('.tx-solr-q').val(), //search query
                'dL-resultPage': autoSuggestion.find('a').attr('href'), //Page path of clicked result
                'event': 'gtmEvent-linkClick'
              };
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push(clickDataLayer);
            });
          }else{
            autoSuggestion.on('click',function(){
              if(form.find('#solr-was-suggestion').length==0){
                form.prepend('<input type="hidden" id="solr-was-suggestion" name="tx_solr[wasSuggestion]" value="1">');
              }
            });
          }
        })

        // remove class which marks the current hacking process
        $(autocompleteBox).removeClass('solr-hack-performing');
      }
    }
}

function solrOnFocusLastSearches() {
    if($('input.tx-solr-suggest').length){
      $('input.tx-solr-suggest').each(function(){
        let searchField = this;
        let form = $(searchField).closest('form');
        let autocompleteBox = form.find('.tx-solr-autocomplete');
        if(autocompleteBox.length){
          $(searchField).on('focus',function(){
            if($(searchField).val()===''){
              solrShowOnlyLastSeachesAutosuggest(form,autocompleteBox);
            }
          });
        };
      })
    }
}

function solrShowOnlyLastSeachesAutosuggest(form,autocompleteBox) {
    if(autocompleteBox.find('.last-search-suggestions-container').length===0){
      let lastSearchesHtml = solrGetLastSearchesAutosuggestHtml(form);
      solrAddLastSearchesAutosuggestHtml(form,autocompleteBox,lastSearchesHtml);
    }
    autocompleteBox.find('.autocomplete-suggestions>.autocomplete-suggestion').remove();
    autocompleteBox.find('.autocomplete-suggestions>.autocomplete-group').remove();
    autocompleteBox.find('.autocomplete-suggestions').addClass('tx-solr-autosuggest');
    autocompleteBox.find('.autocomplete-suggestions').show();
}

function solrAddOriginalSolrLoader(form) {
    form.submit(function(){
      let solrContainer = form.closest(".tx_solr");
      let solrParent = solrContainer.parent();
      let loader = jQuery("<div class='tx-solr-loader'></div>");
      solrParent.append(loader);
    })
}

function solrAddSearchSpinner(form, overlay, placeToBe, activeClass) {
    form.submit((e) => {
        if (overlay.hasClass(activeClass)) {
            placeToBe.css({'display':'flex','justify-content':'center','align-items':'center'});
            placeToBe.html('<div class="spinner-border solr-spinner" />');
            $('.solr-spinner').css('display', 'inline-block');
        }
    });
}

function solrInitSpinner() {
    // Handle behaviour after submitting form
    if($('#tx-solr-search-form-pi-results-nav').length){
      solrAddSearchSpinner($('#tx-solr-search-form-pi-results-nav'), $('.level-2-navi-overlay.search'), $('.level-2-search-col'), 'active');
    }
    if($('#tx-solr-search-form-pi-results-mobile').length){
      solrAddSearchSpinner($('#tx-solr-search-form-pi-results-mobile'), $('#mobile-search-container'), $('.mobile-search-overlay'), 'open');
    }
    if($('#tx-solr-search-form-pi-results').length){
      let form = $(document.getElementById('tx-solr-search-form-pi-results'));
      solrAddOriginalSolrLoader(form);
    }
    if($('.tx_solr #results-per-page form').length){
      let form = $('.tx_solr #results-per-page form');
      solrAddOriginalSolrLoader(form);
    }
}

function solrDataLayerHandler() {
    if($('.tx_solr.layout-split').length){
      const urlParams = new URLSearchParams(window.location.search);
      let solrQ = '';
      let solrCategory = '';
      let solrNumberResults = $('#tx-solr-search .result-count').html();
      let solrFilter = '';
      let wasPrevious = false;
      let wasSuggestion = false;

      if(urlParams.get('tx_solr[q]')){
        solrQ = urlParams.get('tx_solr[q]');
      }
      if(urlParams.get('tx_solr[filter][0]')){
        solrCategory = urlParams.get('tx_solr[filter][0]').split(':')[1];
      }
      if(urlParams.get('tx_solr[wasPrevious]')){
        wasPrevious = true;
      }
      if(urlParams.get('tx_solr[wasSuggestion]')){
        wasSuggestion = true;
      }

      let iFilter = 1;
      while (urlParams.get('tx_solr[filter]['+iFilter+']')) {
        if(solrFilter!=''){
          solrFilter += ',';
        }
        solrFilter += urlParams.get('tx_solr[filter]['+iFilter+']');
        iFilter++;
      }

      let arrFilter = [];
      if($('#tx-solr-search-functions .facets>.facet').length){
        $('#tx-solr-search-functions .facets>.facet').each(function(){
          let facet = $(this);
          let facetName = facet.attr('id');
          let arrFacet = [];
          if(facetName=='facet-accordiontype'){
            if(facet.find('.facet-option-list a').length){
              facet.find('.facet-option-list a').each(function(){
                let option = $(this);
                let optionVal = '';
                optionVal = option.closest('.facet-option').attr('data-facet-item-value');
                let optionChecked = false;
                if(option.hasClass('selected')){
                  optionChecked = true;
                }
                arrFacet.push({value:optionVal,selected:optionChecked});
              })
            }

          }else{
            if(facet.find('input').length){
              facet.find('input').each(function(){
                let option = $(this);
                let optionVal = '';
                if(facetName=='facet-accordionyears'){
                  optionVal = option.closest('.facet-option').attr('data-facet-item-value');
                }else{
                  optionVal = option.closest('.facet-option').attr('data-facet-item-value');
                }
                let optionChecked = false;
                if(option.attr('checked')=='checked'){
                  optionChecked = true;
                }
                arrFacet.push({value:optionVal,selected:optionChecked});
              })
            }
          }
          arrFilter.push({facet:facetName,options:arrFacet});
        })
      }


      let tmpDataLayer = {
        'dL-pageType': 'searchresult',
        'dL-searchQuery': solrQ,
        'dL-searchCategory': solrCategory,
        'dL-searchFilter': arrFilter,
        'dL-wasSuggestion': wasSuggestion,
        'dL-wasPrevious': wasPrevious,
        'dL-numberResults': solrNumberResults,
        'event': 'gtmEvent-pageView'
      };
      console.log(tmpDataLayer);

      if($('.solr-ajaxified:not(.page-link)').length){
        $('.solr-ajaxified:not(.page-link)').on('click',function(){
          let solrContainer = $(this).closest('.tx_solr.layout-split');
          if(solrContainer.length){
            solrContainer.addClass('datalayer-reload');
              solrReloadDataLayerHandler();
          }
        });
      }

      if($('.solr-search-document').length){
        $('.solr-search-document').each(function(){
          let document = $(this);
          document.on('click',function(){
            let clickDataLayer = {
              'dL-resultPage': document.data('document-url'), //Page path of clicked result
              'event': 'gtmEvent-linkClick'
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(clickDataLayer);
          })
        })
      }

      if(solrQ){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(tmpDataLayer);
      }
    }
}

function solrReloadDataLayerHandler() {
    if($('.tx_solr.layout-split').length){
      if($('.tx_solr.layout-split').hasClass('datalayer-reload')){
        setTimeout(function(){
          solrReloadDataLayerHandler();
        },500);
      }else{
        solrDataLayerHandler();
      }
    }
}

function solrFilterAccordionHandler() {
    if($('.tx_solr.layout-split #tx-solr-search-functions').length){

      let sessionItem = JSON.parse(window.localStorage.getItem('solr-facets-accordions'));
      let searchword = $('#solr-searchbar-input').val();
      let tab = $('#facet-accordiontab .facet.selected').parent().data('facet-item-value');

      if(tab != '' && tab != undefined && searchword != '' && searchword != undefined && sessionItem != '' && sessionItem != undefined){
        if(sessionItem.tab == tab && sessionItem.searchword == searchword){
          if(sessionItem.facets.length){
            sessionItem.facets.forEach(function(facet){
              if(facet.panels.length){
                facet.panels.forEach(function(panel){
                  if(panel.opened){
                    $(panel.name).collapse('show');
                  }
                })
              }
            });
          };
        }else{
          window.localStorage.setItem('solr-facets-accordions', '{}');
        }
      }

      $('.tx_solr.layout-split #tx-solr-search-functions .panel-collapse.collapse').on('shown.bs.collapse', function () {
        solrSaveFilterAccordions();
      });
      $('.tx_solr.layout-split #tx-solr-search-functions .panel-collapse.collapse').on('hidden.bs.collapse', function () {
        solrSaveFilterAccordions();
      });

      if($('.solr-ajaxified').length){
        $('.solr-ajaxified').on('click',function(){
          $('.tx_solr.layout-split').addClass('filter-accordion-reload');
          solrReloadFilterAccordionHandler();
        });
      }
      if($('.facet.facet-type:not(#facet-accordiontab) .solr-ajaxified').length){
        $('.facet.facet-type:not(#facet-accordiontab) .solr-ajaxified').on('click',function(){
          if($('.tx_solr.layout-split').length){
            $('.tx_solr.layout-split').parent().addClass('facet-filter-clicked');
            $('.tx_solr.layout-split').parent().attr('data-scrolltop',document.documentElement.scrollTop);
          }
        });
      }
    }
}

function solrReloadFilterAccordionHandler(){
    if($('.tx_solr.layout-split').length){
      if($('.tx_solr.layout-split').hasClass('filter-accordion-reload')){
        setTimeout(function(){
          solrReloadFilterAccordionHandler();
        },500);
      }else{
        solrFilterAccordionHandler();
      }
    }
}

function solrSaveFilterAccordions() {
    let searchword = $('#solr-searchbar-input').val();
    let tab = $('#facet-accordiontab .facet.selected').parent().data('facet-item-value');
    let arrFacets = [];
    if($('#tx-solr-search-functions').length){
      $('#tx-solr-search-functions .facets>.facet').each(function(){
        let facet = $(this);
        let facetId = facet.attr('id');
        let arrFacet = [];
        if(facet.find('.panel-heading').length){
          facet.find('.panel-heading').each(function(){
            let panel = $(this);
            let panelId = panel.find('a').attr('href');
            let opened = false;
            if($(panelId).length && $(panelId).hasClass('show')){
              opened = true;
            }
            let arrPanel = {name:panelId,opened:opened};
            arrFacet.push(arrPanel);
          })
        }
        arrFacets.push({name:facetId,panels:arrFacet});
      });
    }
    let sessionItem = {searchword:searchword,tab:tab,facets:arrFacets};
    window.localStorage.setItem('solr-facets-accordions', JSON.stringify(sessionItem));
}

/**
 * Handles the mobile filter sticky behaviour
 *
 * The init()-method offers multiple options to customize the behaviour. See the method-comments for more details.
 */
const MobileFilterHandler = {
  /**
   * Initializes the mobile filter sticky behaviour
   *
   * @param {object} elFilter - The filter element
   * @param {object} elFilteredArea - Is need in order calculate the sticky behaviour of the filter element.
   * @param {int} additionalOffsetTop - Additional offset top for the filter element. This is useful if you have a fixed header.
   * @param {string} fixedFilterClassName - The class name which will be added to the filter element if it is sticky.
   * @param maxWindowWidth - The max window width for the sticky behaviour. If the window width is greater than this value, the filter element will not be sticky.
   */
  init: function (elFilter, elFilteredArea, additionalOffsetTop = 0, fixedFilterClassName = 'fixed', maxWindowWidth = 991.8) {
    this.elFilter = elFilter;
    this.elFilteredArea = elFilteredArea;
    this.additionalOffsetTop = additionalOffsetTop;
    this.fixedFilterClassName = fixedFilterClassName;
    this.maxWindowWidth = maxWindowWidth;

    if (!elFilter || !elFilteredArea) {
      return;
    }

    this.elBody = document.querySelector('body');

    this.initialFilterOffsetTop = this.elFilter.offsetTop;

    this.filterHeight = this.elFilter.offsetHeight;
    this.sumFilterAndAdditionalOffsetTop = this.filterHeight + this.additionalOffsetTop;
    window.addEventListener('resize', () => {
      this.filterHeight = this.elFilter.offsetHeight;

      this.applyStickyness();
    });

    window.addEventListener('scroll', () => {
      if (getScrollDirection() === 'up') {
        this.elFilter.classList.add('solr-news-filter_scroll-up');
      } else {
        this.elFilter.classList.remove('solr-news-filter_scroll-up');
      }
    });

    this.applyStickyness();
    window.addEventListener('scroll', () => {
      this.applyStickyness();
    });
  },

  isPartiallyInViewport: function (el, offsetTop = 0, offsetBottom = 0) {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    const vertInView = (rect.top <= windowHeight) && ((rect.top-offsetTop + rect.height-offsetBottom) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
  },

  applyStickyness: function () {
    if (
      this.isPartiallyInViewport(this.elFilteredArea, 0, this.sumFilterAndAdditionalOffsetTop)
      && this.initialFilterOffsetTop < window.scrollY+this.additionalOffsetTop
      && this.elFilter.offsetTop < window.scrollY+this.additionalOffsetTop
      && window.innerWidth <= this.maxWindowWidth
    ) {
      this.elFilter.classList.add(this.fixedFilterClassName);
      this.elBody.style.paddingTop = this.filterHeight + 'px';
    } else {
      this.resetElements()
    }
  },

  resetElements: function () {
    this.elFilter.classList.remove(this.fixedFilterClassName);
    this.elBody.style.paddingTop = '0';
  }
};

export {
    solrResultHandler,
    solrLastSearchesAutosuggestListener,
    initSolr,
    solrSearchbarHandler,
    initMobileNewsFilter,
    initMobileNewsFilterButton,
    mobileRemoveFilter,
    mobileFilterCount,
    solrMobileFilterButtonListener,
    iniSaveSolrSearchTermInSession,
    initSolrResultsPerPage,
    solrOnFocusLastSearches,
    solrInitSpinner,
    solrDataLayerHandler,
    solrFilterAccordionHandler,
    initFilterPopupButton,
    MobileFilterHandler
};

