/**
 * LocalVideoHandler
 *
 * @description Setups all videos on the current page that are marked as local videos by class `.video-embed-precheck_local-file`.
 * YouTube-Videos require a cookie consent, and are not touched by this handler. They are handled by the `usercentrics.js`.
 * @see usercentrics.js
 * @author Patrick Lautsch <patrick.lautsch@rheinschafe.de>
 * @type {{init: LocalVideoHandler.init, setupLocalVideo: LocalVideoHandler.setupLocalVideo}}
 */
const LocalVideoHandler = {
  init: function () {
    this.instances = document.querySelectorAll('.video-embed-precheck_local-file');
    if (this.instances.length === 0) return;

    this.instances.forEach(instance => {
      this.setupLocalVideo(instance);
    });
  },

  setupLocalVideo: function (instance) {
    const video = instance.parentElement.querySelector('.video-embed-item');

    instance.addEventListener('click', () => {
      if (video) {
        video.play();

        instance.remove();
      }
    });
  }
};

export {LocalVideoHandler};
