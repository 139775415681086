function formGtm() {
  // Auslesen des Wertes aus dem Local Storage
  var sessionStorageValue = sessionStorage.getItem('gtm_mail');

  // Überprüfen, ob Elemente mit der Klasse 'powermail_form' vorhanden sind
  var formExists = document.querySelector('.powermail_form') !== null;

  // Wenn die Klasse 'powermail_form' nicht existiert (Keine Powermailseite), Alert mit dem Local Storage Wert anzeigen
  if (!formExists && sessionStorageValue !== null) {

    // Den String in ein Array von Arrays umwandeln
    var arrayOfArrays = JSON.parse(sessionStorageValue);

    // Ein leeres Objekt erstellen, um die Daten zu sammeln
    var dataLayerObject = {};

    // Jedes Unterarray durchgehen, um Schlüssel-Wert-Paare im Objekt zu speichern
    arrayOfArrays.forEach(function(item) {
        dataLayerObject[item[0]] = item[1];
    });

    dataLayer.push(dataLayerObject);
    sessionStorage.removeItem('gtm_mail');
  }

  // Wenn die Klasse 'powermail_form' existiert (Formularseite), leere Sessionstorage
  // Vermutlich hat die Person einen Validierungsfehler. Wenn Sie nun das Formular nicht erneut absendet
  // und auf eine andere Seite geht, dann wir das Event trotzdem gesendet.
  // Wenn sie nun neu auf Absenden klickt, wir die Session neu befüllt
  if (formExists && sessionStorageValue !== null) {
    sessionStorage.removeItem('gtm_mail');
  }

}

export { formGtm };
