/**
 * Applies the "Cut-off Corner"-Style to table cells.
 *
 *
 */
function initTextMediaTableHighlight() {
    let tables = $(".table");

    tables.each(function () {
        let rowsCount = $(this).find('tr').length;
        let colsCount = $(this).find('th, td').length / rowsCount;
        let hasHighlightCount = $(this).find('.table-highlight').length > 0;

        // specific table has at least on cell highlighted
        if (hasHighlightCount === true) {
            applyDataHighlight(this);
            checkTableCellCutOffCorner(this, rowsCount, colsCount);
        }
    });
}

/**
 * Applies an attribute (data-table-cell-highlight="1") for
 *   table-cells that are highlighted.
 *
 * Note: a single cell is considered "highlighted", if:
 *   1). the row posses a "table-highlight"-class
 *   2). the cell itself posses a "table-highlight"-class
 */
function applyDataHighlight(table) {
    $(table)
        .find('th, td')
        .each(function (index) {
            $(this).attr('data-table-cell-index', index);

            if ($(this).hasClass('table-highlight')) {
                $(this).attr('data-table-cell-highlight', '1');
            }
        });

    $(table)
        .find('tr.table-highlight th, tr.table-highlight td')
        .each(function () {
            $(this).attr('data-table-cell-highlight', '1')
        });
}

/**
 * Checks if a table-cell should have a "cut-off corner".
 *
 * A table-cell should have a "cut-off corner", if:
 *   1). the cell itself is highlighted (data-table-cell-highlight)
 *   2). the cell has no right neighbor, that is highlighted.
 *   3). the cell has no bottom neighbor, that is highlighted.
 */
function checkTableCellCutOffCorner(table, rowsCount, colsCount) {
    $(table)
        .find('[data-table-cell-highlight]')
        .each(function () {
            let currentCellIndex = Number($(this).attr('data-table-cell-index'));
            let rightNeighborCellIndex = currentCellIndex + 1;
            let bottomNeighborCellIndex = currentCellIndex + colsCount;
            if ((currentCellIndex % colsCount) !== (colsCount - 1)) {
                // check right-neighbor
                if ($(table).find('[data-table-cell-index="' + rightNeighborCellIndex + '"][data-table-cell-highlight]').length !== 0) {
                    return;
                }
            }

            // current cell has bottom-neighbor at all?
            if ($(table).find('[data-table-cell-index="' + bottomNeighborCellIndex + '"][data-table-cell-highlight]').length !== 0) {
                return;
            }

            $(this).addClass('cut-off-corner');
        });
}

export { initTextMediaTableHighlight };