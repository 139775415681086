let magicGridsBlog = [];

const MOBILE_BREAKPOINT = 768;

function initBlogBreadcrumbs() {
  if ($('.blog__detail').length) {
    var breadcrumbs = $('.breadcrumb-container').html();
    $('.remove-breadcrumbs').remove();
    $('#breadcrumb-container').html(breadcrumbs);
  }
}

function initMagicGridBlog() {
  if ($('.magic-grid-news').length) {
    $('.magic-grid-news').each(function () {
      let curElement = this;
      let magicGridBlog = new MagicGrid({
        container: curElement,
        animate: false,
        gutter: 0,
        static: true,
        useMin: false
      });

      magicGridBlog.listen();
      magicGridsBlog.push(magicGridBlog);
    });
  }
}

/**
 * On mobile viewports, the share buttons are made sticky.
 *
 * @see MOBILE_BREAKPOINT
 */
function initBlogDetailShare() {
  const editorialBlogPost = document.querySelector('.editorial__stage_blog-post');
  if (!editorialBlogPost) return;

  let controller = undefined;

  let viewport = getViewport();
  if (viewport === 'mobile') {
    controller = new ScrollMagic.Controller();
    setupScrollMagicAnimation(editorialBlogPost).addTo(controller);
  }
  window.addEventListener('resize', () => {
    if (viewport !== getViewport()) {
      viewport = getViewport();

      if (viewport === 'mobile') {
        controller = new ScrollMagic.Controller();
        setupScrollMagicAnimation(editorialBlogPost).addTo(controller);
      } else {
        resetGsapAnimation(editorialBlogPost);
        controller.destroy(true);
      }
    }
  });
}

function setupScrollMagicAnimation(editorialBlogPost) {
  const {
    shareButtons,
    blogDetailShareTitle,
    blogDetailShareLinks,
    blogShareItemClose,
    showShareButtonTrigger
  } = getElements(editorialBlogPost);

  const footer = document.querySelector('footer');
  const duration = distanceBetweenToElements(footer, showShareButtonTrigger) - window.innerHeight / 2;
  const scene = new ScrollMagic.Scene({
    triggerElement: showShareButtonTrigger,
    duration: duration,
  });

  const tlShowShareLinks = getGsapAnimationTimelineShareLinksReveal(blogDetailShareTitle, blogDetailShareLinks);
  const tlShareButtonReveal = getGsapAnimationTimelineShareButtonReveal(shareButtons);

  scene.on('enter', () => {
    tlShareButtonReveal.play();
  });

  scene.on('leave', () => {
    tlShareButtonReveal.reverse();
  });

  blogDetailShareTitle.addEventListener('click', (evt) => {
    if (getViewport() !== 'mobile') return;

    evt.preventDefault();
    tlShowShareLinks.play();
  });

  blogShareItemClose.addEventListener('click', (evt) => {
    if (getViewport() !== 'mobile') return;

    evt.preventDefault();
    tlShowShareLinks.reverse();
  });

  return scene;
}

function resetGsapAnimation(editorialBlogPost) {
  const {
    shareButtons,
    blogDetailShareTitle,
    blogDetailShareLinks
  } = getElements(editorialBlogPost);

  gsap.to(blogDetailShareTitle, {clearProps: true});
  gsap.to(blogDetailShareLinks, {clearProps: true});
  gsap.to(shareButtons, {clearProps: true});
}

/**
 * Returns an object with the elements needed for ScrollMagic/Gsap.
 *
 * @param {HTMLElement} editorialBlogPost
 * @returns {{shareButtons: HTMLElement, blogDetailShareTitle: HTMLElement, blogDetailShareLinks: HTMLElement, blogShareItemClose: HTMLElement, showShareButtonTrigger: HTMLElement}}
 */
function getElements(editorialBlogPost) {
  if (!editorialBlogPost) return;

  const shareButtons = editorialBlogPost.querySelector('.blog__detail__share');
  if (!shareButtons) return;

  const showShareButtonTrigger = document.querySelector('#blog-detail-share-trigger');
  if (!showShareButtonTrigger) return;

  const blogDetailShareTitle = shareButtons.querySelector('.blog__detail__share__title');
  const blogDetailShareLinks = shareButtons.querySelector('.blog__detail__share__links');
  const blogShareItemClose = shareButtons.querySelector('#share-item-close');
  if (!blogDetailShareTitle || !blogDetailShareLinks || !blogShareItemClose) return;

  return {
    shareButtons,
    blogDetailShareTitle,
    blogDetailShareLinks,
    blogShareItemClose,
    showShareButtonTrigger
  }
}

/**
 * Defines and returns the GSAP animation timeline for the share button reveal.
 *
 * @returns {object} - GSAP animation timeline
 */
function getGsapAnimationTimelineShareButtonReveal(shareButtons) {
  const gsapTimeline = gsap.timeline({paused: true});

  gsapTimeline.fromTo(shareButtons,
    {
      bottom: '-48px'
    },
    {
      bottom: 0,
      duration: 0.5,
      ease: 'power1.inOut',
    }
  );

  return gsapTimeline;
}

/**
 * Defines and returns the GSAP animation timeline for the reveal of the share links.
 *
 * Note: Reverse this timeline to hide the share links.
 *
 * @param {HTMLElement} blogDetailShareTitle - The share title element
 * @param {HTMLElement} blogDetailShareLinks - The share links element
 *
 * @returns {object} - GSAP animation timeline
 */
function getGsapAnimationTimelineShareLinksReveal(blogDetailShareTitle, blogDetailShareLinks) {
  if (!blogDetailShareTitle || !blogDetailShareLinks) return;

  const gsapTimeline = gsap.timeline({paused: true});

  gsapTimeline.fromTo(
    blogDetailShareTitle,
    {
      x: 0
    },
    {
      x: "-200%",
      duration: 0.5,
      ease: 'power1.inOut',
    },
    0
  );

  gsapTimeline.fromTo(
    blogDetailShareLinks,
    {
      x: "200%"
    },
    {
      x: 0,
      duration: 0.5,
      ease: 'power1.inOut',
    },
    0
  );

  return gsapTimeline;
}

function distanceBetweenToElements(element1, element2) {
  const element1Rect = element1.getBoundingClientRect();
  const element2Rect = element2.getBoundingClientRect();

  return element1Rect.top - element2Rect.top;
}

/**
 * Returns the current viewport.
 *
 * @returns {string} - Can be 'mobile' or 'desktop'.
 *
 * @see MOBILE_BREAKPOINT
 */
function getViewport() {
  const width = window.innerWidth;
  if (width < MOBILE_BREAKPOINT) return 'mobile';
  return 'desktop';
}

function lpcBlogIsotopeJsInit() {
  HandleIsotope.init();
  HandleDiscoverMore.init();
}

function fixCategoryMenuItemTextSizeChange() {
  const categoryMenuItems = document.querySelectorAll('.lpc-blog-grid-filter-item');
  if (!categoryMenuItems) return;

  categoryMenuItems.forEach((categoryMenuItem) => {
    // get width
    const categoryMenuItemWidth = categoryMenuItem.offsetWidth;

    // set width
    categoryMenuItem.style.width = (categoryMenuItemWidth + 30) + 'px';
  });
}

function detailBannerWidth() {
  const highlightedTexts = document.querySelectorAll(".frame-type-rscwcontentelements_highlighted_text");

  highlightedTexts.forEach(el => {
    const content = el.querySelector(".highlighted-text-content-box");
    const imageWrapper = el.querySelector(".highlighted-text-contact-person");

    imageWrapper.style.left = `-${content.getBoundingClientRect().x}px`;
    imageWrapper.style.width = "100vw";
  });
}

window.addEventListener("resize", detailBannerWidth);

const HandleIsotope = {
  currentFilter: '*',

  showItemsStep: 6,

  showItems: 6,

  /**
   * If a grid item must not be visible, even when its category-filter matches, add this class to the grid item.
   * This will be used for the "Discover more"-functionality, which shows more items on click.
   */
  gridItemInactiveModifierClassName: 'lpc-blog-grid-item_inactive',
  gridItemInactiveModifierSelector: '.' + 'lpc-blog-grid-item_inactive',

  init: function () {
    HandleIsotope.hideFilterItems();

    const lpcBlogList = document.getElementById('lpc-blog-list');
    if (!lpcBlogList) return;

    const filter = document.getElementById('lpc-blog-grid-filter');
    if (!filter) return;

    const filterItems = filter.querySelectorAll('.lpc-blog-grid-filter-item');
    if (filterItems.length === 0) return;

    const grid = document.getElementById('lpc-blog-grid');
    if (!grid) return;

    const filterNoResults = grid.querySelector('.lpc-blog-list__items__no-results');
    if (filterNoResults.length === 0) return;

    const layoutDesktop = {
      itemSelector: '.lpc-blog-grid-item:not(' + HandleIsotope.gridItemInactiveModifierSelector + ')',
      stagger: 30,
      masonry: {
        gutter: 15,
      }
    };

    fixCategoryMenuItemTextSizeChange();

    $(grid).isotope(layoutDesktop);
    HandleIsotope.setFilter();

    filterItems.forEach((filterItem) => {
      filterItem.addEventListener('click', () => {
        HandleIsotope.showItems = HandleIsotope.showItemsStep;

        const activeFilterItem = document.querySelector('.lpc-blog-grid-filter-item.active');
        if (activeFilterItem) {
          activeFilterItem.classList.remove('active');
        }

        filterItem.classList.add('active');

        const filterValue = filterItem.getAttribute('data-lpc-blog-filter-category');
        const filterValueCategory = '.lpc-blog-grid-item.category-' + filterValue;

        if (filterValue === "all") {
          HandleIsotope.setFilter();
        } else {
          HandleIsotope.setFilter(filterValueCategory);
        }

        if (!document.querySelectorAll(filterValueCategory).length && filterValue !== "all") {
          filterNoResults.classList.remove("d-none");
        } else {
          filterNoResults.classList.add("d-none");
        }
      });
    });
  },

  /**
   * Sets the filter for the grid aswell as the number of active items to show.
   *
   * Note:
   *   - If this method is called, the "Discover more"-functionality will be reset.
   *
   * @param {string} filterValueCategory
   * @returns {void}
   */
  setFilter: function (filterValueCategory = "*") {
    const grid = HandleIsotope.getGrid();
    if (!grid) return;

    let filter = '.lpc-blog-grid-item:not(' + HandleIsotope.gridItemInactiveModifierSelector + ')';
    if (filterValueCategory !== "*") {
      filter += filterValueCategory;
    }

    HandleIsotope.currentFilter = filter;

    const removeInActiveModifier = (callback) => {
      const inActiveItems = HandleIsotope.getInactiveGridItems();
      if (inActiveItems.length > 0) {
        for (let i = 0; i < inActiveItems.length; i++) {
          inActiveItems[i].classList.remove(HandleIsotope.gridItemInactiveModifierClassName);
        }
      }
      callback();
    }

    removeInActiveModifier(() => {
      const items = grid.querySelectorAll(filter);

      items.forEach((item, index) => {
        if (index % 5 === 0 || index % 6 === 0) {
          item.classList.add('big');
        } else {
          item.classList.remove('big');
        }
      })

      if (items.length > HandleIsotope.showItems) {
        for (let i = HandleIsotope.showItems; i < items.length; i++) {
          items[i].classList.add(HandleIsotope.gridItemInactiveModifierClassName);
        }
      }

      $(grid).isotope({filter: filter});

      HandleDiscoverMore.updateVisbility();
    });
  },

  showMoreItems: function () {
    const grid = HandleIsotope.getGrid();
    if (!grid) return;

    const inactiveItems = HandleIsotope.getInactiveGridItems();
    if (inactiveItems.length > 0) {
      for (let i = 0; i < inactiveItems.length; i++) {
        inactiveItems[i].classList.remove(HandleIsotope.gridItemInactiveModifierClassName);
      }
    }

    HandleIsotope.showItems += HandleIsotope.showItemsStep;

    HandleIsotope.setFilter(HandleIsotope.currentFilter);

    HandleDiscoverMore.updateVisbility();
  },

  getGrid: function () {
    return document.getElementById('lpc-blog-grid');
  },

  getNumberOfInactiveGridItems: function () {
    const grid = HandleIsotope.getGrid();
    if (!grid) return;

    return grid.querySelectorAll(HandleIsotope.gridItemInactiveModifierSelector).length;
  },

  getInactiveGridItems: function () {
    const grid = HandleIsotope.getGrid();
    if (!grid) return;

    return grid.querySelectorAll(HandleIsotope.gridItemInactiveModifierSelector);
  },

  hideFilterItems: function () {
    const filterItems = document.querySelectorAll('.lpc-blog-grid-filter-item');
    if (!filterItems) return;

    filterItems.forEach((filterItem) => {
      const filterValue = filterItem.getAttribute('data-lpc-blog-filter-category');
      const filterValueCategory = '.lpc-blog-grid-item.category-' + filterValue;

      if (!document.querySelectorAll(filterValueCategory).length && filterValue !== "all") {
        filterItem.classList.add('d-none');
      }
    });
  },
}

const HandleDiscoverMore = {
  init: function () {
    HandleDiscoverMore.bindDiscoverMoreButton();
    HandleDiscoverMore.updateVisbility();
  },

  hide: function () {
    const discoverMoreButton = document.getElementById('lpc-blog-list-load-more');
    if (!discoverMoreButton) return;

    discoverMoreButton.classList.add('d-none');
  },

  show: function () {
    const discoverMoreButton = document.getElementById('lpc-blog-list-load-more');
    if (!discoverMoreButton) return;

    discoverMoreButton.classList.remove('d-none');
  },

  updateVisbility: function () {
    const numberOfInactiveGridItems = HandleIsotope.getNumberOfInactiveGridItems();

    if (numberOfInactiveGridItems > 0) {
      HandleDiscoverMore.show();
    } else {
      HandleDiscoverMore.hide();
    }
  },

  bindDiscoverMoreButton: function () {
    const discoverMoreButton = document.getElementById('lpc-blog-list-load-more');
    if (!discoverMoreButton) return;

    discoverMoreButton.addEventListener('click', (evt) => {
      evt.preventDefault();
      HandleIsotope.showMoreItems();
    });
  }
}

export {initBlogBreadcrumbs, initMagicGridBlog, magicGridsBlog, initBlogDetailShare, lpcBlogIsotopeJsInit};
