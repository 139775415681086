function bootstrapSelect() {
    if ($('select.custom-select-picker').length) {
        $('select.custom-select-picker').each(function () {
            var selectVal = $(this).val();
            $(this).selectpicker();
            $(this).selectpicker('val', selectVal);
            $(this).selectpicker('render');
            $(this).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
              $('[data-toggle="popover"]').popover('hide');
            });
        })
    }
}

export { bootstrapSelect };
