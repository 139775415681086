function imageTeaserTabFunction() {
  const imageTeaserContainer = document.querySelectorAll('.image-teaser-container');
  if (!imageTeaserContainer.length) return;

  imageTeaserContainer.forEach((container) => {
    const imageTeaser = container.querySelectorAll('.image-teaser');
    if (!imageTeaser.length) return;

    imageTeaser.forEach((teaser) => {
      const tapAnimator = teaser.querySelector('.image-teaser-tap-animator');
      if (!!tapAnimator) {
        initTapAnimator(teaser, tapAnimator);
      }
    })
  });
}

function initTapAnimator(teaser, tapAnimator) {
  teaser.classList.add('image-teaser_has-tap-animator');

  tapAnimator.addEventListener('click', (event) => {
    event.preventDefault();
  });
}

export {imageTeaserTabFunction};
