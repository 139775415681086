/*
  RSCW Responsive Media Images
  Author: Fabian Auer (auer@rscw.io)

  Version: 0.0.5
 */
var queryParam = new URLSearchParams(window.location.search);
var images,
    backgroundImages,
    //Attributes with the Images and Setttings
    imgAttr = 'data-rscw-img',
    backAttr = 'data-rscw-background',
    orientAttr = 'data-rscw-orientation',
    srcAttr = 'data-rscw-src-attr',
    threshold = queryParam.get('rscw-media-threshold')?queryParam.get('rscw-media-threshold'):200,
    throttled,
    throttleTimeout = 50;

document.addEventListener("DOMContentLoaded", function() {
    rscwUpdateImgs();
});

// Function to generate best Event based on browser compat
var addEvent = function(object, type, callback) {
    if (object == null || typeof(object) == 'undefined') return;
    if (object.addEventListener) {
        object.addEventListener(type, callback, false);
    } else if (object.attachEvent) {
        object.attachEvent("on" + type, callback);
    } else {
        object["on"+type] = callback;
    }
};

// Function get the required size and sets the image url.
function rscwUpdateImgs() {
    clearTimeout(throttled);
    // throttle the calls of this function on scroll handler
    throttled = setTimeout(function() {
        // Get all images to set size
        images = document.querySelectorAll('['+imgAttr+']:not(['+imgAttr+'=""])');
        // Get all elements with back images to set size
        backgroundImages = document.querySelectorAll('['+backAttr+']:not(['+backAttr+'=""])');

        for (var i = 0; i < images.length; i++) {
            var srcA = 'src';
            if (
                (images[i].getBoundingClientRect().top <= (window.innerHeight + threshold) && images[i].getBoundingClientRect().bottom >= 0) &&
                getComputedStyle(images[i]).display !== "none"
                && rscwImgUrl(images[i],imgAttr,images[i].getAttribute(orientAttr)) !== ''
            ) {
                if (images[i].getAttribute(srcAttr))
                    srcA = images[i].getAttribute(srcAttr);
                images[i].setAttribute(srcA,rscwImgUrl(images[i],imgAttr,images[i].getAttribute(orientAttr)));
                images[i].onload = function() {
                  this.parentNode.classList.add('--img-loaded');
                }
            }
        }
        for (i = 0; i < backgroundImages.length; i++) {
            if (
                (backgroundImages[i].getBoundingClientRect().top <= (window.innerHeight + threshold) && backgroundImages[i].getBoundingClientRect().bottom >= 0) &&
                getComputedStyle(backgroundImages[i]).display !== "none" &&
                rscwImgUrl(backgroundImages[i],backAttr,backgroundImages[i].getAttribute(orientAttr)) !== ''
            ) {
                backgroundImages[i].style.backgroundImage = "url('"+rscwImgUrl(backgroundImages[i],backAttr,backgroundImages[i].getAttribute(orientAttr))+"')";
            }
        }
    }, throttleTimeout);
}

// Url Helper to generate image url for glide.
function rscwImgUrl(image,attr,h){
    var imageContainer;
    if (attr === backAttr)
        imageContainer = image;
    else
        imageContainer = image.parentNode;
    if (imageContainer.offsetHeight === 0 && imageContainer.offsetWidth === 0)
        return '';
    if (typeof h !== 'undefined' && h === 'flip') {
        if (imageContainer.offsetHeight > 0)
            return addParameterToURL(image.getAttribute(attr),'h='+Math.ceil(imageContainer.offsetHeight / 100) * 100);
    }
    else
    if (imageContainer.offsetWidth > 0)
        return addParameterToURL(image.getAttribute(attr),'w='+Math.ceil(imageContainer.offsetWidth / 100) * 100);
    return '';
}
function addParameterToURL(url,param){
    if (url.indexOf('w=')<0 && url.indexOf('h=')) {
        url += (url.split('?')[1] ? '&':'?') + param;
    }
    return url;
}
addEvent(window, "resize", function() {
    rscwUpdateImgs();
});
addEvent(window, "scroll", function() {
    rscwUpdateImgs();
});
addEvent(window, "orientationchange", function() {
    rscwUpdateImgs();
});

// slider is calling this function globally, we need to provide it on the window object ... or use an event system in the future ...
window.rscwUpdateImgs = rscwUpdateImgs;
