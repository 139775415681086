const HEADER_MOBILE_CLASS = '.navigation-mobile .header-bar-mobile';
const HEADER_DESKTOP_CLASS = '.navigation-desktop';

function mainNavigation() {

    /*
    *
    *     Tim Apler:
    *     Den folgenden Block habe ich auskommentiert, da es diese Funktion bereits gibt in RHENUS.solrLastSearchesAutosuggestListener()
    *
    *
    */

    // const getAndShowLastSearches = (formInput) => {
    //     // Check, if there are Search terms in session storage
    //     if (window.localStorage.getItem('solr-searchterms')) {
    //         let lastSearchesHtml = "";
    //         let lastSearchesArray = window.localStorage.getItem('solr-searchterms').split(',');
    //         formInput.closest('form').append('<div class="last-searches-block" style="background:white;z-index:3000;"></div>');

    //         // if there are last searches, fill the html for autocomplete box
    //         if (Array.isArray(lastSearchesArray)) {
    //             lastSearchesArray = lastSearchesArray.reverse();
    //             lastSearchesArray.forEach(function(item){
    //                 if(item!=''){
    //                     lastSearchesHtml+= '<div class="autocomplete-suggestion last-search-suggestion">'+item+'</div>';
    //                 }
    //             });
    //         }

    //         // fill last searches in last-searches-block
    //         $('.last-searches-block').html(lastSearchesHtml);

    //         // if last searches options were set init the click event
    //         let lastSearchBox = $('.last-searches-block');
    //         if(lastSearchBox.find('.last-search-suggestion').length){
    //             lastSearchBox.find('.last-search-suggestion').each(function(){
    //                 let lastSearchSuggestOption = $(this);
    //                 // init click event
    //                 lastSearchSuggestOption.on('click',function(optionEvent){
    //                     optionEvent.preventDefault();
    //                     let form = formInput.closest('form');
    //                     let lastSearchValue = lastSearchSuggestOption.html();
    //                     let inputField = $(form).find('.tx-solr-suggest');
    //                     // fill input field and submit form
    //                     inputField.val(lastSearchValue);
    //                     form.submit();
    //                 });
    //             });
    //         }
    //     }

    // // Handle behaviour after submitting form
    // let form = formInput.closest('form');

    // const addSearchSpinner = (overlay, placeToBe, activeClass) => {
    //     form.submit((e) => {
    //         /* e.preventDefault(); */
    //         $('.last-searches-block').remove();
    //         if (overlay.hasClass(activeClass)) {
    //             placeToBe.css({'display':'flex','justify-content':'center','align-items':'center'});
    //             placeToBe.html('<div class="spinner-border solr-spinner" />');
    //             $('.solr-spinner').css('display', 'inline-block');
    //         }
    //     });
    // }

    // if (form[0].id == 'tx-solr-search-form-pi-results-nav') {
    //     addSearchSpinner($('.level-2-navi-overlay'), $('.level-2-search-col'), 'active');
    // }
    // if (form[0].id == 'tx-solr-search-form-pi-results-mobile') {
    //     addSearchSpinner($('#mobile-search-container'), $('.mobile-search-overlay'), 'open');
    // }

    // }

    /*
    *
    *     Tim Apler:
    *     Ich habe mein Handling um diese Funktion erweitert. Findet sich in RHENUS.solrOnFocusLastSearches()
    *
    *
    *
    */
  //  // Main Search: add last searches Div
  //   $('#solr-searchbar-input').on('focus', () => {
  //       getAndShowLastSearches($('#solr-searchbar-input'));
  //   });
  //   $('#solr-searchbar-input').on('focusout', () => {
  //       $('.last-searches-block').remove();
  //   });

    // // Mobile Search: add last searches Div, handle trigger and focus input field
    // $('#solr-searchbar-input-mobile').on('focus', () => {
    //     getAndShowLastSearches($('#solr-searchbar-input-mobile'));
    // });
    // $('.mobile-search-overlay').on('click', () => {
    //     $('.last-searches-block').remove();
    //     $('#mobile-search-container,.mobile-search-flyout-trigger').removeClass('open');
    // });
    if($('.mobile-search-flyout-trigger').length){
      $('.mobile-search-flyout-trigger').each(function(){
        let item = this;
        $(item).on('click', () => {
            if($(this).hasClass('open')){
              $('#mobile-search-container').removeClass('open');
              $(item).removeClass('open');
              $('.last-searches-block').remove();
            }else{
              //getAndShowLastSearches($('#solr-searchbar-input-mobile'));
              $('#mobile-search-container').addClass('open');
              $(item).addClass('open');
              $('#solr-searchbar-input-mobile').focus();
            }
        });
      })
    }

    // Level-2-navi-trigger
    $('.level-2-navi-trigger').on('click', function () {
        var $childOverlay = $(this).next('.level-2-navi-overlay');
        $('body').removeClass('navigation-open');
        $('body').removeClass('navigation-open-search');
        $('.navi-background').remove();

        if ($childOverlay.hasClass('active')) {
            $('.level-2-navi-overlay').removeClass('active');
        } else {
            $('.level-2-navi-overlay').removeClass('active');
            $childOverlay.addClass('active');
            $('body').addClass('navigation-open');
            if($childOverlay.hasClass('search')) {
              $('body').addClass('navigation-open-search');
              $('#solr-searchbar-nav-input').focus();
            }
            $('body').prepend('<div class="navi-background"></div>');
            closeNavigation();
        }
    /*
    *
    *     Tim Apler:
    *     Ich habe mein Handling um diese Funktion erweitert. Findet sich in RHENUS.solrOnFocusLastSearches()
    *
    *
    *
    */
        // // Set searchbar focus when active and show Last-Searches-Block, if focussed, but not typed anything yet
        // $('#solr-searchbar-nav-input').focus();
        // if ($('#solr-searchbar-nav-input').focus()) {
        //     getAndShowLastSearches($('#solr-searchbar-nav-input'));
        // }
        return false
    });

    $('.level-3-navi-trigger').on('click', function () {
        var $childOverlay = $(this).next('.level-3-navi-overlay');
        if ($childOverlay.hasClass('active')) {
            $('.level-3-navi-overlay').removeClass('active');
        } else {
            $('.level-3-navi-overlay').removeClass('active');
            $childOverlay.addClass('active');
        }
        return false
    });

  function closeNavigation() {
    const closeNavigationHandler = () => {
      const body = document.querySelector('body');
      const level2NaviOverlays = document.querySelectorAll('.level-2-navi-overlay');
      const naviBackground = document.querySelector('.navi-background');

      level2NaviOverlays.forEach((level2NaviOverlay) => {
        level2NaviOverlay.classList.remove('active');
      });

      body.classList.remove('navigation-open', 'navigation-open-search');

      if (!!naviBackground) {
        naviBackground.remove();
      }
    }

    document.querySelectorAll('.navi-flyout-close, .navi-background, .level-2-navi-overlay.search, .navigation-open-search .main-header-overlay').forEach((element) => {
      element.addEventListener('click', () => {
        closeNavigationHandler();
      });
    });

    document.onkeydown = (evt) => {
      if (evt.key === 'Escape') {
        closeNavigationHandler();
      }
    };
  }
}

function mobileNavigation() {
    $('.mobile-navi-trigger').on('click', function () {
        var $navigationPage = $(this).next('.mobile-navigation-page');

        $navigationPage.addClass('active');
        return false
    });

    $('.close-navigation-page').on('click', function () {
        $(this).parent('.mobile-navigation-page').removeClass('active');
    });

    $('.mobile-flyout-trigger').on('click', function () {
        $(this).toggleClass('active');
        $('.mobile-navi-flyout').toggleClass('active');
        $('body').removeClass('mobile-navigation-open');

        if ($(this).hasClass('active')) {
            $('body').addClass('mobile-navigation-open');
        }
        $('.mobile-navi-trigger').next('.mobile-navigation-page').removeClass('active');

        MenuActivePage.init();
    });
}

function headerScroll() {
  const fnHeader = document.querySelector('.fn-header');
  const leadCtaContainer = document.querySelector('.lead-cta-container');
  const countrySwitch = document.querySelector('.country_switch');

  // height subtracted from stage-height to trigger the small navi
  const SMALL_NAVI_SCROLL_TRIGGER_OFFSET = 155;

  // in case stage-height is smaller than the offset, use this fallback
  const STAGE_HEIGHT_FALLBACK = 400;

  const leadCtaAnchor = document.querySelectorAll('.lead-cta-anchor');
  let smallNaviTrigger = window.innerHeight * 0.9;

  if (leadCtaAnchor.length > 0) {
    let stage = leadCtaAnchor[0];

    let stageHeight = stage.offsetHeight;

    if (stageHeight < SMALL_NAVI_SCROLL_TRIGGER_OFFSET) {
      stageHeight = STAGE_HEIGHT_FALLBACK;
    }

    smallNaviTrigger = stageHeight - SMALL_NAVI_SCROLL_TRIGGER_OFFSET;
  }

  fixNaviBackgroundTop();

  window.addEventListener('scroll', () => {
    const scrollHeight = window.scrollY;

    if (scrollHeight > smallNaviTrigger) {
      if (!!fnHeader) {
        fnHeader.classList.add('active');
      }

      if (!!leadCtaContainer) {
        leadCtaContainer.classList.add('active');
      }

      if (!!countrySwitch) {
        countrySwitch.classList.add('small-navi');
      }
    } else {
      if (!!fnHeader) {
        fnHeader.classList.remove('active');
      }

      if (!!leadCtaContainer) {
        leadCtaContainer.classList.remove('active');
      }

      if (!!countrySwitch) {
        countrySwitch.classList.remove('small-navi');
      }
    }

    fixNaviBackgroundTop();
  });

  setTimeout(() => {
    if (!!leadCtaContainer) {
      leadCtaContainer.removeClass('inactive');
    }
  }, 500);
}

function smoothScrolling() {
    // Smooth scrolling if url contains hash
    var offset = 66;
    var duration = 1500;
    var hash = window.location.hash;

    const isSelectorValid = (selector) => {
      try {
        $(selector)
      } catch { //when selector invalid
        return false;
      } //when selector valid
      return true;
    }

    if(!isSelectorValid(hash)) {
      return;
    }

    if ($(hash).length > 0 && hash == '#tx-news-form') {
        offset = 250;
        duration = 0;

        $('html, body').animate({
            scrollTop: $(hash).offset().top - offset
        }, duration, function () {
        });
    }
    if ($(hash).length > 0 && hash != '#tx-news-form') {
        // special case: tabs
        let identifier = hash.substring(0, 4);
        if (identifier === '#tab') {
            hash = $('a[href="' + window.location.hash + '"]');
        }

        $('html, body').animate({
            scrollTop: $(hash).offset().top - offset
        }, duration, 'swing');
    }
}

function customNavigation() {
    // Level-2-navi-trigger
    $('.submenu-trigger').on('click', function (e) {
        e.preventDefault();
        var childLi = $(this).parent();
        $('.submenulink-box').each(function(){
            $(this).removeClass('active');
        });
        $('.submenu-trigger').each(function(){
            $(this).removeClass('active');
        });
        let linkId = $(childLi).data('id');
        $('#submenulink-box-'+linkId).addClass('active');
        $(this).addClass('active');
        return false
    });

    function closeNavigation() {
        $('.navi-flyout-close, .navi-background, .level-2-navi-overlay.search, .navigation-open-search .main-header-overlay').on('click', function () {
            $('body').removeClass('navigation-open');
            $('body').removeClass('navigation-open-search');
            $('.navi-background').remove();
        });
    }
}

const MenuActivePage = {
  ACTIVE_MENU_POINT_CLASSNAME: 'active-menu-point',

  activeMenuPointDataPageId: [],

  init() {
    const currentPageId = this.getCurrentPageIdFromBody();

    if (currentPageId === false) {
      return;
    }

    if (this.setActiveMenuPointDataPageId(currentPageId)) {
      this.setActiveMenuPointMobile();
      this.setActiveMenuPointDesktop();
    }

    this.setActiveNaviPageMobile();
    this.setThirdLevelPageVisibilityDesktop();
  },

  setActiveMenuPointDataPageId(currentPageId) {
    const currentMenuPoint = document.querySelector('.navigation-desktop a[data-page-id="' + currentPageId + '"]');

    if (this.getMenuPointLevel(currentMenuPoint) === 1) {
      this.activeMenuPointDataPageId.push(
        currentMenuPoint.getAttribute('data-page-id')
      );

      return true;
    }

    if (this.getMenuPointLevel(currentMenuPoint) === 2) {
      this.activeMenuPointDataPageId.push(
        currentMenuPoint.getAttribute('data-page-id')
      );

      this.activeMenuPointDataPageId.push(
        currentMenuPoint.closest('.level-2-navi-overlay').parentElement.querySelector('.level-1-navi-link').getAttribute('data-page-id')
      );

      return true;
    }

    if (this.getMenuPointLevel(currentMenuPoint) === 3) {
      this.activeMenuPointDataPageId.push(
        currentMenuPoint.getAttribute('data-page-id')
      );

      this.activeMenuPointDataPageId.push(
        currentMenuPoint.closest('.level-2-navi-overlay').parentElement.querySelector('.level-1-navi-link').getAttribute('data-page-id')
      );

      this.activeMenuPointDataPageId.push(
        currentMenuPoint.closest('.level-3-navi-overlay').parentElement.querySelector('.level-2-navi-link').getAttribute('data-page-id')
      );

      return true;
    }

    return false;
  },

  getMenuPointLevel(menuPoint) {
    if (!menuPoint) {return}

    if (menuPoint.classList.contains('level-3-navi-link')) {
      return 3;
    }

    if (menuPoint.classList.contains('level-2-navi-link')) {
      return 2;
    }

    return 1;
  },

  /**
   * Returns the current TYPO3 page id from the body tag
   *
   * @returns {string|boolean}
   */
  getCurrentPageIdFromBody() {
    const bodyId = document.querySelector('body').getAttribute('id');

    if (!bodyId) {
      return false;
    }

    return bodyId.substring(1); // removes the "p" from the id
  },

  setActiveMenuPointDesktop() {
    if (this.activeMenuPointDataPageId.length === 0) {
      return;
    }

    const navi = document.querySelector('.navigation-desktop');

    if (!navi) {
      return;
    }

    this.activeMenuPointDataPageId.forEach((pageId) => {
      const menuPoint = navi.querySelector('a[data-page-id="' + pageId + '"]');

      if (!menuPoint) {
        return;
      }

      menuPoint.classList.add(this.ACTIVE_MENU_POINT_CLASSNAME);
    });
  },

  setActiveMenuPointMobile() {
    if (this.activeMenuPointDataPageId.length === 0) {
      return;
    }

    const navi = document.querySelector('.navigation-mobile');

    if (!navi) {
      return;
    }

    this.activeMenuPointDataPageId.forEach((pageId) => {
      const menuPoint = navi.querySelector('a[data-page-id="' + pageId + '"]');

      if (!menuPoint) {
        return;
      }

      menuPoint.classList.add(this.ACTIVE_MENU_POINT_CLASSNAME);
    });
  },

  /**
   * If the current page is a "third level" page, then the respective column should be visible.
   */
  setThirdLevelPageVisibilityDesktop() {
    if (this.activeMenuPointDataPageId.length !== 3) {
      return;
    }

    const navi = document.querySelector('.navigation-desktop');

    if (!navi) {
      return;
    }

    const activeMenuPoint = navi.querySelector('a.level-3-navi-trigger.active-menu-point');

    if (!activeMenuPoint) {
      return;
    }

    activeMenuPoint.nextElementSibling.classList.add('active');
  },

  setActiveNaviPageMobile() {
    if (this.activeMenuPointDataPageId.length <= 1) {
      return;
    }

    const navi = document.querySelector('.navigation-mobile');

    if (!navi) {
      return;
    }

    const setMobileNavigationPageActive = (pageId) => {
      const naviPage = navi.querySelector('a[data-page-id="' + pageId + '"]');

      if (!naviPage) {
        return;
      }

      const activeMenuPointParent = naviPage.closest('.mobile-navigation-page');

      if (!activeMenuPointParent) {
        return;
      }

      activeMenuPointParent.classList.add('active');

      return true;
    };

    for (let i=0; i<this.activeMenuPointDataPageId.length; i++) {
      setMobileNavigationPageActive(this.activeMenuPointDataPageId[i]);
    }
  }
};

/**
 * Ensures that the dropdown menu and the dropdown toggle have the same width.
 *
 * This is necessary because the dropdown menu is positioned absolute.
 */
const mobileLanguageMenuWidthFix = () => {
    const mobileLanguageBar = document.querySelector('.mobile-language-bar');
    if (!mobileLanguageBar) return;

    const dropdownToggle = mobileLanguageBar.querySelector('.dropdown-toggle');
    if (!dropdownToggle) return;

    const dropdownMenu = mobileLanguageBar.querySelector('.dropdown-menu');
    if (!dropdownMenu) return;

    dropdownMenu.style.minWidth = dropdownToggle.offsetWidth + 'px';
};

function getHeaderHeight() {
  const mobileHeader = document.querySelector(HEADER_MOBILE_CLASS);
  const desktopHeader = document.querySelector(HEADER_DESKTOP_CLASS);

  if (!mobileHeader || !desktopHeader) return false;

  return {
    mobile: mobileHeader.offsetHeight,
    desktop: desktopHeader.offsetHeight
  };
}

function fixNaviBackgroundTop() {
  const body = document.querySelector('body');

  const naviDesktop = document.querySelector('.navigation-desktop');
  if (!naviDesktop) return;

  if (naviDesktop.classList.contains('active')) {
    body.classList.add('navigation-desktop-active');
  } else {
    body.classList.remove('navigation-desktop-active');
  }
}

export { mainNavigation, mobileNavigation, headerScroll, smoothScrolling, customNavigation, MenuActivePage, mobileLanguageMenuWidthFix, getHeaderHeight, fixNaviBackgroundTop };

