function initNewsFiltering() {
  // desktop filter
  if ($('.desktop-filter .news-category-filter .subfilter').length && $('#tx-news-form').length) {
    // Update filter and submit form, after filter was changed
    $('.desktop-filter .news-category-filter .subfilter').on('change', function () {
      updateNewsCategoryFilter();
      $('#tx-news-form').submit();
    });
  }

  // desktop year filter
  if ($('.desktop-filter input[name="desktop-year"]').length) {
    $('.desktop-filter input[name="desktop-year"]').on('change', function () {
      updateNewsCategoryFilter();
      $('#tx-news-form').submit();
    });
  }

  // mobile filter
  if ($('.mobile-filter .news-category-filter .subfilter').length && $('#tx-news-form').length) {
    // Update categories and submit if button is clicked
    $('.flyout-search-button').click(function () {
      updateNewsCategoryFilterMobile();
      $('#tx-news-form').submit();
    })
  }

  // mobile year filter
  if ($('.mobile-filter input[name="mobile-year"]').length) {
    $('.mobile-filter input[name="mobile-year"]').on('change', function () {
      updateNewsCategoryFilterMobile();
      $('#tx-news-form').submit();
    });
  }

  // Init back button
  if ($('.flyout-back').length) {
    $('.flyout-back').on('click', function (e) {
      e.preventDefault();
    });
  }

  // Init filter pills
  if ($('.news .filter-pill .remove').length) {
    var filterAmount = 0;
    // click on remove button of set filters
    $('.news .filter-pill .remove').on('click', function (e) {
      e.preventDefault();
      // get value
      var value = $(this).parent().data('value');
      // Uncheck checkbox of value
      $('.news .filter-flyout input[value=' + value + ']').prop('checked', false);
      // update categories & year and submit
      updateNewsCategoryFilterMobile();
      if ($(this).hasClass('year')) {
        $('#tx-news-filter-year').val('');
      }
      $('#tx-news-form').submit();
    })
    // show set filter headline
    $('.news .setted-filters').css('display', 'block');
    // count set filters
    $('.news .filter-pill').each(function () {
      filterAmount++;
    })
    // set amount to button
    if ($('.news .open-flyout .filter-amount').length) {
      $('.news .open-flyout .filter-amount').empty();
      if (filterAmount > 0) {
        $('.news .open-flyout .filter-amount').append(filterAmount);
      }
    }
  }


}

function updateNewsCategoryFilter() {
  if ($('#tx-news-form').length) {
    var categoryString = '';
    // build categorie sting
    $('.desktop-filter .news-category-filter .subfilter').each(function () {
      if (this.checked) {
        if (categoryString != '') {
          categoryString += ',';
        }
        categoryString += $(this).val();
      }
    });
    // add string to form
    if (categoryString != '') {
      $('#tx-news-filter-categories').remove();
      $('#tx-news-form').append('<input id="tx-news-filter-categories" type="hidden" value="' + categoryString + '" name="tx_news_pi1[overwriteDemand][categories]">')
    } else {
      $('#tx-news-filter-categories').remove();
    }
  }
  // add update year
  if ($('.desktop-filter input[name="desktop-year"]').length) {
    $('#tx-news-filter-year').remove();
    $('.desktop-filter input[name="desktop-year"]').each(function () {
      if ($(this).prop('checked') && $(this).val() != '' && $(this).val() != 'all') {
        $('#tx-news-form').append('<input id="tx-news-filter-year" type="hidden" value="' + $(this).val() + '" name="tx_news_pi1[overwriteDemand][year]">');
      }
    });
  } else {
    $('#tx-news-filter-year').remove();
  }
}

function updateNewsCategoryFilterMobile() {
  if ($('#tx-news-form').length) {
    var categoryString = '';
    // build categorie sting
    $('.mobile-filter .news-category-filter .subfilter').each(function () {
      if (this.checked) {
        if (categoryString != '') {
          categoryString += ',';
        }
        categoryString += $(this).val();
      }
    });
    // add string to form
    if (categoryString != '') {
      $('#tx-news-filter-categories').remove();
      $('#tx-news-form').append('<input id="tx-news-filter-categories" type="hidden" value="' + categoryString + '" name="tx_news_pi1[overwriteDemand][categories]">')
    } else {
      $('#tx-news-filter-categories').remove();
    }
  }
  // add update year
  if ($('.mobile-filter input[name="mobile-year"]').length) {
    $('#tx-news-filter-year').remove();
    $('.mobile-filter input[name="mobile-year"]').each(function () {
      if ($(this).prop('checked') && $(this).val() != '' && $(this).val() != 'all') {
        $('#tx-news-form').append('<input id="tx-news-filter-year" type="hidden" value="' + $(this).val() + '" name="tx_news_pi1[overwriteDemand][year]">');
      }
    });
  } else {
    $('#tx-news-filter-year').remove();
  }
}

function newsLoadingAnimation() {
  $(document).on('click', '.more-news-trigger', function () {
    $(this).parents('.more-news-trigger-container').addClass('clicked');
  });
}

function initNewsSliderNeu() {
  if (!document.getElementsByClassName('news-slider-container-neu')[0]) return;

  let allSlider = [];
  $('.news-slider-neu__slider').each(function (index, slider) {
    $(this).attr('data-news-slider-instance', index);

    let tnsSlider = tns({
      container: slider,
      items: 1,
      slideBy: 1,
      controls: true,
      mode: 'carousel',
      nav: false,
      mouseDrag: false,
      autoplay: false,
      loop: true,
      controlsText: ["chevron_left", "chevron_right"],
      gutter: 30,
      responsive: {
        992: {
          items: 3,
          slideBy: 3
        }
      }
    });

    allSlider.push(tnsSlider);
  });
}

function newsSlider() {
  const $newsSliderContainer = $('.news-slider-container');

  if (!!$newsSliderContainer) {
    /**
     * all news slider container get assigned an unique data-news-slider attribute
     */
    $newsSliderContainer.each(function (index) {
      $(this).attr('data-news-slider', index);
    });

    /**
     * initialize all .news-slider-left instances and store them in an array
     */
    let allSliderLeft = [];
    $('.news-slider-left').each(function (index, slider) {
      let tnSliderLeft = tns({
        container: slider,
        items: 1,
        edgePadding: -5,
        slideBy: 'page',
        controls: false,
        mode: 'carousel',
        nav: false,
        autoplay: false,
      });

      allSliderLeft.push(tnSliderLeft);
    });

    /**
     * Depending on the Viewport the .news-slider-right instances will either be a carousel (mobile) or a gallery (desktop).
     *
     * Note: "mode" is NOT a responsive options for tiny-slider
     */
    let galleryMode = 'gallery';
    if ($(window).width() <= 992) {
      galleryMode = 'carousel';
    }

    /**
     * initialize all .news-slider-right instances and store them in an array
     */
    let allSliderRight = [];
    $('.news-slider-right').each(function (index, slider) {
      let tnSliderRight = tns({
        container: slider,
        items: 1,
        slideBy: 'page',
        controls: false,
        mode: galleryMode,
        nav: false,
        autoplay: false,
      });

      allSliderRight.push(tnSliderRight);
    });

    /**
     * ensure the headline of the .news-slider-right instances are shortened from a specific length
     */
    $newsSliderContainer.each(function () {
      const rightContainer = $(this).find('.news-slider-right');

      $(rightContainer).find('.tns-item').each(function () {
        let unshortenedHeadline = $(this).find('.headline').html().trim();

        if (unshortenedHeadline.length > 100) {
          unshortenedHeadline = unshortenedHeadline.slice(0, 100) + "...";
          $(this).find('.headline').html(unshortenedHeadline);
        }
      });
    });

    /**
     * updates the visual progress bar of a slider
     */
    const updateProgressBar = (slider, $sliderContainer) => {
      const info = slider.getInfo();
      const slideCurrent = info.displayIndex;
      const slidesTotal = info.slideCount;
      const sliderProgress = (slideCurrent / slidesTotal) * 100;

      $sliderContainer.find('.news-slider-current').html(slideCurrent);
      $sliderContainer.find('.slider-progress-bar').css('width', sliderProgress + '%');
    };

    /**
     * Ensures that two sliders always display the same slide.
     *
     * It also prevents a deadlock-situation, where the change of first slider would cause the change of the second,
     * which in turn would change original first slider. For this purpose, a specific "locked" class is assigned to the
     * slider container-class, which wraps around both slider. As long as the container has this class, only the current
     * call of this method may influence both sliders.
     */
    const handleSliderEntanglement = (slider1, slider2, sliderIndex) => {
      const $sliderContainer = $('.news-slider-container[data-news-slider="' + sliderIndex + '"]');

      if ($sliderContainer.hasClass('locked') === false) {
        $sliderContainer.addClass('locked');

        updateProgressBar(slider1, $sliderContainer);

        const info = slider1.getInfo();
        const slideCurrent = info.displayIndex;
        slider2.goTo(slideCurrent - 1);
      } else {
        $sliderContainer.removeClass('locked');

        rscwUpdateImgs();
      }
    };

    /**
     * event listener for the left and right slider
     */
    for (let i = 0; i < allSliderLeft.length; i++) {
      allSliderLeft[i].events.on('transitionStart', () => {
        handleSliderEntanglement(allSliderLeft[i], allSliderRight[i], i);
      });

      allSliderRight[i].events.on('transitionStart', () => {
        handleSliderEntanglement(allSliderRight[i], allSliderLeft[i], i);
      });
    }

    /**
     * When a .news-slider-button is clicked, the corresponding slider will go to the next or previous slide.
     *
     * Note: The .news-slider-button DO NOT explicitly change the right slider. This is done automatically by the event
     * listener and the handleSliderEntanglement() method.
     */
    $('.news-slider-button').on('click', function () {
      const $sliderContainer = $(this).parents('.news-slider-container');

      const sliderNumber = $sliderContainer.attr('data-news-slider');

      if ($(this).hasClass('slider-next')) {
        allSliderLeft[sliderNumber].goTo('next');
      } else {
        allSliderLeft[sliderNumber].goTo('prev');
      }

      updateProgressBar(allSliderLeft[sliderNumber], $sliderContainer);

      rscwUpdateImgs();
    });
  }
}

const NewsDetailImage = {
  init() {
    this.newsDetailPage = document.querySelector('.news-single');

    if (!this.newsDetailPage) {
      return;
    }

    this.mediaImage = this.newsDetailPage.querySelectorAll('.media-image');

    if (!this.mediaImage) {
      return;
    }

    this.setAltAndTitleIfEmpty();
  },

  /**
   * Ensures that the alt and title attribute of the image are set. If they are not set, the alt and title attribute
   * will be set to the headline of the news.
   */
  setAltAndTitleIfEmpty() {
    const headline = this.newsDetailPage.getElementsByTagName('h1')[0].textContent;

    this.mediaImage.forEach((image) => {
      if (!image.alt) {
        image.alt = headline;
      }

      if (!image.title) {
        image.title = headline;
      }
    });
  },
};

export {initNewsFiltering, newsLoadingAnimation, initNewsSliderNeu, newsSlider, NewsDetailImage};
