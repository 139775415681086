function callMeBackCheckbox() {
    $('.callmeback-trigger .powermail_field').on('click', function () {
        var $innerCheckbox = $(this).find('input[type="checkbox"]');
        var $parentTrigger = $(this).parents('.callmeback-trigger');
        $(this).toggleClass('active');

        if ($innerCheckbox.prop('checked')) {
            $innerCheckbox.prop('checked', false).trigger('change');
            $parentTrigger.removeClass('active');
        } else {
            $innerCheckbox.prop('checked', true).trigger('change');
            $parentTrigger.addClass('active');
        }
        return false
    });
}

function specialStaticAusblenden() {
    $('#powermail_field_topic').on('change', function () {
        var selectedValue = $(this).val();
        var $callBackCheckbox = $('.callmeback-trigger input[type="checkbox"]');
        var $callBackInput = $('.callmeback-trigger');

        $callBackCheckbox.prop('checked', false).trigger('change');
        $callBackInput.removeClass('active');

        // automotive_solutions
        // warehousing
        if ((selectedValue == "automotive_solutions") || (selectedValue == "warehousing")) {
            $('.powermail_fieldwrap_callmeback_number').addClass('hide-callback');
            $('.powermail_fieldwrap_callmeback_checkbox').addClass('hide-callback');
        } else {
            $('.powermail_fieldwrap_callmeback_number').removeClass('hide-callback');
            $('.powermail_fieldwrap_callmeback_checkbox').removeClass('hide-callback');
        }
    });
}

function specialNextFormfieldEinAusklappen() {
    $('.open-next-box-trigger').each(function () {
        var $lastInput = $(this).find('input').last(),
            $parentElement = $(this),
            $nextFormField = $parentElement.next('.powermail_fieldwrap');

        $nextFormField.addClass('hidden');

        $lastInput.on('change', function () {
            if ($(this).is(':checked')) {
                $nextFormField.removeClass('hidden');
            } else {
                $nextFormField.addClass('hidden');
            }
        });
    });
}

export { callMeBackCheckbox, specialStaticAusblenden, specialNextFormfieldEinAusklappen };