let downloadLists = [];

function initDownloadsTable() {
    if ($('.downloads-table').length > 0) {
        $('.downloads-table').each(function () {
            var downloadlist = [];
            downloadlist['uid'] = $(this).data('uid');
            downloadlist['list'] = $(this).DataTable({
                "order": [[1, "asc"]],
                "columnDefs": [
                    {
                        "targets": [0],
                        "visible": false,
                        "searchable": false,
                        "width": "0%",
                    },
                    {
                        "targets": [1],
                        "visible": true,
                        "searchable": true,
                        "width": "100%",
                    },
                    {
                        "targets": [2, 3, 4],
                        "visible": false,
                        "searchable": true,
                        "width": "0%",
                    },
                ],
                "searching": true,
                "paging": false,
                "dom": "t<'bottom'lp>"
            });
            downloadLists.push(downloadlist);
            $('#c' + downloadlist['uid'] + ' .filters input').on('change', function () {
                var filterString = '';
                $('#c' + downloadlist['uid'] + ' .filters input').each(function () {
                    if ($(this).prop('checked')) {
                        if (filterString != '') {
                            filterString += '|';
                        }
                        filterString += 'category-' + $(this).val();
                    }
                })
                downloadlist.list
                    .column(2)
                    .search(filterString, true, false)
                    .draw();
                $('#c' + downloadlist['uid'] + ' .count').html(downloadlist['list'].page.info().recordsDisplay);
            });
        })
    }
}

export { initDownloadsTable };