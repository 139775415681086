/**
 * If the main navigation (desktop only) experiences a line break, then the text of the search icon should be hidden.
 *
 * If the height of the main navigation exceeds the height of the first navigation list point, then we can conclude
 * that a line break has happened. In this case, we add 'd-none' to the search icon-text class list.
 *
 * Note: This object does only handle the visibility of the search icon-text to prevent a line break within the main
 * navigation. Its job is not prevent the line break altogether!
 */
const SearchIconTextHandler = function () {
  const elNavigationDesktop = document.getElementsByClassName('navigation-desktop')[0];
  if (!elNavigationDesktop) return;

  const elMainNavigation = elNavigationDesktop.getElementsByClassName('main-navigation')[0];
  if (!elMainNavigation) return;

  const elFirstMainNavigationListPoint = elMainNavigation.getElementsByTagName('li')[0];
  if (!elFirstMainNavigationListPoint) return;

  const desktopMainNavigationHeight = elMainNavigation.clientHeight;
  const firstMainNavigationListPointHeight = elFirstMainNavigationListPoint.clientHeight;

  if (desktopMainNavigationHeight > firstMainNavigationListPointHeight) {
    const elSearchTitleSpan = document.getElementsByClassName('search-title-span')[0];

    if (!!elSearchTitleSpan) {
      elSearchTitleSpan.classList.add('d-none');
    }
  }
};

export {SearchIconTextHandler};
