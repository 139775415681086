const EditorialStage = {
  init() {
    if (!document.querySelector('.editorial__stage')) return;

    this.changeBreadcrumbColumnClass();
  },

  /**
   * Shrinks the breadcrumb column on the xl-viewport to make room for the editorial stage-image in order to prevent
   * the breadcrumb overflowing the image. This way the after-space class can be small as the breadcrumb and editorial
   * stage-image can be displayed side by side horizontally.
   */
  changeBreadcrumbColumnClass() {
    const breadcrumbContainer = document.querySelector('.breadcrumb-container');
    if (!breadcrumbContainer) return;

    const breadcrumbColumn = breadcrumbContainer.querySelector('.col-12');
    if (!breadcrumbColumn) return;

    breadcrumbColumn.classList.remove('col-12');
    breadcrumbColumn.classList.add('col-sm-12', 'col-xl-7');
  },
}

export {EditorialStage};
