/**
 * Handles the exchange and transition from brand- to subbrand-logo (and vice versa) based on the current scroll
 * position.
 *
 * To initialize the handler, call subbrandLogoHandler.init().  If there is a subbrand-logo present on the site,
 * the subbrand-logo will be "projected" as a new element to the DOM.  Also, event listener will be defined,
 * which are getting triggered by scrolling or resizing the browser window. Depending on the relative scrolling
 * position, the brand- and/or subbrand-logo will be visible. If they are in a between-state, which means both
 * are currently visible, a transition animation will be applied, depending on the exchange-state.
 */
const subbrandLogoHandler = {
    // If set to true, the method "debug()" will be called on every "update()".
    debugFlag: false,

    // The current exchange- or "in between"-state.
    state: null,

    // jQuery object of the brand logo.
    brandLogo: null,

    // jQuery object of the subbrand logo.
    subbrandLogo: null,

    // jQuery object of the generated and appended projected logo.
    projectedLogo: null,

    // jQuery object of the style element of the projected logo SVG.
    // This is needed, in order to change the css-style of the projected logo, because the projected logo, when
    // its "becoming" the subbrand logo (metaphorical, not literally!) its color has to be changed.
    svgStyleProjectedLogo: null,

    // Object containing properties, that are used for calculations regarding the responsiveness of the page.
    responsive: {
        mobileBreakpoint: 1200,
        logoPosTop: null,
        brandLogo: {
            mobile: null,
            desktop: null,
        },
        subbrandLogo: {
            mobile: null,
            desktop: null,
        },
        // The vertical distance down (in pixel) from the initial position of the projected logo.
        projectedLogo: {
            verticalShift: {
                mobile: 32,
                desktop: 48
            }
        },
    },

    // Indicates at what point on the vertical axis (in pixel) the brand logo <emph>starts</emph> colliding with
    // the subbrand logo (and vice versa).
    transition: {
        point: null,
        brandLogo: {
            top: null,
            bottom: null,
            state: 1,
        },

        subbrandLogo: {
            top: null,
            bottom: null,
            state: 0
        }
    },

    // Object containing the class and class-body that will be used to change the color of the projected logo.
    svgFillColorClassOverwrite: {
        white: '.st0{fill:#FFFFFF;}',
        blue: '.st0{fill:#014898;}'
    },

    /**
     * Initialize the handling of the subbrand logo (if there's one).
     *
     * If a subbrand logo is present on a page, jQuery objects for the brand- and subbrand-logo will be
     * declared. Listener to "scroll"- and "resize"-events are set to call an update()-method for setting
     * various properties or (re-) calculating them. Also, the update()-method will be called once in order to
     * setup the properties correctly.
     */
    init: function () {
        if (document.getElementsByClassName('subbrand-logo').length !== 0) {
            let that = this;

            that.responsive.brandLogo.mobile = $('.brand-logo_mobile');
            that.responsive.brandLogo.desktop = $('.brand-logo_desktop');
            that.responsive.subbrandLogo.mobile = $('.subbrand-logo_mobile');
            that.responsive.subbrandLogo.desktop = $('.subbrand-logo_desktop');

            that.brandLogo = that.responsive.brandLogo.desktop;
            that.subbrandLogo = that.responsive.subbrandLogo.desktop;

            // we need to wait until the "projected logo" has been insert into the dom
            if (that.createTransitionPointLogoProjection()) {
                that.projectedLogo = $('.projected-logo');
                that.transition.point = $('#projected-logo-container');

                // The projected logo has to be colored in rhenus blue as it is based
                // on the subbrand logo, which has to be white.
                $('#svg-projected-logo').on('load', function () {
                    $($(this)[0].contentDocument).find('path').attr('fill', '#014898');
                    $($(this)[0].contentDocument).find('polygon').attr('fill', '#014898');
                    that.svgStyleProjectedLogo = $($(this)[0].contentDocument).find('style');
                    $(that.svgStyleProjectedLogo).html(that.svgFillColorClassOverwrite.blue);

                    that.update();
                    $(window).on('scroll', function () {
                        that.update();
                    });

                    $(window).on('resize', function () {
                        that.update();
                    });
                });
            }
        }
    },

    /**
     * When ever values change, that could effect the state of a logo, this method must be called.
     */
    update: function () {
        let that = this;

        if (that.responsiveAdjustments()) {
            that.calcTransitionProperties();
            that.transitionAnimation();

            that.debug(that.debugFlag);
        }
    },

    /**
     * (Re-) Calculates properties regarding the responsiveness of the page.
     */
    responsiveAdjustments: function () {
        let that = this;

        if (that.isMobile()) { // mobile
            that.responsive.logoPosTop = -1;
            that.responsive.logoHeight = 18;
            that.brandLogo = that.responsive.brandLogo.mobile;
            that.subbrandLogo = that.responsive.subbrandLogo.mobile;
            $('#projected-logo-container').css('margin-top', that.responsive.projectedLogo.verticalShift.mobile);
        } else { // not mobile
            that.responsive.logoPosTop = 16;
            that.responsive.logoHeight = 31;
            that.brandLogo = that.responsive.brandLogo.desktop;
            that.subbrandLogo = that.responsive.subbrandLogo.desktop;
            $('#projected-logo-container').css('margin-top', that.responsive.projectedLogo.verticalShift.desktop);
        }

        return true;
    },

    /**
     * Creates a logo projection-element and appends it at the transition point.
     */
    createTransitionPointLogoProjection: function () {
        let that = this;

        let subbrandLogoImgSrc = that.subbrandLogo.find('img').attr('src');
        let subbrandLogoImgAlt = that.subbrandLogo.find('img').attr('alt');
        if (subbrandLogoImgAlt.length === 0) {
            subbrandLogoImgAlt = $('h1.headline').text().trim();
        }

        let logoProjection = `
            <div class="container" id="projected-logo-container">
                <div class="row">
                    <div class="col-sm-12 col-md-3 text-right order-md-last mb-3 mb-md-0">
                        <span class="projected-logo mt-1">
                            <object id="svg-projected-logo" data="${subbrandLogoImgSrc}" type="image/svg+xml"></object>
                        </span>
                    </div>

                    <div class="col-sm-12 col-md-9 text-left order-md-first">
                        <h2 class="h1 subbrand-label">${subbrandLogoImgAlt}</h2>
                    </div>
                </div>
            </div>
        `;

        if (that.isMobile() && $('.lead-cta-anchor').length > 0) {
            $('.lead-cta-anchor').first().find('figure').after(logoProjection);
        } else {
            if ($('.breadcrumb-container').length > 0) {
                $('.breadcrumb-container').first().after(logoProjection);
            } else {
                $('.lead-cta-container').first().after(logoProjection);
            }

            $('.lead-cta-container').css('min-height', '60px');
        }

        return true;
    },

    /**
     * Performs the transition and animation from one logo to the other.
     *
     * Please note: Properties regarding the current progress of the transition or the current state,
     * should be calculated in "subbrand.calcTransitionProperties()" and taken from the
     * "subbrand.transition"-object.
     *
     * The progress/state of a logo is represented by the following properties:
     *  - transition.brandLogo.state
     *  - transition.subbrandLogo.state
     * If one of these has a value of 0, the logo is not active.
     * If one of these has a value of 1, the logo is active.
     * If the value of a property is between 0..1, the logo is in <emph>transition</emph>.
     * The higher a value gets, the more "active" it becomes.
     */
    transitionAnimation: function () {
        let that = this;

        if (that.transition.brandLogo.state === 1) { // assumption: subbrandLogo.state === 0
            // brand-logo
            that.brandLogo.css('top', that.responsive.logoPosTop);
            this.setLogoVisibility(that.brandLogo, 1);

            // subbrand-logo
            this.setLogoVisibility(that.subbrandLogo, 0);

            // projected-logo
            this.setLogoVisibility(that.projectedLogo, 1);
        } else if (that.transition.subbrandLogo.state === 1) { // assumption: brandLogo.state === 0
            // brand-logo
            this.setLogoVisibility(that.brandLogo, 0);

            // subbrand-logo
            that.subbrandLogo.css('top', that.responsive.logoPosTop);
            this.setLogoVisibility(that.subbrandLogo, 1);

            // projected-logo
            this.setLogoVisibility(that.projectedLogo, 0);
        } else { // both states are in transition
            // brand-logo
            that.brandLogo.css('top', that.responsive.logoPosTop - ((that.responsive.logoPosTop + that.responsive.logoHeight) * that.transition.subbrandLogo.state));
            this.setLogoVisibility(that.brandLogo, that.transition.brandLogo.state);

            // subbrand-logo
            that.subbrandLogo.css('top', that.responsive.logoPosTop + (that.responsive.logoHeight * that.transition.brandLogo.state));
            this.setLogoVisibility(that.subbrandLogo, that.transition.subbrandLogo.state);

            // projected-logo
            this.setLogoVisibility(that.projectedLogo, 0);

            // projected-logo
            //that.projectedLogo
            //    .css('opacity', that.transition.brandLogo.state)
            //    .css('top', that.responsive.logoPosTop + (that.responsive.logoHeight * that.transition.brandLogo.state));
        }
    },

  /**
   * Sets the visibility of a logo
   *
   * This method also handles the "display" property of the logo. This is necessary, because otherwise both logos,
   * even though one logo would not be visible, both would still be on top of each other, hindering linking.
   */
    setLogoVisibility: function (logo, opacity = 0) {
      logo.css('opacity', opacity);

      if (opacity === 0) {
        logo.css('display', 'none');
      } else {
        logo.css('display', 'block');
      }
    },

    getTransitionPointDistanceTop: function () {
        let that = this;

        if (that.isMobile())
            return that.transition.point.offset().top - 17;
        else
            return that.transition.point.offset().top;
    },

    /**
     * Calculates the current transition properties
     *
     * The transition between one logo to another is equivalent to overlap of both logo.
     *  - brand logo fully visible: if the "bottom" is below of the subbrand logo's "top".
     *    ("below" in pixel mind you)
     *  - subbrand logo fully visible: if the "bottom" is below of the brand logo's "top".
     *    ("below" in pixel mind you)
     *  - both logo visible but in a transitional state: if none of the conditions above are met.
     */
    calcTransitionProperties: function () {
        let that = this;

        // distance from brand logo top to page top etc.
        that.transition.brandLogo.top = $(window).scrollTop() + that.brandLogo.position().top;
        that.transition.brandLogo.bottom = that.transition.brandLogo.top + that.responsive.logoHeight;

        that.transition.subbrandLogo.top = that.getTransitionPointDistanceTop();
        that.transition.subbrandLogo.bottom = that.transition.subbrandLogo.top + that.responsive.logoHeight;

        // should the brand logo be shown?
        if (that.transition.brandLogo.bottom < that.transition.subbrandLogo.top) {
            that.transition.brandLogo.state = 1;
            that.transition.subbrandLogo.state = 0;
        }
        // should the subbrand logo be shown?
        else if (that.transition.subbrandLogo.bottom < that.transition.brandLogo.top) {
            that.transition.brandLogo.state = 0;
            that.transition.subbrandLogo.state = 1;
        }
        // transitional phase
        else {
            let diff = (that.transition.subbrandLogo.bottom - that.transition.brandLogo.top) / 2;

            that.transition.brandLogo.state = diff / that.responsive.logoHeight;
            that.transition.subbrandLogo.state = 1 - that.transition.brandLogo.state;
        }
    },

    isMobile: function () {
        let that = this;

        return $(window).width() < that.responsive.mobileBreakpoint;
    },

    debug: function (debugFlag = false) {
        if (debugFlag !== true) return;

        let that = this;

        console.log(
            `
            --- DEBUG ---
            window:
                $(window).scrollTop(): ${$(window).scrollTop()}

            transition:
                transition.point.offset().top: ${that.transition.point.offset().top}

                transition.brandLogo.state:    ${that.transition.brandLogo.state}
                transition.subbrandLogo.state: ${that.transition.subbrandLogo.state}

                transition.brandLogo.top:    ${that.transition.brandLogo.top}
                transition.brandLogo.bottom: ${that.transition.brandLogo.bottom}
                transition.subbrandLogo.top:    ${that.transition.subbrandLogo.top}
                transition.subbrandLogo.bottom: ${that.transition.subbrandLogo.bottom}

            logo:
                brandLogo.height():    ${that.responsive.logoHeight}
                subbrandLogo.height(): ${that.responsive.logoHeight}
            --- /DEBUG ---
            `
        );
    },
}

export { subbrandLogoHandler };
