import QRCode from 'qrcode';

const initQrCodeGenerator = () => {
  if($('#generate-qrcode').length) {
    $('#generate-qrcode').click((e) => {
      e.preventDefault();

      var opts = {
        type: 'svg',
      }

      QRCode.toString(window.location.href, opts, function(err, string) {
        if (err) throw err
        downloadSvg(string);
        console.log(string);
        const qrcodediv = "<div id='svgcontainer' style='display:none'>"+string+"</div>";
        $(qrcodediv).appendTo('body');
      });
    });
  }
};

const downloadSvg = (text) => {
  const filename = "RhenusKarriereFilter.svg";
  console.log("testachricht");
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export {initQrCodeGenerator}
