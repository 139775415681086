function languageSwitch() {
    if ($('.language-switcher').length) {
        if ($(document).width() > 767.98) {
            $('.language-switcher .active').click(function (e) {
                e.preventDefault();
                $('.language-switcher').toggleClass('opened');
            });
        }
    }
}

export { languageSwitch };
