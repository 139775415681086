function countrySwitch() {
    if ($('#rhenus-worldwide-popup').length) {
        var countryPage = false;
        var slug = $(location).attr('pathname');
        slug.indexOf(1);
        slug.toLowerCase();
        slug = slug.split("/")[1];
        let hostUrl = window.location.host;
        if(slug!==''){
            $('#rhenus-worldwide-popup .frame-type-textmedia a').each(function () {
                let href = $(this).attr('href');
                if(href!==undefined){
                    href.indexOf(1);
                    href.toLowerCase();
                    if(href.includes(hostUrl)){
                        href = href.split("/")[3];
                    } else {
                        href = href.split("/")[1];
                    }
                    if(href==slug){
                        switch (href) {
                            case "ao":
                                countryPage = false;
                                break;
                            case "landingpages":
                                countryPage = false;
                                break;
                            case "cn":
                                countryPage = true;
                                $(this).css('font-weight', 'bold');
                                $('.countryswitch-country').html("Greater China");
                                $('.country-title').html("Rhenus Greater China");
                                break;
                            case "za":
                                countryPage = true;
                                $(this).css('font-weight', 'bold');
                                $('.countryswitch-country').html("South Africa");
                                $('.country-title').html("Rhenus South Africa");
                                break;
                            default:
                                countryPage = true;
                                $(this).css('font-weight', 'bold');
                                var country = $(this).html();
                                country = country.charAt(0).toUpperCase() + country.slice(1);
                                if ($('.countryswitch-country').length) {
                                    $('.countryswitch-country').html(country);
                                }
                                $('.country-title').html("Rhenus " + country);
                                break;
                        }
                    }
                }
            });
        }
        if (countryPage == false) {
            $('.country-switch-stripe-right').remove();
        }
    }
}

function initRhenusWorldWideTextLinks() {
    if ($('.open-rhenus-world-wide').length) {
        $('.open-rhenus-world-wide').click(function (e) {
            e.preventDefault();
            $.magnificPopup.open({
                items: {
                    src: '#rhenus-worldwide-popup',
                    type: 'inline'
                },
            });
        });
    }
}

export { countrySwitch, initRhenusWorldWideTextLinks };