import {getCurrentViewport} from '../utils/viewport.utils';

const MOBILE_BREAKPOINT = 992;

/**
 * Initializes ALL extended stripes on the current page, if any.
 */
function initExtendedStripe() {
  const extendedStripes = document.querySelectorAll('.extended-stripe');
  if (extendedStripes.length === 0) return;

  setupScrollMagic(extendedStripes);
}

/**
 * Sets up the ScrollMagic-Controller and -Scenes for the given stripes. Destroys and reinitializes the controller, if
 * the viewport changes from mobile to desktop or vice versa, as we're using different scenes/animations for both.
 *
 * Note: The GSAP- and ScrollMagic-library (as well as the ScrollMagic-Plugin animation.gsap) are required for this.
 * As of now (2023-10-20), they're included in the fluid-Partial "Page/ScrollMagicGsap".
 *
 * @see sources/html/typo3conf/ext/rscw_datenmuehle/Resources/Private/Partials/Page/ScrollMagicGsap.html
 *
 * @param {NodeListOf<HTMLElement>} stripes
 */
function setupScrollMagic(stripes) {
  stripes.forEach((stripe) => {
    let controller = new ScrollMagic.Controller();
    let viewport = getCurrentViewport(MOBILE_BREAKPOINT);

    if (viewport === 'mobile') {
      setupAnimationMobile(stripe).addTo(controller);
    } else {
      setupAnimationDesktop(stripe).addTo(controller);
    }

    window.addEventListener('resize', () => {
      if (viewport !== getCurrentViewport(MOBILE_BREAKPOINT)) {
        viewport = getCurrentViewport(MOBILE_BREAKPOINT);

        controller.destroy(true);
        controller = new ScrollMagic.Controller();

        if (viewport === 'mobile') {
          setupAnimationMobile(stripe).addTo(controller);
        } else {
          setupAnimationDesktop(stripe).addTo(controller);
        }
      }
    });
  });
}

/**
 * Sets up a GSAP-Timeline for the given stripe. Meant for mobile devices.
 * Returns a ScrollMagic.Scene with the timeline attached.
 *
 * @param {HTMLElement} stripe
 * @returns {ScrollMagic.Scene}
 */
function setupAnimationMobile(stripe) {
  const {image, headline, text, cta} = getExtendedStripeElements(stripe);

  const tl = gsap.timeline();
  if (image) {
    tl.fromTo(image, {
      opacity: 1,
      x: '0',
      y: '0',
      scale: '1',
    }, {
      x: '0',
      y: '200px',
      scale: '1.1',
      opacity: 0,
      ease: 'power1.inOut',
      duration: 2
    }, 0);
  }

  if (headline) {
    tl.fromTo(headline, {
      opacity: 0,
      y: '-30px',
    }, {
      opacity: 1,
      y: '0',
      ease: 'power1.inOut',
      duration: 1
    }, 0.5)
  }

  if (text) {
    tl.fromTo(text, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'power1.inOut',
      duration: 1,
    }, 1);
  }

  if (cta) {
    tl.fromTo(cta, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'power1.inOut',
      duration: 1,
    }, 1.5);
  }

  const scene = new ScrollMagic.Scene({
    triggerElement: stripe,
    duration: 300,
    offset: -100,
  });
  scene.setTween(tl);

  return scene;
}

/**
 * Sets up a GSAP-Timeline for the given stripe. Meant for desktop.
 * Returns a ScrollMagic.Scene with the timeline attached.
 *
 * @param {HTMLElement} stripe
 * @returns {ScrollMagic.Scene}
 */
function setupAnimationDesktop(stripe) {
  const {image, headline, text, cta} = getExtendedStripeElements(stripe);

  const tl = gsap.timeline();

  if (image) {
    tl.fromTo(image, {
      x: '-200px',
      opacity: 0,
    }, {
      x: '0',
      opacity: 1,
      ease: 'power1.inOut',
      duration: 2
    }, 0);
  }

  if (headline) {
    tl.fromTo(headline, {
      opacity: 0,
      y: '-30px',
    }, {
      opacity: 1,
      y: '0',
      ease: 'power1.inOut',
      duration: 1
    }, 0.5);
  }

  if (text) {
    tl.fromTo(text, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'power1.inOut',
      duration: 1,
    }, 1);
  }

  if (cta) {
    tl.fromTo(cta, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'power1.inOut',
      duration: 1,
    }, 1.5);
  }

  const scene = new ScrollMagic.Scene({
    triggerElement: stripe,
    duration: 300,
    offset: -100,
  });
  scene.setTween(tl);

  return scene;
}

/**
 * Returns an object with the elements of the given stripe.
 *
 * @param {HTMLElement} stripe
 * @returns {{image, cta, text, headline}}
 */
function getExtendedStripeElements(stripe) {
  const image = stripe.querySelector('.extended-stripe-image');
  const content = stripe.querySelector('.extended-stripe-content');
  const headline = content.querySelector('.headline');
  const text = content.querySelectorAll('p');
  const cta = content.querySelector('a');

  return {
    image,
    headline,
    text,
    cta,
  };
}

export {initExtendedStripe};
