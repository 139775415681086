function initFileUploadFields() {
  if($('.powermail_fieldwrap_type_file').length){
    $('.powermail_fieldwrap_type_file').each(function(index,fileUploadWrapper){
      let fileUploadField = $(fileUploadWrapper).find('input[type=file]');
      let selectButton = $(fileUploadWrapper).find('.button-select');
      fileUploadField.on('change',function(e){
        updateFileList(fileUploadWrapper,fileUploadField);
      })
      if($(fileUploadWrapper).find('#edit-upload-form').length){

        selectButton.on('click',function(e){
          e.preventDefault();
          $.magnificPopup.open({
              items: {
                  src: '#edit-upload-form',
                  type: 'inline'
              },
          });
          $('#edit-upload-form .button-submit').on('click',function(e){
            e.preventDefault();
            $.magnificPopup.close();
            fileUploadField.trigger('click');
          });
        })
      }else{
        selectButton.on('click',function(e){
          e.preventDefault();
          fileUploadField.trigger('click');
        })
      }
      initRemoveButtons(fileUploadWrapper,fileUploadField);
      // buildFilesFromHiddenFields(fileUploadWrapper,fileUploadField);
    });
  }
}

function updateFileList(fileUploadWrapper,fileUploadField){
  let currentFileList = $(fileUploadWrapper).find('.current-file-list');
  console.log(fileUploadField);
  if(fileUploadField[0].files != undefined){
    console.log(fileUploadField[0].files);

    let children = "";
    for (let i = 0; i < fileUploadField[0].files.length; ++i) {
      let sizeMB = fileUploadField[0].files.item(i).size/1000000;
      sizeMB = Math.round(sizeMB * 100) / 100;
      children +=  '<li><span class="file-name">'+ fileUploadField[0].files.item(i).name + '</span><span class="file-size">'+sizeMB+' MB</span>' + '<span class="remove-list"><i class="material-icons">close</i></span>' + '</li>'
    }
    currentFileList.html(children);
    initRemoveButtons(fileUploadWrapper,fileUploadField);
  }

}

function initRemoveButtons(fileUploadWrapper,fileUploadField){
  $(fileUploadWrapper).find('.remove-list').each(function(){
    let button = $(this);
    button.on('click',function(e){
      let index = $(fileUploadWrapper).find('li').index(button.parent());
      removeFromFileInputField(fileUploadField,index);
      updateFileList(fileUploadWrapper,fileUploadField);
    })
  })
}

function removeFromFileInputField(fileUploadField,index){
  const dt = new DataTransfer()
  const input = fileUploadField[0];
  const { files } = input

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    if (index !== i)
      dt.items.add(file) // here you exclude the file. thus removing it.
  }

  input.files = dt.files // Assign the updates list
}

function buildFilesFromHiddenFields(fileUploadWrapper,fileUploadField){
  if(fileUploadField[0].files.length==0){
    if($(fileUploadWrapper).find('input[type=hidden]').length){
      const dt = new DataTransfer();
      $(fileUploadWrapper).find('input[type=hidden]').each(function(index,inputField){
        let fileName = $(inputField).val();
        let fileExt = fileName.split('.').pop();
        let file = new File(["foo"], fileName, {
          type: "text/"+fileExt,
        });
        dt.items.add(file) // here you exclude the file. thus removing it.
        $(inputField).remove();
      });
      fileUploadField[0].files = dt.files;
      updateFileList(fileUploadWrapper,fileUploadField);
    }
  }
}

export { initFileUploadFields };
