/**
 * IMPORTANT:
 *  The AnimationHandler does only (and should only) handle the WOW-animations that can be configured in the generic
 *  backend-tab "Animation". It DOES NOT handle other animations (even other WOW-animations), e. g. "Staunezahl"!
 */
import WOW from 'wow.js/dist/wow.min';

const AnimationHandler = {
  wowConfig: {
    boxClass: 'wow',
    animateClass: 'animate__animated',
    mobile: false,
  },

  init() {
    new WOW({
      ...this.wowConfig,
    }).init();
  }
};

export {AnimationHandler};
