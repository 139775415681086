import {accordionElement, initAcoordionLinks, openUrlAccordion} from "./components/accordion";
import {initBlogBreadcrumbs, initMagicGridBlog, initBlogDetailShare, lpcBlogIsotopeJsInit} from "./components/blog";
import {bootstrapSelect} from "./components/bootstrapSelect";
import {BrowserDetect} from "./components/browserDetect";
import {initCareerNumbers} from "./components/career";
import {defaultTeaserSlider} from "./components/defaultTeaserSlider";
import {initDownloadsTable} from "./components/downloadsTable";
//import { gallery } from "./components/gallery";
//import { imageSlider } from "./components/imageSlider";
import {imageTeaserTabFunction} from "./components/imageTeaser";
import {initHotspots} from "./components/interactiveMap";
import {languageSwitch} from "./components/languageMenu";
import {leadCTA} from "./components/leadCTA";
import {initAnimatedLightboxPopupButton, initLightboxPopupButton} from "./components/lightbox";
import {changeLayoutOfLpcNews} from "./components/lpc";
import {materialComponents} from "./components/materialComponents";
import {parseMauticForms} from "./components/mauticForms";
import {
  headerScroll,
  mainNavigation,
  mobileNavigation,
  smoothScrolling,
  customNavigation,
  MenuActivePage,
  mobileLanguageMenuWidthFix
} from "./components/navigation";
import {
  initNewsFiltering,
  newsLoadingAnimation,
  initNewsSliderNeu,
  newsSlider,
  NewsDetailImage
} from "./components/news";
import {callMeBackCheckbox, specialNextFormfieldEinAusklappen, specialStaticAusblenden} from "./components/powermail";
import {referenceSlider} from "./components/referenceSlider";
import {
  solrResultHandler,
  solrLastSearchesAutosuggestListener,
  initSolr,
  solrSearchbarHandler,
  initMobileNewsFilter,
  initMobileNewsFilterButton,
  mobileRemoveFilter,
  mobileFilterCount,
  solrMobileFilterButtonListener,
  iniSaveSolrSearchTermInSession,
  initSolrResultsPerPage,
  solrOnFocusLastSearches,
  solrInitSpinner,
  solrDataLayerHandler,
  solrFilterAccordionHandler,
  initFilterPopupButton,
  MobileFilterHandler
} from "./components/solr";
//import { initStageSlider, stageBreadcrumbs } from "./components/stage";
import {initStandardTeaserMobileSlider} from "./components/standardTeaserSlider";
import {staunezahl} from "./components/staunezahlen";
import {subbrandLogoHandler} from "./components/subbrandLogoHandler";
import {svgConverter} from "./components/svgConverter";
//import { tabbedContentbox } from "./lazy/tabbedContentBox";
import {initTextMediaTableHighlight} from "./components/table";
import {initTabLinks} from "./components/tabLinks";
import {tabularContent} from "./components/tabularContent";
import {initMultistepForm} from "./components/multistepform";
import {countrySwitch, initRhenusWorldWideTextLinks} from "./components/worldwideMenu";
import {restrictB2BForms, disableButtonAfterSubmit} from "./components/forms";
import {handleBuisnessPrivateCases} from "./components/new_forms";
import {initFileUploadFields} from "./components/fileUpload";
import {formGtm} from "./components/formGtm";
import {UseDefaultLangBanner} from "./general/UseDefaultLangBanner";
import {SidebarCtaContainer} from "./components/SidebarCtaContainer";
import {Back2TopButton} from "./components/Back2TopButton";
import {SearchIconTextHandler} from "./general/SearchIconTextHandler";
import {EditorialStage} from "./components/EditorialStage";
import {AnimationHandler} from "./general/AnimationHandler";
import {initQrCodeGenerator} from "./components/qrcode";
import {initUtmSession} from "./components/utmSession";
import {HyphenopolyHandler} from "./general/HyphenopolyHandler";
import {waterLevels} from "./components/waterLevels";
import {countrySwitchModule} from "./components/countrySwitch";
import {roadFreightImprovement} from "./components/roadFreightImprovement";
import {initExtendedStripe} from "./components/extendedStripe";
//import { initQuote } from "./components/quote";
//import { initTextTeaser } from "./components/textTeaser";
//import { initExtendedImageTeaserInfobox } from "./components/extendedImageTeaserInfobox";
import {UsercentricsHandler} from "./general/usercentrics";
import {LocalVideoHandler} from "./general/LocalVideoHandler";

window.getCookie = function (name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
};

$(function () {
  /* Browser detect */
  BrowserDetect.init();

  /* UsercentricsHandler */
  UsercentricsHandler.init();

  /* LocalVideoHandler */
  LocalVideoHandler.init();

  /* not components binded functions */
  svgConverter();
  materialComponents();
  bootstrapSelect();

  /* Navigation functions */
  mainNavigation();
  mobileNavigation();
  headerScroll();
  smoothScrolling();
  customNavigation();
  MenuActivePage.init();
  mobileLanguageMenuWidthFix();

  /* Stage functions */
  /*  initStageSlider();
   stageBreadcrumbs(); */

  /* Accordion functions */
  accordionElement();
  openUrlAccordion();
  initAcoordionLinks();

  /* B2B Form Button */
  restrictB2BForms();

  /* New Forms intro question */
  handleBuisnessPrivateCases();

  /* Gallery function */
  //gallery();

  /* Send GTM Event for send form */
  formGtm();

  /* Tabbed content box function */
  //tabbedContentbox();

  /* Slider functions */
  //imageSlider();
  initStandardTeaserMobileSlider();
  initNewsSliderNeu();
  newsSlider();
  roadFreightImprovement();
  NewsDetailImage.init();

  referenceSlider();
  defaultTeaserSlider();

  /* Image Teaser functions */
  imageTeaserTabFunction();

  /* Tabular content function */
  tabularContent();

  /* Tab links function */
  initTabLinks();

  /* lead CTA function */
  leadCTA();

  /* Powermail functions */
  callMeBackCheckbox();
  specialStaticAusblenden();
  specialNextFormfieldEinAusklappen();

  /*  Interactive map function */
  initHotspots();

  /* Downloads table */
  initDownloadsTable();

  /* Solr functions */
  initFilterPopupButton();
  solrResultHandler();
  solrLastSearchesAutosuggestListener();
  initSolr();
  solrSearchbarHandler();
  initMobileNewsFilter();
  initMobileNewsFilterButton();
  mobileRemoveFilter();
  mobileFilterCount();
  solrMobileFilterButtonListener();
  iniSaveSolrSearchTermInSession();
  initSolrResultsPerPage();
  solrOnFocusLastSearches();
  solrInitSpinner();
  solrDataLayerHandler();
  solrFilterAccordionHandler();
  MobileFilterHandler.init(
    document.getElementsByClassName('solr-news-filter')[0],
    document.getElementById('tx-solr-search'),
    48, // mobile header bar height
    'solr-news-filter_fixed-mobile'
  );

  /* News function */
  initNewsFiltering();
  newsLoadingAnimation();

  /* Mautic forms */
  parseMauticForms();

  /* Table function */
  initTextMediaTableHighlight();

  /* Subbrand logo handler */
  subbrandLogoHandler.init();

  /* Lightbox functions */
  initAnimatedLightboxPopupButton();
  initLightboxPopupButton();

  /* Rhenus wordlwide menu functions */
  countrySwitch();
  initRhenusWorldWideTextLinks();

  /* Blog functions */
  initBlogBreadcrumbs();
  initMagicGridBlog();
  initBlogDetailShare();
  lpcBlogIsotopeJsInit();

  /* Career functions */
  initCareerNumbers();

  /* Language menu function */
  languageSwitch();

  /* lpc functions */
  changeLayoutOfLpcNews();

  /* Staunezahlen function */
  staunezahl.init();

  /* UseDefaultLangBanner function */
  UseDefaultLangBanner();

  /* SidebarCtaContainer function */
  SidebarCtaContainer();

  /* SidebarCtaContainer function */
  Back2TopButton();

  /* SearchIconTextHandler function */
  SearchIconTextHandler();
  /* Init Multistep form with parsley */
  initMultistepForm();

  /* Init fileUploadFields */
  initFileUploadFields();

  /* Init QrCodeGenerator */
  initQrCodeGenerator();

  /* Init EditorialStage */
  EditorialStage.init();

  /* Init AnimationHandler */
  AnimationHandler.init();

  initUtmSession();

  /* Init waterLevels */
  waterLevels.init();

  /* Init HyphenopolyHandler */
  HyphenopolyHandler.init();

  /* Init Country Switch function */
  countrySwitchModule();

  /* Init extendedStripe */
  initExtendedStripe();

  /* Init Quote */
  //initQuote();

  /* Init TextTeaser */
  //initTextTeaser();

  /* Init extendedImageTeaserInfobox */
  //initExtendedImageTeaserInfobox();
});
