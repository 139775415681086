function restrictB2BForms() {
    if($('.tx-powermail.only-show-b2b').length){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.get('form_page')){
        $('.tx-powermail.only-show-b2b').each(function(){
          let powermailContainer = $(this);
          let formContainer = powermailContainer.find('.b2b-form-container');
          formContainer.css('opacity','1');
          $(this).closest('.b2b-button-container').remove();
        })
      }else{
        $('.tx-powermail.only-show-b2b').each(function(){
          let powermailContainer = $(this);
          let text = powermailContainer.attr('data-text');
          let buttonText = powermailContainer.attr('data-button-text');
          let buttonBackText = powermailContainer.attr('data-button-back-text');
          let formContainer = powermailContainer.find('.b2b-form-container');
          let form = formContainer.find('form');
          let formUid = form.attr('id').replace('form-','');
          formUid = formUid.replace(formUid.substr(-2),'');
          let button = '<div class="b2b-button-container"><div class="text-center"><p>'+text+'</p><button class="button-secondary b2b-button">'+buttonText+'</button></div></div>';
          let nav;
          if($('.multistep-form-navigation[data-powermail-form='+formUid+']').length){
            nav = $('.multistep-form-navigation[data-powermail-form='+formUid+']');
            nav.find('.next').addClass('b2b-hide');
            nav.find('.back').addClass('b2b-hide');
            button = '<div class="b2b-button-container"><div class="text-center"><p>'+text+'</p><button class="button-ghost b2b-button-back mr-2">'+buttonBackText+'</button><button class="button-secondary b2b-button">'+buttonText+'</button></div></div>';
          }
          powermailContainer.prepend(button);
          powermailContainer.find('.b2b-button').on('click',function(){
            formContainer.css('opacity','1');
            $(this).closest('.b2b-button-container').remove();
            if(nav){
              nav.find('.next').removeClass('b2b-hide');
              nav.find('.back').removeClass('b2b-hide');
            }
          });
          if(nav){
            powermailContainer.find('.b2b-button-back').on('click',function(){
                nav.find('.next').removeClass('b2b-hide');
                nav.find('.back').removeClass('b2b-hide');
                nav.find('.back').trigger('click');
            });
          }
        })
      }
    }
}

export { restrictB2BForms };
