function initAnimatedLightboxPopupButton() {
    $('.lightbox-inline-animation').magnificPopup({
        type:'inline',
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        fixedBgPos: true,
        fixedContentPos: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });
    $('#mfp-close').on('click',function(){
        $.magnificPopup.close();
    });
}
function initLightboxPopupButton() {
    $('.lightbox-inline').magnificPopup({
        type:'inline',
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        fixedBgPos: true,
        fixedContentPos: true
    });
    $('#mfp-close').on('click',function(){
        $.magnificPopup.close();
    });
    $('.picturecredit-inline').magnificPopup({
        type:'inline',
        midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        fixedBgPos: true,
        fixedContentPos: true,
        callbacks: {
            open: function() {
                window.dispatchEvent(new Event('resize'));
            }
        }
    });
}

export { initAnimatedLightboxPopupButton, initLightboxPopupButton };