let topicNavigation;
let progressBar = {};
const spinnerHTML = '<div class="container spinner-container">'+
                      '<div class="spinner-border text-primary" role="status">'+
                        '<span class="sr-only">Loading...</span>'+
                      '</div>'+
                    '</div>';

function initMultistepForm() {
  let startPage = 0;
  if($('.tx-powermail form.powermail_morestep').length){
    $('.tx-powermail form.powermail_morestep').each(function(){
      let form = $(this);
      let formUid = form.attr('id').replace('form-','');
      formUid = formUid.replace(formUid.substr(-2),'');
      let formNavigation = $('.multistep-form-navigation[data-powermail-form="'+formUid+'"]');
      if(form.find('.powermail_fieldset').length){
        let formSections = form.find('.powermail_fieldset');

        // Previous button is easy, just go back
        formNavigation.find('.back').click(function() {
          deactivateForm(formUid);

          // Unset topic on last(current) page of topic navigation
          if($('.tx-rscwleadhandling-fom-navigation[data-form-uid="'+formUid+'"] .navigation-and-form .form-section.current').length){
            $('.tx-rscwleadhandling-fom-navigation[data-form-uid="'+formUid+'"] .navigation-and-form .form-section.current input[type=radio]').prop('checked',false);
          }

          buildProgressbarObject();
        });

        // Previous button is easy, just go back
        formNavigation.find('.previous').click(function() {
          navigateTo(curIndex(formSections) - 1, formSections,formNavigation);
          buildProgressbarObject();
        });

        // Next button goes forward iff current block validates
        formNavigation.find('.next').click(function() {
          form.parsley().whenValidate({
            group: 'block-' + formUid + '-' + curIndex(formSections)
          }).done(function() {
            navigateTo(curIndex(formSections) + 1, formSections,formNavigation);
            buildProgressbarObject();
          });
        });

        formNavigation.find('[type="submit"]').click(function() {
          form.parsley().whenValidate({
            group: 'block-' + formUid + '-' + curIndex(formSections)
          }).done(function() {
            startSpinner();
            //let formUid = $(this).closest('.multistep-form-navigation').attr('data-powermail-form');
            let relativeForm = $('.powermail_form_'+formUid);
            if(relativeForm.length){
              relativeForm.submit();
            }
          });
        });

        // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
        formSections.each(function(index, section) {
          $(section).find(':input').attr('data-parsley-group', 'block-' + formUid + '-' + index);
        });

        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('form_page')){
          let formPage = urlParams.get('form_page');
          if(formPage!=-1){
            startPage = indexByPageUid(formSections,formPage);
          }else{
            startPage = formPage;
          }
        }
        if(startPage!=-1){
          navigateTo(startPage,formSections,formNavigation); // Start at the beginning
        }else{
          navigateTo(0,formSections,formNavigation); // Start at the beginning
        }
      }
    })
  }
  initTopicNavigation(startPage);
  initAccordionElements();
  addModalToEditButton();
}


function navigateTo(index, sections, formNavigation) {
  index = getNextAviablePage(index, sections);
  if(index!=-1){
    // Mark the current section with the class 'current'
    sections
      .removeClass('current')
      .eq(index)
        .addClass('current');
    // Show only the navigation buttons that make sense for the current section:
    if(index==1 && sections.eq(0).hasClass('hide')){
      formNavigation.find('.previous').toggle(false);
      formNavigation.find('.back').toggle(true);
    }else{
      formNavigation.find('.previous').toggle(index > 0);
      formNavigation.find('.back').toggle(index == 0);
    }
    var atTheEnd = index >= sections.length - 1;
    if(!atTheEnd && getNextAviablePage(index+1, sections)==-1){
      atTheEnd = true;
    }
    formNavigation.find('.next').toggle(!atTheEnd);
    formNavigation.find('.end').toggle(false);
    formNavigation.find('[type=submit]').toggle(atTheEnd);
    rscwUpdateImgs();
  }
}

function curIndex(sections) {
  // Return the current index by looking at which section has the class 'current'
  return sections.index(sections.filter('.current'));
}

function indexByPageUid(sections,pageUid) {
  // Return the current index by looking at which section has the class 'current'
  return sections.index(sections.filter('[data-powermail-page-uid="'+pageUid+'"]'));
}

function getNextAviablePage(index, sections){
  if(index!=-1 && (sections.eq(index).hasClass('powermail-cond-hide') || sections.eq(index).hasClass('hide'))){
    let direction = '';

    if(curIndex(sections)<index){
      direction = 'forward';
    }else{
      direction = 'back';
    }

    if(direction=='forward'){
      if(sections.length - 1 == index){
        index == -1;
      }else if(sections.length - 1 >= index + 1){
        index++;
      }
      else{
        index = sections.length - 1;
      }
    }else{
      if(index - 1 >= 0){
        index--;
      }else{
        index = 0;
      }
    }
    index = getNextAviablePage(index,sections);
  }
  return index;
}

function initTopicNavigation(initialPage = 0){
  if($('.tx-rscwleadhandling-fom-navigation').length){
    topicNavigation = $('.tx-rscwleadhandling-fom-navigation');
    let formNavigation = topicNavigation.find('.multistep-form-navigation');
    let formSections = topicNavigation.find('.form-navigation-body .form-section');
    let formUid = topicNavigation.attr('data-form-uid');
    if(formSections.length){
        // Previous button is easy, just go back
        formNavigation.find('.previous').click(function() {
          navigateTo(curIndex(formSections) - 1, formSections,formNavigation);
          formSections.eq(curIndex(formSections)).find('input[type=radio]').prop('checked',false);

          buildProgressbarObject();
        });

        // Next button goes forward iff current block validates
        // formNavigation.find('.next').click(function() {
        //   navigateForwardToTopic(curIndex(formSections) + 1, formSections,formNavigation);
        //   buildProgressbarObject();
        // });

        // formNavigation.find('.end').click(function() {
        //   endTopicNavigation(formSections,formUid);
        //   buildProgressbarObject();
        // });

        // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
        formSections.each(function(index, section) {
          $(section).find(':input').on('change', function(e){
            updateNavigationPages(formSections);

            let curValue = getCurNavigationValue(formSections);
            if(curValue && curValue!=''){
              if(isNextPage(curValue,formSections)){
                navigateForwardToTopic(curIndex(formSections) + 1, formSections,formNavigation);
                formSections.eq(curIndex(formSections)).find('input[type=radio]').attr('checked',false);
                buildProgressbarObject();
              }else{
                endTopicNavigation(formSections,formUid);
                buildProgressbarObject();
              }
            }
            updateNavigationButtons(formSections, formNavigation);
          });
        });

        updateNavigationPages(formSections);
        let startPage = 0;
        if(initialPage != -1){
          formSections.each(function(index, section){
            if($(section).find('input:checked, input.checked').length){
              startPage = index;
            }
          });
        }else{
          formSections.eq(startPage).find('input[type=radio]').attr('checked',false);
        }
        navigateTo(startPage,formSections,formNavigation); // Start at the beginning
        updateNavigationButtons(formSections, formNavigation,true);

        let curValue = getCurNavigationValue(formSections);
        if(curValue && initialPage!=-1){
          if(curIndex(formSections) > 0 && formSections.eq(curIndex(formSections)).find('input[value="'+curValue+'"]').attr('data-link')==undefined){
            activateForm(formUid);
          }else if(curIndex(formSections) == 0){
            deactivateForm(formUid);
            formSections.eq(curIndex(formSections)).find('input[value="'+curValue+'"]').trigger('change');
          }else{
            deactivateForm(formUid);
          }
        }else{
          deactivateForm(formUid);
        }
    }
    buildProgressbarObject();
  }else{
    if($('#tx-rscwleadhandling-progressbar').length && $('#tx-rscwleadhandling-progressbar').attr('data-progressbar') != undefined){
      progressBar = JSON.parse($('#tx-rscwleadhandling-progressbar').attr('data-progressbar'));
      drawProgressbar(false,true);
    }
  }
  removeSpinner();
}

function updateNavigationPages(sections){
  sections.each(function(index, section){
    let currValue = 0;
    if(index>0){
      currValue = $(section).find('.sub-topic-container:not(.hide) input:checked').val();
    }else{
      currValue = $(section).find('input:checked').val();
    }
    if(currValue && currValue != '' && sections.length -1 >= index + 1){
      let nextSection = sections.eq(index + 1);
      if(nextSection.find('.sub-topic-container[data-parent-topic="'+currValue+'"]').length){
        nextSection.find('.sub-topic-container').addClass('hide');
        nextSection.find('.sub-topic-container[data-parent-topic="'+currValue+'"]').removeClass('hide');
      }
      if(index==0){
        if(currValue=='transport'){
          $('.second-level-header-transport').toggle(true);
          $('.second-level-header-default').toggle(false);
        }else{
          $('.second-level-header-transport').toggle(false);
          $('.second-level-header-default').toggle(true);
        }
      }
    }
  });
}

function navigateForwardToTopic(index, sections, formNavigation){
  let currValue = getCurNavigationValue(sections);
  if(isNextPage(currValue,sections)){
    // Mark the current section with the class 'current'
    sections
      .removeClass('current')
      .eq(index)
        .addClass('current');
    updateNavigationButtons(sections, formNavigation);
  }
}

function getCurNavigationValue(sections){
  let currSection = sections.eq(curIndex(sections));
  let level = currSection.attr('data-navigation-level');
  let selectedInput;
  if(level>1){
    selectedInput = currSection.find('.sub-topic-container:not(.hide) input:checked');
  }else{
    selectedInput = currSection.find('input:checked');
  }
  let currValue = 0;
  if(selectedInput.length==1){
    currValue = selectedInput.val();
  }
  return currValue;
}

function updateNavigationButtons(sections, formNavigation){
  formNavigation.find('.previous').toggle(curIndex(sections) > 0);
  let atTheEnd = curIndex(sections) >= sections.length - 1;
  // formNavigation.find('.next').toggle(!atTheEnd);
  // formNavigation.find('.end').toggle(false);
  // formNavigation.find('[type=submit]').toggle(atTheEnd);

  let curValue = getCurNavigationValue(sections);
  if(curValue && curValue!=''){
    if(isNextPage(curValue,sections)){
      // formNavigation.find('.end').toggle(false);
      // formNavigation.find('.next').toggle(true);
      formNavigation.find('.next').trigger('click');
    }else{
      // formNavigation.find('.next').toggle(false);
      // formNavigation.find('.end').toggle(true);
      formNavigation.find('.end').trigger('click');
    }
  }else{
    formNavigation.find('.end').toggle(false);
    formNavigation.find('.next').toggle(false);
  }
  if(formNavigation.find('button:not([style*="display: none"])').length>0){
    formNavigation.removeClass('hide');
  }else{
    formNavigation.addClass('hide');
  }
}

function isNextPage(curValue,sections){
  let index = curIndex(sections);
  if(sections.length -1 >= index +1 && sections.eq(index+1).find('.sub-topic-container[data-parent-topic="'+curValue+'"]').length){
    return true;
  }
  return false;
}

function deactivateForm(formUid){
  if($('form.powermail_form_'+formUid).length){
    $('form.powermail_form_'+formUid).addClass('hide');
    if($('form.powermail_form_'+formUid).closest('.only-show-b2b').length){
      $('form.powermail_form_'+formUid).closest('.only-show-b2b').addClass('hide');
    }
  }
  if($('.multistep-form-navigation[data-powermail-form="'+formUid+'"]').length){
    $('.multistep-form-navigation[data-powermail-form="'+formUid+'"]').addClass('hide');
  }
  if($('.tx-rscwleadhandling-fom-navigation[data-form-uid="'+formUid+'"] .navigation-and-form').length){
    $('.tx-rscwleadhandling-fom-navigation[data-form-uid="'+formUid+'"] .navigation-and-form').removeClass('hide')
  }
}

function activateForm(formUid){
  // if form exists
  if($('form.powermail_form_'+formUid).length){
    $('form.powermail_form_'+formUid).removeClass('hide');
    // if form is only-show-b2b
    if($('form.powermail_form_'+formUid).closest('.only-show-b2b').length){
      $('form.powermail_form_'+formUid).closest('.only-show-b2b').removeClass('hide');
      // if only-show-b2b overly is still active
      if($('form.powermail_form_'+formUid).closest('.only-show-b2b').find('.b2b-button-container').length){
        // if nav exists
        if($('.multistep-form-navigation[data-powermail-form="'+formUid+'"]').length){
          // add b2b-hide-buttons
          $('.multistep-form-navigation[data-powermail-form="'+formUid+'"] .next').addClass('b2b-hide');
          $('.multistep-form-navigation[data-powermail-form="'+formUid+'"] .back').addClass('b2b-hide');
        }
      }
    }
  }
  if($('.multistep-form-navigation[data-powermail-form="'+formUid+'"]').length){
    $('.multistep-form-navigation[data-powermail-form="'+formUid+'"]').removeClass('hide');
  }
  if($('.tx-rscwleadhandling-fom-navigation[data-form-uid="'+formUid+'"] .navigation-and-form').length){
    $('.tx-rscwleadhandling-fom-navigation[data-form-uid="'+formUid+'"] .navigation-and-form').addClass('hide')
  }
}

function endTopicNavigation(sections,formUid){
  let currIndex = curIndex(sections);
  let currSection = sections.eq(currIndex);
  let currTopic = currSection.find('input:checked');
  if(currTopic.attr('data-link')){
    startSpinner();
    window.location.href = currTopic.attr('data-link');
  }else{
    activateForm(formUid);
  }
}

function buildProgressbarObject(){
  // if form navigation exists
  if($('.tx-rscwleadhandling-fom-navigation').length){
    // empty progressbar arrays
    progressBar['topics'] = {};
    progressBar['multistep'] = {};
    // get navigation
    let topicNavigation = $('.tx-rscwleadhandling-fom-navigation');
    // get navigation pages
    let topicSection = topicNavigation.find('.form-section');
    // get uid of multistep form
    let formUid = topicNavigation.attr('data-form-uid');
    // check if topicNavigation or multistepform is active
    let isTopicNavigationActive = !topicNavigation.find('.navigation-and-form').hasClass('hide');

    let lastValue;
    let stopProgrssbar = false;
    let isFirstPage = true;

    // foreach page of the topicnavigation
    topicSection.each(function(topicIndex, section){
      // if the topicnavigation is active, stop it at the current page
      if(isTopicNavigationActive && $(section).hasClass('current')){
        stopProgrssbar = true;
      }
      // if progessbar is unstopped
      if(!stopProgrssbar){
        isFirstPage = false;
        // check if there is a selected value from the last page
        if(lastValue && lastValue!=''){
          // if there is a value, search only in the realted sub-topic-container
          if($(section).find('.sub-topic-container[data-parent-topic="'+lastValue+'"] input:checked').length){
            // if there is some input checked, save it to the progrssbar array
            let value = $($(section).find('.sub-topic-container[data-parent-topic="'+lastValue+'"] input:checked')[0]).val();
            let title = $($(section).find('.sub-topic-container[data-parent-topic="'+lastValue+'"] input:checked')[0]).attr('data-title');
            let item = {};
            item['value'] = value;
            item['title'] = title;
            item['icon'] = $($(section).find('.sub-topic-container[data-parent-topic="'+lastValue+'"] input:checked')[0]).parent().find('.icon_dark').attr('src');
            progressBar['topics'][topicIndex] = item;
            // save the current value in the lastValue variable for the next page
            lastValue = value;
          }
        }else{
          // if there is no lastValue, we are on the first page
          if($(section).find('input:checked').length){
            // if there is some input checked, save it to the progrssbar array
            let value = $($(section).find('input:checked')[0]).val();
            let title = $($(section).find('input:checked')[0]).attr('data-title');
            let item = {};
            item['value'] = value;
            item['title'] = title;
            item['icon'] = $($(section).find('input:checked')[0]).parent().find('.icon_dark').attr('src');
            progressBar['topics'][topicIndex] = item;
            // save the current value in the lastValue variable for the next page
            lastValue = value;
          }
        }
      }
    });
    // if we have a formUid and the form exists and the topicnavigation is inactive (which also means, the multiform is active)
    if(formUid != undefined && $('.powermail_form_'+formUid).length && !isTopicNavigationActive){
      // get the multiform
      let form = $('.powermail_form_'+formUid);
      // get the section of the multiform
      let formSections = form.find('.powermail_fieldset');
      let currentPage;
      stopProgrssbar = false;
      // foreach all page of the multiform
      formSections.each(function(stepIndex, section){
        // if there is a currentPage, it means the last processed page was the current in view so we should top the progressbar here
        if(currentPage){
          stopProgrssbar = true;
        }
        // if the progressbar is unstopped and the current page is not hidden by powermailCond save it to the processbar array
        if(!stopProgrssbar && !$(section).hasClass('powermail-cond-hide') && !$(section).hasClass('hide')){
          let item = {};
          item['value'] = $(section).attr('data-page-title');
          // if it's the last page save this information
          if(stepIndex == $(formSections).length -1){
            item['end'] = true;
          }
          progressBar['multistep'][stepIndex] = item;
          // save this page as currentPage if it is the current page in view
          if($(section).hasClass('current')){
            currentPage = $(section);
          }
        }
      });
      if(form.find('input[name="progressbar"]').length>0){
        form.find('input[name="progressbar"]').val(JSON.stringify(progressBar));
      }else{
        form.append("<input type='hidden' name='progressbar' value='"+JSON.stringify(progressBar)+"'/>");
      }
    }
    drawProgressbar(isFirstPage);
  }
}

function drawProgressbar(isFirstPage = false, isConfirmationPage = false){
  let mobileLabel = '';
  if($('#tx-rscwleadhandling-progressbar .progressbar-content').length){
    let colWidthPercent = 16.66667;
    let countAllItems = Object.entries(progressBar['multistep']).length + Object.entries(progressBar['topics']).length;
    if(countAllItems >= 6 || (isConfirmationPage && countAllItems < 6)){
      colWidthPercent = 100 / (countAllItems+1);
    }

    let progressbarLabel = $('#tx-rscwleadhandling-progressbar').attr('data-label');
    // get the content node of progressbar
    let progressBarNode = $('#tx-rscwleadhandling-progressbar .progressbar-content');
    // add col-width styles and empty progressbar
    progressBarNode.html('<style type="text/css">.col-processbar{flex:0 0 '+colWidthPercent+'%;max-width:'+colWidthPercent+'%;}</style>');
    // if it is not the first page, add a label
    if(!isFirstPage){
      progressBarNode.parent().find('.navigation-form-label').addClass('small-label');
      progressBarNode.html('<div class="col-12"><h4>'+progressbarLabel+'</h4></div>');
    }else{
      progressBarNode.parent().find('.navigation-form-label').removeClass('small-label');
    }
    // foreach in progressbar array saved topic generate a html item and add it to html progressbar
    Object.entries(progressBar['topics']).forEach(function(topic){
      let imageHTML = '';
      if(topic[1]['icon'] && topic[1]['icon'] != ''){
        imageHTML = '<img src="'+topic[1]['icon']+'" />';
      }
      let html =  '<div class="col-2 col-processbar topic" >'+
                    '<span class="icon">'+imageHTML+'</span>'+
                    '<div class="divider"></div>'+
                    '<span class="title">'+topic[1]['title']+'</span>'+
                  '</div>';

        progressBarNode.append(html);
    });
    // foreach in progressbar array saved multistepform page generate a html item and add it to html progressbar
    Object.entries(progressBar['multistep']).forEach(function(step, index){
      if(index == Object.entries(progressBar['multistep']).length - 1){
        mobileLabel = step[1]['value'];
      }
      let  icon = '';
      // if this is not the last page (which means it is completely edited/filled/validated)
      if(index < Object.entries(progressBar['multistep']).length - 1 || isConfirmationPage){
        icon = '<img src="/typo3conf/ext/rscw_provider/Resources/Public/Icons/check-outline.svg" width="24" height="24" />';
      }
      let html =  '<div class="col-2 col-processbar step">'+
                    '<span class="icon">'+icon+'</span>'+
                    '<div class="divider"></div>'+
                    '<span class="title">'+step[1]['value']+'</span>'+
                  '</div>';

      progressBarNode.append(html);
    });
    // if the topicnavigation is active and we are not on the first page, add an empty progressbar item for the current page
    if(!Object.entries(progressBar['multistep']).length && !isFirstPage){
      let currentElementHTML =  '<div class="col-2 col-processbar step">'+
                                  '<span class="icon"></span>'+
                                  '<div class="divider"></div>'+
                                  '<span class="title"></span>'+
                                '</div>';
      progressBarNode.append(currentElementHTML);
    }
    // if we are not an the last page and not on the first page, add an "use the rest of the space" divider
    if(!isFirstPage && !isConfirmationPage){
      let restElement =   '<div class="col rest-col">'+
                            '<span class="icon"></span>'+
                            '<div class="divider"></div>'+
                          '</div>';
      progressBarNode.append(restElement);
    }
    if(isConfirmationPage){
      let confirmationElement =   '<div class="col col-processbar step">'+
                                    '<span class="icon"></span>'+
                                    '<div class="divider"></div>'+
                                    '<span class="title">Overview & send</span>'+
                                  '</div>';
      progressBarNode.append(confirmationElement);
      mobileLabel = 'Overview & send';
    }
    if(mobileLabel!=''){
      let mobileLabelHTML = '<div class="col-12"><span class="mobile-label">'+mobileLabel+'</span></div>';
      progressBarNode.append(mobileLabelHTML);
    }
    if($('input[name="tx_powermail_pi1[field][topic]"]').length && progressBar['topics'][1]!= undefined){
      $('input[name="tx_powermail_pi1[field][topic]"]').val(progressBar['topics'][1]['value']);
    }
  }
}

function startSpinner(){
  if($('form.powermail_form').length){
    $('form.powermail_form').addClass('hide');
  }
  if($('.multistep-form-navigation').length){
    $('.multistep-form-navigation').addClass('hide');
  }
  if($('.tx-rscwleadhandling-fom-navigation .navigation-and-form').length){
    $('.tx-rscwleadhandling-fom-navigation .navigation-and-form').addClass('hide');
  }
  if($('.tx-rscwleadhandling-fom-navigation').length){
    $('.tx-rscwleadhandling-fom-navigation').append(spinnerHTML);
  }
  if($('#tx-rscwleadhandling-progressbar').length){
    $('#tx-rscwleadhandling-progressbar').addClass('hide');
  }
}

function removeSpinner(){
  if($('.tx-rscwleadhandling-fom-navigation .spinner-container').length){
    $('.tx-rscwleadhandling-fom-navigation .spinner-container').remove();
    if($('#tx-rscwleadhandling-progressbar').length){
      $('#tx-rscwleadhandling-progressbar').removeClass('hide');
    }
  }
}

function initAccordionElements() {
  if($('.powermail_confirmation .accordion-header').length){
    $('.powermail_confirmation .accordion-header').on('click', function () {
        const accordionElement = $(this);
        const accordionParent = accordionElement.parents('.row-head');

        if ($(accordionElement).hasClass('collapsed')) {
          accordionParent.addClass('active');
        } else {
          accordionParent.removeClass('active');
        }
    });
  }
}

function addModalToEditButton(){
  if($('.open-edit-popup').length){
    $('.open-edit-popup').on('click',function(e){
      const button = $(this);
      const form = button.closest('form');
      e.preventDefault();
      $.magnificPopup.open({
          items: {
              src: '#confirmation-edit-form',
              type: 'inline'
          },
      });
      $('#confirmation-edit-form .button-submit').on('click',function(e){
        e.preventDefault();
        form.submit();
      });
    });
  }
}

export { initMultistepForm };
