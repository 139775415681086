import 'jquery.mmenu.4.7.5';

$(function() {
    // mmenu
    if(window.__PAGE_SETTINGS__.useMobileMenuInsteadOfBootstrapMenu) {
        $('.header .nav-mm-markup').mmenu({
            navbars: [
                {
                    position: 'top',
                    content: [
                        'searchfield',
                    ]
                }
            ],
            searchfield: {
                search: false,
            },
            extensions: [
                'pagedim-black',
                'theme-dark',
                'fx-menu-slide',
            ],
        }, {
            offCanvas: {
            pageSelector: '.page-wrapper'
            }
        })
    }
})

