import {lazyRetry} from "./lazyRetry";
import './base';
import './modules/magnificpopup';
import './modules/mmenu';
import './app';

if(document.querySelector(".rhenus-quote")) {
  lazyRetry(() => import(/* webpackChunkName: "quote" */'./lazy/quote').then(module => {
    module.initQuote();
  }), "quote");
}

if(document.querySelector(".stage")) {
  lazyRetry(() => import(/* webpackChunkName: "stage" */'./lazy/stage').then(module => {
    module.initStageSlider();
    module.stageBreadcrumbs();
  }), "stage");
}

if(document.querySelector(".text-teaser-container")) {
  lazyRetry(() => import(/* webpackChunkName: "text-teaser" */'./lazy/textTeaser').then(module => {
    module.initTextTeaser();
  }), "text-teaser");
}

if(document.querySelector(".accordion-box-trigger")) {
  lazyRetry(() => import(/* webpackChunkName: "tabbed-content" */'./lazy/tabbedContentBox').then(module => {
    module.tabbedContentbox();
  }), "tabbed-content");
}

if(document.querySelector(".image-slider-container")) {
  lazyRetry(() => import(/* webpackChunkName: "image-slider" */'./lazy/imageSlider').then(module => {
    module.imageSlider();
  }), "image-slider");
}
