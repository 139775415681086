function roadFreightImprovement() {
  const roadFreightImprovement = document.querySelectorAll('.road-freight-improvement');
  if (roadFreightImprovement) {
    roadFreightImprovement.forEach(el => {
      const res = window.res;
      const fromCountriesArray = res.map(({From, From_abbr}) => ({
        ["title"]: From,
        ["abbr"]: From_abbr
      })).filter((entry, index, fromCountriesArray) => {
        return !fromCountriesArray.slice(0, index).some(prevEntry => prevEntry.abbr === entry.abbr)
      });

      const toCountriesArray = res.map(({To, To_abbr}) => ({
        ["title"]: To,
        ["abbr"]: To_abbr
      })).filter((entry, index, toCountriesArray) => {
        return !toCountriesArray.slice(0, index).some(prevEntry => prevEntry.abbr === entry.abbr)
      });

      const createCountrySlide = (country, el) => {
        let swiperSlide = document.createElement('div')
        swiperSlide.innerHTML = `
        <div class="rfm_country">
            <div class="rfm_country-image">
                <svg viewBox="0 0 184 184">
                  <use href="/typo3conf/ext/rscw_provider/Resources/Public/Images/Countries/countries.svg#${country.abbr}" />
                </svg>
            </div>
            <div class="rfm_country-title">
                <p>${country.title}</p>
            </div>
        </div>
    `;
        swiperSlide.setAttribute('data-country-abbr', `${country.abbr}`)
        swiperSlide.setAttribute('data-country-title', `${country.title}`)
        el.appendChild(swiperSlide);
      }

      const countrySlider = el.querySelectorAll('.country-slider');
      countrySlider.forEach(el => {
        if (el.classList.contains('from-countries')) {
          fromCountriesArray.forEach(country => {
            createCountrySlide(country, el)
          })
        }

        if (el.classList.contains('to-countries')) {
          toCountriesArray.forEach(country => {
            createCountrySlide(country, el)
          })
        }
      })

      const identifyActiveSlide = (el) => {
        const info = el.getInfo();
        const indexCurr = info.index;
        const indexPrev = info.indexCached;
        for (const item of info.slideItems) {
          item.classList.remove('tns-slide-center');
        }
        if (info.slideItems[indexCurr] !== undefined) {
          info.slideItems[indexCurr].classList.add('tns-slide-center');
        }
      }

      const checkForEqualCountry = (sliderFrom, sliderTo, caller) => {
        const infoFrom = sliderFrom.getInfo();
        const infoTo = sliderTo.getInfo();
        const indexFromCurr = infoFrom.index;
        const indexToCurr = infoTo.index;
        const indexFromPrev = infoFrom.indexCached;
        const indexToPrev = infoTo.indexCached;
        let currentSlideCountryData = null;
        let activeCompareSlideElement = null;
        let activeCompareSlideElementData = null;
        let slideElement = null;
        if (caller == "from") {
          slideElement = sliderFrom.getInfo().slideItems[indexFromCurr];
          currentSlideCountryData = slideElement.dataset.countryAbbr;
          activeCompareSlideElement = sliderTo.getInfo().slideItems[indexToCurr];
          activeCompareSlideElementData = activeCompareSlideElement.dataset.countryAbbr;
          if (currentSlideCountryData == activeCompareSlideElementData) {
            if (indexFromCurr > indexFromPrev) {
              sliderFrom.goTo("next");
            } else {
              sliderFrom.goTo("prev");
            }
          }
        } else {
          slideElement = sliderTo.getInfo().slideItems[indexToCurr];
          if (slideElement !== undefined) {
            currentSlideCountryData = slideElement.dataset.countryAbbr;
            activeCompareSlideElement = sliderFrom.getInfo().slideItems[indexFromCurr];
            activeCompareSlideElementData = activeCompareSlideElement.dataset.countryAbbr;
            if (currentSlideCountryData == activeCompareSlideElementData) {
              if (indexToCurr > indexToPrev) {
                sliderTo.goTo("next");
              } else {
                sliderTo.goTo("prev");
              }
            }
          }
        }
      }

      var fromCountries = tns({
        container: el.querySelector('.from-countries'),
        items: 1,
        gutter: 20,
        center: true,
        loop: true,
        nav: false,
        onInit: true,
        preventActionWhenRunning: true,
        startIndex: 1,
        slideBy: 1,
        prevButton: el.querySelector('.from-countries-navigation .prev'),
        nextButton: el.querySelector('.from-countries-navigation .next'),
        responsive: {
          768: {
            items: 3,
          },
          992: {
            items: 1
          },
          1200: {
            items: 3
          }
        }
      });

      fromCountries.getInfo().slideItems[fromCountries.getInfo().index].classList.add('tns-slide-center');
      fromCountries.events.on('indexChanged', () => {
        identifyActiveSlide(fromCountries);
        if (!ignoreEqualCountries) {
          checkForEqualCountry(fromCountries, toCountries, "from");
        }
      });

      var toCountries = tns({
        container: el.querySelector('.to-countries'),
        items: 1,
        gutter: 20,
        center: true,
        loop: true,
        nav: false,
        onInit: true,
        preventActionWhenRunning: true,
        startIndex: 1,
        prevButton: el.querySelector('.to-countries-navigation .prev'),
        nextButton: el.querySelector('.to-countries-navigation .next'),
        responsive: {
          768: {
            items: 3,
          },
          992: {
            items: 1
          },
          1200: {
            items: 3
          }
        }
      });

      toCountries.getInfo().slideItems[toCountries.getInfo().index].classList.add('tns-slide-center');
      toCountries.events.on('indexChanged', () => {
        identifyActiveSlide(toCountries);
        if (!ignoreEqualCountries) {
          checkForEqualCountry(fromCountries, toCountries, "to");
        }
      });

      let activeTo, activeFrom, activeToTitle, activeFromTitle, ignoreEqualCountries;

      const compareCountries = () => {
        Array.from(fromCountries.getInfo().slideItems).forEach(slide => {
          if (slide.classList.contains('tns-slide-center')) {
            activeFrom = slide.dataset.countryAbbr;
            activeFromTitle = slide.dataset.countryTitle;
          }
        })

        Array.from(toCountries.getInfo().slideItems).forEach((slide, index) => {
          if (slide.classList.contains('tns-slide-center')) {
            activeTo = slide.dataset.countryAbbr;
            activeToTitle = slide.dataset.countryTitle;
          }
        });

        const improvementMessage = el.querySelector('.improvement-message');
        const activeEntry = res.find(entry => entry.From_abbr === activeFrom && entry.To_abbr === activeTo);
        const improvement = improvementMessage.querySelector('.improvement');
        const noImprovement = improvementMessage.querySelector('.no-improvement');

        if (typeof activeEntry == 'undefined' || activeEntry.Integer_value === 0 || isNaN(activeEntry.Integer_value)) {
          improvement.style.display = 'none'
          noImprovement.style.display = 'block'
        } else {
          improvement.querySelector('.from-country').innerText = `${activeFromTitle}`;
          improvement.querySelector('.to-country').innerText = `${activeToTitle}`;
          improvement.querySelector('.improvement-number').innerText = `${activeEntry.Integer_value}`;
          noImprovement.style.display = 'none'
          improvement.style.display = 'block'
        }

        const countrySwitch = el.querySelector('.compare-icon');
        countrySwitch.addEventListener('click', () => {
          ignoreEqualCountries = true;

          const toIndex = Array.from(fromCountries.getInfo().slideItems).filter(slide => !slide.classList.contains('tns-slide-cloned')).findIndex(slide => {
            return slide.dataset.countryAbbr === activeTo;
          });

          const fromIndex = Array.from(toCountries.getInfo().slideItems).filter(slide => !slide.classList.contains('tns-slide-cloned')).findIndex(slide => {
            return slide.dataset.countryAbbr === activeFrom;
          });

          fromCountries.goTo(toIndex)
          toCountries.goTo(fromIndex)

          setTimeout(() => {
            ignoreEqualCountries = false
          }, 100)
        });
      }

      compareCountries();
      const countrySliderArray = [fromCountries, toCountries];
      countrySliderArray.forEach(slider => {
        slider.events.on('indexChanged', function (evt) {
          compareCountries();
        })
      })
    })
  }
}

export{roadFreightImprovement};
