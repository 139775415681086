import WOW from 'wow.js/dist/wow.min';

const staunezahl = {
  locale: 'en',

  wowConfig: {
    boxClass: 'staunezahl',
    animateClass: 'staunezahl_in-viewport',
    mobile: true
  },

  init: function () {
    this.locale = document.getElementsByTagName('html')[0].getAttribute('lang');

    /**
     * Check if this.locale is valid for number formatting, otherwise use 'en'.
     */
    if (!Intl.NumberFormat.supportedLocalesOf(this.locale).length) {
      this.locale = 'en';
    }

    const staunezahl = document.getElementsByClassName('staunezahl');

    if (staunezahl.length > 0) {
      new WOW({
        ...this.wowConfig,
      }).init();

      for (let i = 0; i < staunezahl.length; i++) {
        this.initStaunezahlInstance(document.getElementById(staunezahl[i].getAttribute('id')));

        if (i === staunezahl.length - 1) {
          this.fixToplineHeight();
        }
      }
    }
  },

  initStaunezahlInstance: function (staunezahl) {
    const decimals = parseInt(staunezahl.getAttribute('data-staunezahl-decimals'));
    const duration = parseInt(staunezahl.getAttribute('data-staunezahl-duration') * 1000);

    let numberStart, numberEnd;
    if (decimals === 0) {
      numberStart = parseInt(staunezahl.getAttribute('data-staunezahl-number-start'));
      numberEnd = parseInt(staunezahl.getAttribute('data-staunezahl-number-end'));
    } else {
      numberStart = parseFloat(staunezahl.getAttribute('data-staunezahl-number-start'));
      numberEnd = parseFloat(staunezahl.getAttribute('data-staunezahl-number-end'));
    }

    const stepTime = 100;
    const range = numberEnd - numberStart;
    const numberOfSteps = duration / stepTime;
    const step = range / numberOfSteps;

    const obj = $(staunezahl).find('.staunezahl--number-box');
    const progressBar = $(staunezahl).find('.staunezahl--progress-bar');
    progressBar.css('width', '0');

    let current = numberStart;
    let progress = 1;
    const timer = setInterval(() => {
      if (obj.parent().hasClass('staunezahl_in-viewport')) {
        current += step;
        obj.html(this.formatNumber2Locale(current, decimals));

        progressBar.css('width', (progress * 100 / numberOfSteps) + '%');
        progress += 1;

        if (current >= numberEnd) {
          obj.html(this.formatNumber2Locale(numberEnd, decimals));
          clearInterval(timer);
        }
      }
    }, stepTime);
  },

  /**
   * Formats a number to the locale of the page.
   *
   * @param value - Number to format
   * @param decimal - Number of decimal places
   *
   * @returns {string} - Formatted number
   */
  formatNumber2Locale: function (value, decimal) {
    return value.toLocaleString(this.locale, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  },

  /**
   * Ensures that all toplines have the same height (on >= tablet)
   */
  fixToplineHeight: function () {
    const toplines = document.getElementsByClassName('staunezahl--topline');

    let toplineCount = toplines.length;
    let height = 0;

    for (let i = 0; i < toplineCount; i++) {
      if (toplines[i].offsetHeight > height) {
        height = toplines[i].offsetHeight;
      }
    }

    for (let i = 0; i < toplineCount; i++) {
      toplines[i].style.height = height + "px";
    }
  },
};

export {staunezahl};
