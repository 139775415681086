function handleBuisnessPrivateCases() {
  handleBuisnessPrivateCases = document.getElementsByClassName('.formlayout_2023');
  if(typeof(handleBuisnessPrivateCases) != 'undefined' && handleBuisnessPrivateCases != null && handleBuisnessPrivateCases.length){
    document.querySelector('.formlayout_2023').querySelector('form').reset();
    const e = new Event("change");
    document.querySelector(".formlayout_2023 .business").addEventListener("click", () => {
      document.getElementById(document.querySelector(".formlayout_2023 .businesscase-selector").getAttribute("data-form")).classList.remove('d-none');
      document.querySelector(".formlayout_2023 .businesscase_container").classList.add('d-none');
      document.getElementById('powermail_field_business').value = document.querySelector(".formlayout_2023 .business").getAttribute('data-title');
      document.getElementById('powermail_field_business').dispatchEvent(e);
    });

    document.querySelector(".formlayout_2023 .private").addEventListener("click", () => {
      document.getElementById(document.querySelector(".formlayout_2023 .businesscase-selector").getAttribute("data-form")).classList.remove('d-none');
      document.querySelector(".formlayout_2023 .businesscase_container").classList.add('d-none');
      document.getElementById('powermail_field_business').value = document.querySelector(".formlayout_2023 .private").getAttribute('data-title');
      document.getElementById('powermail_field_business').dispatchEvent(e);
    });

  }
}

export { handleBuisnessPrivateCases };
