function referenceSlider() {
    if ($('.reference-slider-container').length) {

        $('.reference-slider-container').each(function (index) {
            $(this).attr('data-reference-slider', index);
        });
        var items = 1;
        var resposiveItems = 3;
        var breakpoint = 768;

        var allReferenceSlider = [];
        var $referenceSlider = $('.reference-slider');
        $referenceSlider.each(function (index, slider) {
            var tnSliderRef = tns({
                container: slider,
                items: items,
                slideBy: items,
                controls: false,
                edgePadding: 0,
                mode: 'carousel',
                nav: false,
                mouseDrag: false,
                autoplay: false,
                autoplayTimeout: 5000,
                autoplayButtonOutput: false,
                loop: false,
                controlsText: ["chevron_left", "chevron_right"],
                responsive: {
                    768: {
                        items: resposiveItems,
                        slideBy: resposiveItems,
                    },
                }
            });

            allReferenceSlider.push(tnSliderRef);
        });

        $('.reference-slider-button').on('click', function () {
            var sliderContainer = $(this).parents('.reference-slider-container'),
                sliderNumber = sliderContainer.attr('data-reference-slider');
            if ($(this).hasClass('slider-next')) {
                allReferenceSlider[sliderNumber].goTo('next');
            } else {
                allReferenceSlider[sliderNumber].goTo('prev');
            }
        });

        $('.reference-slide a.open').click(function (e) {
            e.preventDefault();
            $(this).closest('.reference-slide').addClass('open');
        })
        $('.reference-slide a.teaser-close').click(function (e) {
            e.preventDefault();
            $(this).closest('.reference-slide').removeClass('open');
        })

        var iterator = 0;
        allReferenceSlider.forEach(function () {
            var currentIterator = iterator.valueOf();
            refreshProgressbar(currentIterator);
            allReferenceSlider[iterator].events.on('transitionStart', function () {
                refreshProgressbar(currentIterator);
            });
            iterator++;
        })

        $(window).resize(function () {
            var iterator = 0;
            allReferenceSlider.forEach(function () {
                var currentIterator = iterator.valueOf();
                refreshProgressbar(currentIterator);
                iterator++;
            })
        })
    }

    function refreshProgressbar(sliderNumber) {
        var itemAmount = 0;
        if (window.innerWidth >= breakpoint) {
            itemAmount = resposiveItems;
        } else {
            itemAmount = items;
        }
        var info = allReferenceSlider[sliderNumber].getInfo(),
            direction = 'next';
        if (info.indexCached > info.index) {
            direction = 'prev';
        }
        if (direction == 'next') {
            var slideCurrent = Math.ceil(info.displayIndex / itemAmount),
                slidesTotal = Math.ceil(info.slideCount / itemAmount),
                slidesRest = info.slideCount - info.displayIndex;
            if (slidesRest < itemAmount) {
                slideCurrent = slidesTotal;
            }
            var sliderProgress = (slideCurrent / slidesTotal) * 100;
        } else {
            var slideCurrent = Math.ceil(info.displayIndex / itemAmount),
                slidesTotal = Math.ceil(info.slideCount / itemAmount),
                slidesRest = info.slideCount - info.displayIndex;
            if (info.displayIndex < itemAmount && info.displayIndex > 1) {
                slideCurrent = 2;
            }
            var sliderProgress = (slideCurrent / slidesTotal) * 100;
        }

        $('.reference-slider-container').find('.reference-slider-current').html(slideCurrent);
        $('.reference-slider-container').find('.total-slides').html(slidesTotal);
        $('.reference-slider-container').find('.slider-progress-bar').css('width', sliderProgress + '%');

        rscwUpdateImgs();
    }
}

export { referenceSlider };