const accordionElement = () => {
  const accordionHeader = document.querySelectorAll('.accordion-header');

  for (let i = 0; i < accordionHeader.length; i++) {
    accordionHeader[i].addEventListener('click', () => {
      const accordion = accordionHeader[i].parentElement.parentElement.parentElement.parentElement;
      const accordionElementIndex = Array.prototype.indexOf.call(accordion.children, accordionHeader[i].parentElement.parentElement.parentElement);

      setActiveAccordionElement(
        accordion,
        accordionElementIndex
      );

      $('#'+accordion.id).on('shown.bs.collapse', function() {
        rscwUpdateImgs();
      });
    });
  }
}

const setActiveAccordionElement = (accordion, elementIndex) => {
  // if the accordion element is already active, remove the active class
  if (accordion.getElementsByClassName('accordion-element')[elementIndex].classList.contains('active')) {
    accordion.getElementsByClassName('accordion-element')[elementIndex].classList.remove('active');
  } else {
    accordion.getElementsByClassName('accordion-element')[elementIndex].classList.add('active');
  }

  // remove the active class from all other accordion elements
  for (let i = 0; i < accordion.getElementsByClassName('accordion-element').length; i++) {
    if (i !== elementIndex) {
      accordion.getElementsByClassName('accordion-element')[i].classList.remove('active');
    }
  }
}

const openUrlAccordion = () => {
  var url = window.location.href;
  var id = url.substring(url.lastIndexOf('#') + 1);
  var idNumber = id.slice(3);
  if (id.slice(0, 3) == "acc") {
    if ($('#accordion-item-body-' + idNumber).length) {
      $('#accordion-item-body-' + idNumber).collapse('show');
      $('html, body').animate({
        scrollTop: ($('#accordion-item-body-' + idNumber).offset().top - 100)
      }, 1000);
    }
  }
  $('a:not(.rhenus-googlemaps__reset-all-filter):not(.filter-tags__show-more-toggler)').click(function () {
    var href = $(this).attr('href');
    var hrefId = href.slice(1, href.length);
    var hrefIdNumber = hrefId.slice(3);
    if (hrefId.slice(0, 3) == "acc") {
      if ($('#accordion-item-body-' + hrefIdNumber).length) {
        $('#accordion-item-body-' + hrefIdNumber).collapse('show');
        setTimeout(function () {
          document.getElementById('accordion-item-body-' + hrefIdNumber).scrollIntoView({behavior: "smooth"});
        }, 300);
      }
    }
  });
}

const initAcoordionLinks = () => {
  var hash = window.location.hash;
  if (hash && hash != '') {
    var hashWithout = hash.replace('#', '');
    if (hashWithout.length > 3) {
      var identifier = hashWithout.substring(0, 3);
      if (identifier == 'acc') {
        var idNumber = hash.slice(3);
        $('#accordion-item-body-' + idNumber).collapse('show');
      }
    }
  }
}

export {accordionElement, openUrlAccordion, initAcoordionLinks};
