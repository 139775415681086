import { materialComponents } from "./materialComponents";
import { bootstrapSelect } from "./bootstrapSelect";

function parseMauticForms() {
    const mauticSelector = '.rscw-mautic-form ';
    if ($(mauticSelector + '.mauticform_wrapper').length) {
      if ($(mauticSelector + '.mauticform-text, .mauticform-email, .mauticform-tel').length) {
        $(mauticSelector + '.mauticform-text, .mauticform-email, .mauticform-tel').each(function () {
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap'></div>").wrap("<div class='powermail_field'></div>");
                if ($(this).find('input').length) {
                    if($(this).hasClass('mauticform-required')){
                      $(this).find('input').attr('required','required');
                    }
                    // add material classes & powermail classes
                    $(this).addClass('mdc-text-field');
                    $(this).addClass('mdc-ripple-upgraded');
                    $(this).find('input').addClass('mdc-text-field__input');
                    $(this).find('label').addClass('mdc-floating-label');
                    $(this).find('label').addClass('powermail_label');
                    // append required-dot and material line
                    let input = $(this).find('input');
                    $(this).find('input').remove();
                    $(this).append(input);
                    $(this).append('<div class="required-dot"></div><div class="mdc-line-ripple"></div>');

                } else if ($(this).find('textarea').length) {
                    // add material classes & powermail classes
                    $(this).addClass('mdc-text-field mdc-text-field--textarea');
                    $(this).find('textarea').addClass('mdc-text-field__input');
                    $(this).find('label').addClass('mdc-floating-label');
                    $(this).find('label').addClass('powermail_label');
                    // append required-dot and material line
                    let label = '';
                    let errormsg = '';
                    if ($(this).find('label').length) {
                        label = $(this).find('label')[0].outerHTML;
                        $(this).find('label').remove();
                    }
                    if ($(this).find('.mauticform-errormsg').length) {
                        errormsg = $(this).find('.mauticform-errormsg')[0].outerHTML;
                        $(this).find('.mauticform-errormsg').remove();
                    }
                    let html = '<div class="required-dot"></div>' +
                        '<div class="mdc-notched-outline">' +
                        '<div class="mdc-notched-outline__leading"></div>' +
                        '<div class="mdc-notched-outline__notch">' +
                        label +
                        '</div>' +
                        '<div class="mdc-notched-outline__trailing"></div>' +
                        '</div>' + errormsg;
                    $(this).append(html);
                }
            });
        }
        if ($(mauticSelector + '.mauticform-file, .mauticform-date, .mauticform-datetime-local').length) {
            $(mauticSelector + '.mauticform-file, .mauticform-date, .mauticform-datetime-local').each(function () {
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap'></div>");
                $(this).find('.mauticform-label').addClass('powermail_label');
                $(this).find('input').wrap("<div class='powermail_field'></div>");
            });
        }
        if ($(mauticSelector + '.mauticform-checkboxgrp').length) {
            $(mauticSelector + '.mauticform-checkboxgrp').each(function () {
                let checkboxGroup = this;
                let checkboxRequired = $(this).hasClass('mauticform-required');
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap powermail_fieldwrap_type_check'></div>").wrap("<div class='powermail_field'></div>");
                $(this).find('.mauticform-label').addClass('powermail_label');
                $(this).find('.mauticform-label').addClass('font-weight-bold');
                if(checkboxRequired){
                  $(this).find('.mauticform-label').addClass('required');
                  $(this).find('.mauticform-label').append('<div class="required-dot"></div>');
                }
                if($(this).find('.mauticform-checkboxgrp-row').length){
                  $($(this).find('.mauticform-checkboxgrp-row')).each(function () {
                    let checkboxRow = $(this);
                    if (checkboxRow.parent().find('.mauticform-errormsg').length) {
                        checkboxRow.parent().find('.mauticform-errormsg').html('This field must be filled!');
                    }
                    if(checkboxRow.find('.mauticform-checkboxgrp-label').length){
                      let checkboxLabel = checkboxRow.find('.mauticform-checkboxgrp-label');
                      checkboxLabel.addClass('custom-radio-checkbox checkbox');
                      if (checkboxLabel.find('input').length) {
                        checkboxLabel.find('input').addClass('powermail_checkbox');
                      } else {
                          if (checkboxRow.find('input.mauticform-checkboxgrp-checkbox').length) {
                              let inputBox = checkboxRow.find('input.mauticform-checkboxgrp-checkbox');
                              inputBox.addClass('powermail_checkbox');
                              checkboxLabel.append(inputBox);
                          }
                      }
                      checkboxLabel.append('<span class="checkmark"></span>');
                    }
                  });
                }else if ($(this).find('.mauticform-checkboxgrp-label').length) {
                    $($(this).find('.mauticform-checkboxgrp-label')).each(function () {
                        // add material classes & powermail classes
                        $(this).addClass('custom-radio-checkbox checkbox');
                        if ($(this).find('input').length) {
                            $(this).find('input').addClass('powermail_checkbox');
                        } else {
                            if ($(checkboxGroup).find('input.mauticform-checkboxgrp-checkbox').length) {
                                let inputBox = $(checkboxGroup).find('input.mauticform-checkboxgrp-checkbox');
                                inputBox.addClass('powermail_checkbox');
                                $(this).append(inputBox);
                            }
                        }
                        $(this).append('<span class="checkmark"></span>');
                    });
                }
            });
        }
        if ($(mauticSelector + '.mauticform-radiogrp').length) {
            $(mauticSelector + '.mauticform-radiogrp').each(function () {
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap'></div>").wrap("<div class='powermail_field'></div>");
                $(this).find('.mauticform-label').addClass('powermail_label');
                $(this).find('.mauticform-label').addClass('font-weight-bold');
                if ($(this).find('.mauticform-radiogrp-label').length) {
                    $($(this).find('.mauticform-radiogrp-label')).each(function () {
                        // add material classes & powermail classes
                        $(this).addClass('custom-radio-checkbox radio-button');
                        $(this).find('input').addClass('powermail_radio');
                        $(this).append('<span class="checkmark"></span>');
                    });
                }
            });
        }
        if ($(mauticSelector + '.mauticform-select').length) {
            $(mauticSelector + '.mauticform-select').each(function () {
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap'></div>");
                // add material- & powermail- & bootstrap select -classes
                $(this).addClass('rhenus-custom-select');
                $(this).addClass('powermail_field');
                $(this).find('select').addClass('powermail_select');
                $(this).find('select').addClass('custom-select-picker');
                $(this).find('label').addClass('mdc-floating-label');
                $(this).find('label').addClass('powermail_label');
                // get title
                let title = $(this).find('label').html();
                // add bootstrap select data attributes
                $(this).find('select').data('live-search', 'true');
                $(this).find('select').data('none-selected-text', title);
                // append required-dot
                $(this).append('<div class="required-dot"></div>');
            });
        }
        if ($(mauticSelector + '.mauticform-button-wrapper').length) {
            $(mauticSelector + '.mauticform-button-wrapper').each(function () {
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap'></div>").wrap("<div class='powermail_field'></div>");
                // add material- & powermail -classes
                $(this).find('.mauticform-button').addClass('button-primary powermail_submit');
                $(this).find('.mauticform-button').removeClass('btn btn-default');
            });
        }
        if ($(mauticSelector + 'button.mauticform-pagebreak').length) {
            $(mauticSelector + 'button.mauticform-pagebreak').each(function () {
                // wrap with powermail classes
                $(this).wrap("<div class='powermail_fieldwrap'></div>").wrap("<div class='powermail_field'></div>");
                // add material- & powermail -classes
                $(this).addClass('button-primary');
                $(this).removeClass('btn btn-default');
            });
        }
        $(mauticSelector + '.mauticform_wrapper').parent().find('style').remove();
        materialComponents();
        bootstrapSelect();
    }
}

export { parseMauticForms };
