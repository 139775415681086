function leadCTA() {
    var $stage, stageHeight, smallNaviTrigger;

    const debounce = (method, delay) => {
      clearTimeout(method._tId);
      method._tId = setTimeout(() => {
        method();
      }, delay);
    };

  const handleScroll = () => {
    stageHeight = $(".stage-figure").outerHeight();
    const scrollPos = $(window).scrollTop();

    $(".career-lead-cta-container").addClass("d-lg-block");
    $(".career-sidebar-cta-container").addClass("d-lg-none");
  }

    $(window).on("scroll", () => {
      debounce(handleScroll, 20);
    });

    setTimeout(function () {
      if ($('.lead-cta-anchor').length) {
        $stage = $('.lead-cta-anchor').first();
        stageHeight = $stage.outerHeight();
        if ($(window).width() < 992) { // @TODO: Should this not be 768?
          var isBigStage = $('.big-stage').length;
          var isEditoriableStage = $('.editorial__stage').length;

          if (isBigStage) {
            stageHeight = $('.big-stage').outerHeight() + 30;
          } else if (isEditoriableStage) {
            if ($(window).width() < 768) {
              var editorialContentHeight = $('.editorial__stage').find('.editorial__stage__content').height();
              stageHeight = editorialContentHeight + 48 + 35 + 40;
            } else {
              stageHeight = $('.editorial__stage').outerHeight() + 40;
            }
          } else {
            stageHeight = $stage.find('figure').outerHeight();
          }
        }

        if ($stage.hasClass('stage-slide')) {
          $('.lead-cta-container').addClass('has-stage-slide');
        }

        if (stageHeight < 155) {
          return false
        }

        smallNaviTrigger = stageHeight - 155;

        $('.lead-cta-container').addClass('has-stage');
        $('.lead-cta-container').addClass('stage-margin');
        if ($('.big-stage').length > 0) {
          $('.lead-cta-container').attr('style', 'top:' + (stageHeight - 55) + 'px;');
        }else {
          $('.lead-cta-container').attr('style', 'top:' + (stageHeight - 30) + 'px;');
        }

        var leadCTAScroll = function () {
          var lastScrollTop = 0;
          var stageHeightnew = $stage.outerHeight();

          $(window).scroll(function () {
            var scrollHeight = $(window).scrollTop();
            var st = $(this).scrollTop();
            var isBigStage = $('.big-stage').length;
            var isEditoriableStage = $('.editorial__stage').length;
            if (isBigStage) {
              stageHeightnew = $('.big-stage').outerHeight();
              if ($(window).width() < 768) {
                stageHeightnew = $('.big-stage').outerHeight() + 30;
              }
            } else if (isEditoriableStage) {
              if ($(window).width() < 768) {
                var editorialContentHeight = $('.editorial__stage').find('.editorial__stage__content').height();
                stageHeightnew = editorialContentHeight + 48 + 35 + 40;
              } else {
                stageHeightnew = $('.editorial__stage').outerHeight();
              }
            } else {
              stageHeightnew = $stage.find('figure').outerHeight();
            }

            if (scrollHeight > smallNaviTrigger) {
              $('.lead-cta-container').removeClass('stage-margin');
              $('.lead-cta-container').attr('style', 'top');
            } else {
              $('.lead-cta-container').attr('style', 'top:' + (stageHeightnew - 55) + 'px;');
              $('.lead-cta-container').addClass('stage-margin');
            }
          });
        }

        leadCTAScroll();

        $(window).resize(function () {
          var stageHeightnew = $stage.outerHeight();

          if ($(window).width() < 992) {
            var isBigStage = $('.big-stage').length;
            var isEditoriableStage = $('.editorial__stage').length;
            if (isBigStage) {
              stageHeightnew = $('.big-stage').outerHeight();
            } else if (isEditoriableStage) {
              if ($(window).width() < 768) {
                var editorialContentHeight = $('.editorial__stage').find('.editorial__stage__content').height();
                stageHeightnew = editorialContentHeight + 48 + 35 + 40;
              } else {
                stageHeightnew = $('.editorial__stage').outerHeight();
              }
            } else {
              stageHeightnew = $stage.find('figure').outerHeight();
            }
          }

          leadCTAScroll();

          if ($('.lead-cta-container').hasClass('stage-margin')) {
            $('.lead-cta-container').attr('style', 'top:' + (stageHeightnew - 55) + 'px;');
          }
        });
      }
    }, 500);


    const handleHover = () => {
      if($('.lead-cta .lead-cta-text .hover-text').length){
        $('.lead-cta .lead-cta-text').each(function(index,cta){
          if($(cta).find('.hover-text').length){
            $(cta).find('.hover-text').css('width',Math.round($(cta).find('.hover-text').outerWidth())+'px');
            $(cta).find('.hover-text span').css('width',Math.round($(cta).find('.hover-text').outerWidth())+'px');
            $(cta).find('.hover-text').addClass('length-processed')
            if($(cta).find('.material-icons').length){
              $(cta).find('.material-icons').addClass('hover-icon');
            }
          }
        });
      }
    };

    const handleSharePopup = () => {
      if($('.lead-cta .lead-cta-text.share-popover').length){
        $('.lead-cta .lead-cta-text.share-popover').each(function(index,cta){
          let dir = 'top';
          initPopover(cta,dir,'share-header-popover');
        });
      }
      if($('.career-sidebar-cta-container .share-popover').length){
        $('.career-sidebar-cta-container .share-popover').each(function(index,cta){
          let dir = 'top';
          let classes = '';
          if($(window).width()<992){
            classes = 'share-footer-popover';
            $(cta).addClass(classes);
          }
          initPopover(cta,dir,classes);
          $(cta).on('shown.bs.popover', function () {
            if($(window).width()<992){
              fixPositionOfFooterPopup(cta);
            }
          })
          $(window).resize(function(){
            if($(window).width()<992){
              if(!$(cta).hasClass('share-footer-popover')){
                reinitPopover(cta,dir,'share-footer-popover');
              }
            }else{
              if($(cta).hasClass('share-footer-popover')){
                reinitPopover(cta,dir,'');
              }
            }
          });
          $(window).scroll(function(){
            if($('.popover.share-popover.show').length){
              if($(window).width()<992){
                fixPositionOfFooterPopup(cta);
              }
            }
          })
        });
      }
    };

    const reinitPopover = (cta,dir,classes) => {
      $(cta).off();
      if(classes==''){
        $(cta).removeClass('share-footer-popover');
      }else{
        $(cta).addClass('share-footer-popover');
      }
      $(cta).popover('dispose');
      initPopover(cta,dir,classes);
      $(cta).on('shown.bs.popover', function () {
        if($(window).width()<992){
          fixPositionOfFooterPopup(cta);
        }
      })
    }

    const initPopover = (cta,dir,classes) => {
      let content = '';
      if($('#share-popover-content').length){
        content = $('#share-popover-content').html();
      }
      if($('.default-teaser-slideshow').length){
        classes += ' breaking-slider-on-page';
      }
      $(cta).popover({
        html:true,
        content: content,
        placement: dir,
        template: '<div class="popover share-popover '+classes+'" role="tooltip"><div class="popover-body"></div></div>'
      });
      $(cta).on('click',function(e){
        e.preventDefault();
        const bounding = cta.getBoundingClientRect();
        const height = $(cta).outerHeight()
        const width = $(cta).outerWidth()
        let text = '';
        if($(cta).find('span').length){
          text = $(cta).find('span').html();
        }
        let closeButtonHTML =
          '<div class="sidebar-cta-container close-share-popover-container">'+
            '<a href="#" class="close-share-popover cta-button cta-button_lead-cta"'+
              ' style="width:'+width+'px;height:'+height+'px;top:'+bounding.top+'px;left:'+bounding.left+'px;">'+
              '<i class="material-icons">close</i>'+
              '<span>'+text+'</span>'+
            '</a>'+
          '</div>';
        $('body').append(closeButtonHTML);
        $('.close-share-popover').on('click',function(e){
          e.preventDefault();
          $(cta).popover('hide');
        });
      })
      $(cta).on('show.bs.popover', function () {
        addPopupOverlay(cta);
      })
      $(cta).on('hide.bs.popover', function () {
        removePopupOverlay(cta);
      })
    }

    const fixPositionOfFooterPopup = (cta) => {
      if($('.popover.share-popover').length && !$('.popover.share-popover').hasClass('breaking-slider-on-page')){
        let marginLeft = 4;
        if($('.popover.share-popover').width()<$(cta).width()){
          marginLeft = (($(cta).width()/2) - ($('.popover.share-popover').width()/2))*-1;
        }
        marginLeft = Math.ceil(marginLeft)+'px';
        let x = document.querySelectorAll(".popover.share-popover");
        x[0].style.setProperty("left", marginLeft, "important");
      }
    }

    const addPopupOverlay = (cta) => {
      $('html').css('overflow','hidden');
      $('body').addClass('navigation-open');
      $('body').addClass('navigation-open-search');
      $('body').addClass('navigation-open-without-search');
      $('body').addClass('blue');
      $('body').prepend('<div class="navi-background"></div>');
      $('.navi-background').on('click',function(){
        $(cta).popover('hide');
      });
    }
    const removePopupOverlay = (cta) => {
      $('html').css('overflow','');
      $('.close-share-popover-container').remove();
      $('body').removeClass('navigation-open');
      $('body').removeClass('navigation-open-search');
      $('body').removeClass('navigation-open-without-search');
      $('body').removeClass('blue');
      $('.navi-background').remove();
    }

    handleHover();
    handleSharePopup();
}

export { leadCTA };
