/**
 * Instantiate a slider based on Standard Teaser-News Items
 *
 * @param minItems News items required before turning a standard teaser-news container into a slider
 * @param breakpoint Maximum window width-boundary for the slider to be displayed
 */
function initStandardTeaserMobileSlider(minItems = 3, breakpoint = 992) {
    if ($(window).width() >= breakpoint) return;
    if (!document.getElementsByClassName('news-item-standard-teaser-container')[0]) return;

    let allSlider = [];
    $('.news-item-standard-teaser-slider').each(function (index, slider) {
        // check, if the required number of news items is present
        if ($(this).find('.news-item-standard-teaser-slide').length >= minItems) {

            $(this).attr('data-standard-teaser-slider-instance', index);

            let tnsSlider = tns({
                container: slider,
                items: 1,
                slideBy: 1,
                controls: true,
                mode: 'carousel',
                nav: false,
                mouseDrag: false,
                autoplay: false,
                loop: true,
                controlsText: ["chevron_left", "chevron_right"],
            });

            allSlider.push(tnsSlider);
        }
    });
}

export { initStandardTeaserMobileSlider };